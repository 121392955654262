'use client';

import { gql, useMutation } from '@apollo/client';
import { useMemo } from 'react';

import type { DeviceConfiguration } from '~/types/configurationDevice';

export interface SetConfigurationDeviceDefaultVariables {
  configuration: DeviceConfiguration | undefined;
}

export interface SetConfigurationDeviceDefaultData {
  setConfigurationDeviceDefault: {
    name: string;
  };
}

export default function useMutationSetConfigurationDeviceDefault() {
  const [setConfigurationDeviceDefault, { data, loading, error }] = useMutation<
    SetConfigurationDeviceDefaultData,
    SetConfigurationDeviceDefaultVariables
  >(
    gql`
      mutation MutationSetConfigurationDeviceDefault(
        $configuration: setConfigurationDeviceDefaultInput
      ) {
        setConfigurationDeviceDefault(name: "base", configuration: $configuration) {
          name
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
    },
  );

  return useMemo(
    () => ({
      setConfigurationDeviceDefault,
      result: data?.setConfigurationDeviceDefault,
      isLoading: loading,
      error,
    }),
    [setConfigurationDeviceDefault, data?.setConfigurationDeviceDefault, error, loading],
  );
}
