'use client';

import WarningOutlined from '@ant-design/icons/WarningOutlined';
import { memo, type ReactNode } from 'react';
import styled from 'styled-components';

import theme from '~/theme';

const WarningDiv = styled.div`
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
`;

interface Props {
  color?: string;
  children?: ReactNode;
  className?: string;
}

const Warning = memo(({ color = theme.colors.yellow, children, className }: Props) => (
  <WarningDiv className={className}>
    <WarningOutlined style={{ color }} />
    {children}
  </WarningDiv>
));

Warning.displayName = 'Warning';

export default Warning;
