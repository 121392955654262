'use client';

import { useMemo } from 'react';

import useCompany from '~/hooks/useCompany';

export const MOBILE_APP_FEATURES_ATTRIBUTE_NAME = 'configuration';

export interface MobileAppFeatureProperties {
  dataSource?: 'remote' | 'local';
  brainVersion?: '3' | '4';
  isEquipmentItem?: 'true' | 'false';
  showDataInProfile?: 'true' | 'false';
  missionFeedbackUrl?: string;
}

export interface MobileAppFeature {
  type:
    | 'brain'
    | 'phone'
    | 'temperature'
    | 'heartRate'
    | 'gasConcentrations'
    | 'continuousHeatStrainMonitoring'
    | 'impactDetection'
    | 'sensorDataLogging'
    | 'realTimeData'
    | 'map'
    | 'team'
    | 'profile'
    | 'userData'
    | 'heatStrainAlgorithm'
    | 'manDownAlgorithm'
    | 'stressAlgorithm';
  properties?: MobileAppFeatureProperties;
}

interface MobileAppFeatureSchema {
  schemaVersion: `${number}.${number}.${number}`;
  features: MobileAppFeature[];
}

const DEFAULT_SCHEMA: MobileAppFeatureSchema = {
  schemaVersion: '0.0.1',
  features: [],
};

export default function useCompanyMobileAppFeatures(): {
  configurationValue: string;
  mobileAppFeatures: MobileAppFeatureSchema;
  areMobileAppFeaturesLoaded: boolean;
} {
  const { currentCompany } = useCompany();

  const value = useMemo(
    () =>
      currentCompany?.attributes?.find(
        (attribute) => attribute?.name === MOBILE_APP_FEATURES_ATTRIBUTE_NAME,
      )?.value,
    [currentCompany?.attributes],
  );

  const { mobileAppFeatures, configurationValue } = useMemo(() => {
    let didError = false;
    let configuration = DEFAULT_SCHEMA;
    try {
      if (value) {
        configuration = JSON.parse(value);
      }
    } catch (error) {
      didError = true;
    }
    return {
      mobileAppFeatures: configuration,
      configurationValue: didError ? value : JSON.stringify(configuration, null, 2),
    };
  }, [value]);

  const areMobileAppFeaturesLoaded = useMemo(() => !!currentCompany, [currentCompany]);

  return useMemo(
    () => ({
      configurationValue: configurationValue || '',
      mobileAppFeatures,
      areMobileAppFeaturesLoaded,
    }),
    [configurationValue, mobileAppFeatures, areMobileAppFeaturesLoaded],
  );
}
