import { AGENT_STATUS } from '~/types/agent';
import { MARKER_TYPE } from '~/types/marker';
import curateUrl from '~/utils/parse/curateUrl';

import type { MarkerType } from '../hooks/useMarkers';

const colorMap = {
  [AGENT_STATUS.alert]: 'alarm/critical',
  [AGENT_STATUS.warning]: 'yellow',
  [AGENT_STATUS.inMission]: 'blue',
  [AGENT_STATUS.inSafeZone]: 'green',
  [AGENT_STATUS.connectionLost]: 'midGrey',
};

function computePath(marker: MarkerType) {
  const finalPart = `-ellipse${marker.type === MARKER_TYPE.vehicle ? '-vehicle' : ''}.svg`;
  if (marker.alarmLevel) {
    return `/icons/markers/alarm/${marker.alarmLevel.toLowerCase()}${finalPart}`;
  }
  return `/icons/markers/${colorMap[marker.status]}${finalPart}`;
}

function computeScaledSize(isHighlighted?: boolean) {
  const size = isHighlighted ? 100 : 80;

  return new google.maps.Size(size, size);
}

function computeLabelOrigin(status: AGENT_STATUS, isHighlighted?: boolean) {
  const alert = status === AGENT_STATUS.alert;
  let labelOriginX;
  let labelOriginY;

  if (isHighlighted) {
    labelOriginX = 50;
    labelOriginY = alert ? 50 : 68;
  } else {
    labelOriginX = 40;
    labelOriginY = alert ? 40 : 52;
  }

  return new google.maps.Point(labelOriginX, labelOriginY);
}

export default function computeIcon(marker: MarkerType) {
  const path = computePath(marker);
  const scaledSize = computeScaledSize(marker.isHighlighted);
  const labelOrigin = computeLabelOrigin(marker.status, marker.isHighlighted);
  const url = curateUrl(path);
  const icon: google.maps.Icon = { url, scaledSize, labelOrigin };

  return icon;
}
