'use client';

import { memo } from 'react';
import styled from 'styled-components';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import usePreventPageScroll from '~/hooks/usePreventPageScroll';
import i18n, { currentLanguage } from '~/locales/i18n';
import theme from '~/theme';
import curateUrl from '~/utils/parse/curateUrl';

const SECTION_HEADER_HEIGHT = 93;

const PdfIframe = styled.iframe`
  border: 0;
  width: 100%;
  min-height: calc(100vh - ${SECTION_HEADER_HEIGHT}px - ${theme.dimensions.navbarHeight}px);
`;

const UserManualPage = memo(() => {
  const pageTitle = i18n.t('userManualPage.title');

  usePreventPageScroll();

  return (
    <>
      <Container>
        <PageHeader title={pageTitle} subtitle={i18n.t('userManualPage.subtitle')} />
      </Container>
      <PdfIframe
        title={pageTitle}
        src={curateUrl(`/docs/User Manual 2023-04 ${currentLanguage().toUpperCase()}.pdf`)}
      />
    </>
  );
});

UserManualPage.displayName = 'UserManualPage';

export default UserManualPage;
