'use client';

import { useCallback, useMemo, useState } from 'react';

import useMutationSetCompanyAttribute from '~/apollo/hooks/company/useMutationSetCompanyAttribute';
import useCompany from '~/hooks/useCompany';
import type { EmergencyContact } from '~/types/emergencyContact';

const EMERGENCY_CONTACTS_LIST_KEY = 'emergency_contacts_list';
const ON_CALL_PHONE_NUMBERS_KEY = 'emergency_contacts';

export default function useCompanyEmergencyContacts(): {
  emergencyContactsList: EmergencyContact[];
  setEmergencyContactsList: (contacts: EmergencyContact[]) => Promise<void>;
  onCallPhoneNumbers: string[];
  setOnCallPhoneNumbers: (phoneNumbers: string[]) => Promise<void>;
  isLoading: boolean;
} {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setCompanyAttribute, isLoading: isMutationLoading } = useMutationSetCompanyAttribute();
  const { currentCompany } = useCompany();

  const emergencyContactsList: EmergencyContact[] = useMemo(() => {
    const value = currentCompany?.attributes?.find(
      (attribute) => attribute?.name === EMERGENCY_CONTACTS_LIST_KEY,
    )?.value;
    try {
      return value ? JSON.parse(value) : [];
    } catch {
      return [];
    }
  }, [currentCompany?.attributes]);

  const setEmergencyContactsList = useCallback(
    async (contacts: EmergencyContact[]): Promise<void> => {
      setIsLoading(true);
      await setCompanyAttribute({
        name: EMERGENCY_CONTACTS_LIST_KEY,
        value: JSON.stringify(contacts),
      });
      setIsLoading(false);
    },
    [setCompanyAttribute],
  );

  const onCallPhoneNumbers: string[] = useMemo(() => {
    const value = currentCompany?.attributes?.find(
      (attribute) => attribute?.name === ON_CALL_PHONE_NUMBERS_KEY,
    )?.value;
    try {
      return value ? JSON.parse(value) : [];
    } catch {
      return [];
    }
  }, [currentCompany?.attributes]);

  const setOnCallPhoneNumbers = useCallback(
    async (phoneNumbers: string[]): Promise<void> => {
      setIsLoading(true);
      await setCompanyAttribute({
        name: ON_CALL_PHONE_NUMBERS_KEY,
        value: JSON.stringify(phoneNumbers),
      });
      setIsLoading(false);
    },
    [setCompanyAttribute],
  );

  return useMemo(
    () => ({
      emergencyContactsList,
      setEmergencyContactsList,
      onCallPhoneNumbers,
      setOnCallPhoneNumbers,
      isLoading: isLoading || isMutationLoading,
    }),
    [
      emergencyContactsList,
      setEmergencyContactsList,
      onCallPhoneNumbers,
      setOnCallPhoneNumbers,
      isLoading,
      isMutationLoading,
    ],
  );
}
