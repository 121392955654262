'use client';

import { useQuery, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import QUERY_DEVICE_CONFIGURATION_OVERRIDE_FOR_SUBSIDIARY, {
  type DeviceConfigurationOverrideForSubsidiaryQueryData,
  type DeviceConfigurationOverrideForSubsidiaryQueryVariables,
} from '~/apollo/operations/queries/QueryDeviceConfigurationOverrideForSubsidiary';
import type { DeviceConfigurationOverride } from '~/types/configurationDevice';

export default function useQueryDeviceConfigurationOverrideForSubsidiary({
  subsidiaryId,
  skip,
}: {
  subsidiaryId: string | undefined;
  skip: boolean;
}): {
  deviceConfigurationOverrideForSubsidiary: DeviceConfigurationOverride | undefined;
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => void;
} {
  const { data, loading, error, refetch } = useQuery<
    DeviceConfigurationOverrideForSubsidiaryQueryData,
    DeviceConfigurationOverrideForSubsidiaryQueryVariables
  >(QUERY_DEVICE_CONFIGURATION_OVERRIDE_FOR_SUBSIDIARY, {
    variables: {
      subsidiaryId: subsidiaryId || '',
      nextToken: null,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: skip || !subsidiaryId,
  });

  return useMemo(
    () => ({
      deviceConfigurationOverrideForSubsidiary: data?.subsidiary?.configuration,
      isLoading: loading,
      error,
      refetch,
    }),
    [data?.subsidiary?.configuration, loading, error, refetch],
  );
}
