'use client';

import MailOutlined from '@ant-design/icons/MailOutlined';
import PaperClipOutlined from '@ant-design/icons/PaperClipOutlined';
import { memo } from 'react';
import styled from 'styled-components';

import AboutContent from '~/components/AboutContent';
import Container from '~/components/Container';
import Link, { UnderlineLink } from '~/components/Link';
import PageContentWrapper from '~/components/PageContentWrapper';
import PageHeader from '~/components/PageHeader';
import { SUPPORT_EMAIL, SUPPORT_TICKET_URL } from '~/config/constants';
import routes from '~/config/routes';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import curateUrl from '~/utils/parse/curateUrl';

const WrapperDiv = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 16px;

  ${theme.medias.lteSmall} {
    grid-template-columns: 1fr;
  }
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-gap: 16px;
`;

const ICON_SIZE = 42;

const StyledLink = styled(Link)`
  display: grid;
  grid-template-columns: minmax(0, ${ICON_SIZE}px) minmax(0, 1fr);
  grid-gap: 32px;
  align-items: center;
  padding: 32px;
  background: ${theme.colors.white};
  border-radius: 12px;
  transition: all 20ms ease-in-out;
  overflow: hidden;

  &:hover {
    background: ${theme.colors.lightGrey};
  }

  svg {
    font-size: ${ICON_SIZE}px;
  }

  h4 {
    font-size: 24px;
    margin: 0 0 8px;
  }

  p {
    font-size: 16px;
    margin: 0;
  }

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 12px;
  }
`;

const DisclaimerP = styled.p`
  font-size: 18px;
  font-style: italic;
  margin: 16px 8px 0;
  line-height: 1.5;

  & a {
    color: ${theme.colors.blue};

    &:hover {
      color: ${theme.colors.blue};
    }
  }
`;

const Img = styled.img`
  max-width: 100%;
  border-radius: 12px;
  margin-bottom: 16px;
`;

const SUPPORT_LINKS = [
  {
    key: 'email',
    icon: <MailOutlined />,
    title: i18n.t('supportPage.emailTitle'),
    descriptionHtml: i18n.t('supportPage.emailDescriptionHtml', { email: SUPPORT_EMAIL }),
    href: `mailto:${SUPPORT_EMAIL}`,
  },
  {
    key: 'helpCenter',
    icon: <PaperClipOutlined />,
    title: i18n.t('supportPage.ticketTitle'),
    descriptionHtml: i18n.t('supportPage.ticketDescriptionHtml'),
    href: SUPPORT_TICKET_URL,
  },
];

const CustomerSupportPage = memo(() => {
  const pageTitle = i18n.t('supportPage.title');

  return (
    <PageContentWrapper>
      <Container>
        <PageHeader title={pageTitle} subtitle={i18n.t('supportPage.subtitle')} />
        <WrapperDiv>
          <div>
            <Ul>
              {SUPPORT_LINKS.map((link) => (
                <li key={link.key} data-id={`support-${link.key}-link`}>
                  <StyledLink to={link.href} target="_blank">
                    {link.icon}
                    <div>
                      <h4>{link.title}</h4>
                      {/* eslint-disable-next-line react/no-danger */}
                      <p dangerouslySetInnerHTML={{ __html: link.descriptionHtml }} />
                    </div>
                  </StyledLink>
                </li>
              ))}
            </Ul>
            <DisclaimerP>
              {i18n.t('supportPage.disclaimerFirst')}{' '}
              <UnderlineLink to={routes.userManual}>
                {i18n.t('supportPage.disclaimerSecond')}
              </UnderlineLink>{' '}
              {i18n.t('supportPage.disclaimerThird')}
            </DisclaimerP>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <Img src={curateUrl('/images/customer-support.jpg')} alt={pageTitle} />
            <AboutContent />
          </div>
        </WrapperDiv>
      </Container>
    </PageContentWrapper>
  );
});

CustomerSupportPage.displayName = 'CustomerSupportPage';

export default CustomerSupportPage;
