'use client';

import { EditOutlined, EyeOutlined, SaveOutlined } from '@ant-design/icons';
import { List, Select, Switch, Input, Button } from 'antd';
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import { memo, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import useMutationSetCompanyAttribute from '~/apollo/hooks/company/useMutationSetCompanyAttribute';
import SettingsSelectCompany from '~/components/settings/components/SettingsSelectCompany';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import { SelectCompanySubsidiaryDiv, SettingsTopFlexDiv } from '~/components/settings/shared';
import useCompany from '~/hooks/useCompany';
import useCompanyMobileAppFeatures, {
  MOBILE_APP_FEATURES_ATTRIBUTE_NAME,
} from '~/hooks/useCompanyMobileAppFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import notification from '~/utils/notification';

const ListDiv = styled.div`
  background: white;
  padding: 16px;
  border-radius: 8px;

  .ant-list-item {
    &:last-of-type {
      padding-bottom: 0;
    }
  }
`;

const ItemGridDiv = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 16px;

  ${theme.medias.lteLarge} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  ${theme.medias.lteMedium} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const EditButtonsFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TabMobileAppFeatures = memo(() => {
  const { currentCompany } = useCompany();

  const { mobileAppFeatures, configurationValue } = useCompanyMobileAppFeatures();

  const { setCompanyAttribute, isLoading } = useMutationSetCompanyAttribute();

  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const [textAreaValue, setTextAreaValue] = useState(configurationValue);

  useEffect(() => {
    setTextAreaValue(configurationValue);
  }, [mode, configurationValue]);

  const hasError = useMemo(() => {
    try {
      JSON.parse(textAreaValue);
      return false;
    } catch (error) {
      return true;
    }
  }, [textAreaValue]);

  return (
    <>
      <SelectCompanySubsidiaryDiv>
        <div>
          <TabSectionHeader title={i18n.t('companyFeatures.selectedCompany')} />
          <SettingsSelectCompany />
        </div>
      </SelectCompanySubsidiaryDiv>
      <div>
        <SettingsTopFlexDiv>
          {!currentCompany?.id ? (
            <TabSectionHeader title={i18n.t('companyFeatures.missingCompany')} />
          ) : (
            <>
              <TabSectionHeader title={`Schema version: ${mobileAppFeatures.schemaVersion}`} />
              {mode === 'edit' ? (
                <Button
                  type="primary"
                  icon={<EyeOutlined />}
                  disabled={isLoading}
                  onClick={() => {
                    setMode('view');
                  }}
                >
                  {i18n.t('mobileAppFeatures.viewConfiguration')}
                </Button>
              ) : (
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  disabled={isLoading}
                  onClick={() => {
                    setMode('edit');
                  }}
                >
                  {i18n.t('mobileAppFeatures.editConfiguration')}
                </Button>
              )}
            </>
          )}
        </SettingsTopFlexDiv>
        <ListDiv>
          <TabSectionHeader
            title={i18n.t('mobileAppFeatures.mobileAppConfiguration')}
            style={{ marginTop: 0 }}
          />
          {mode === 'edit' && (
            <>
              <Input.TextArea
                disabled={isLoading}
                style={{
                  fontFamily: 'monospace',
                  background: theme.colors.lightGrey,
                  borderColor: hasError ? theme.colors.red : theme.colors.darkGrey,
                }}
                value={textAreaValue}
                onChange={(event) => {
                  setTextAreaValue(event.target.value);
                }}
                autoSize
              />
              <EditButtonsFlexDiv style={{ marginTop: '16px' }}>
                <Button
                  size="middle"
                  type="default"
                  disabled={isLoading}
                  onClick={() => {
                    setMode('view');
                  }}
                >
                  {i18n.t('common.cancel')}
                </Button>
                <Button
                  size="middle"
                  type="primary"
                  loading={isLoading}
                  icon={<SaveOutlined />}
                  onClick={async () => {
                    try {
                      if (hasError) {
                        notification.error({
                          message: i18n.t('common.error'),
                          description: i18n.t('mobileAppFeatures.invalidJsonFormat'),
                        });
                        return;
                      }
                      await setCompanyAttribute({
                        name: MOBILE_APP_FEATURES_ATTRIBUTE_NAME,
                        value: textAreaValue,
                      });
                      notification.success({
                        message: i18n.t('common.success'),
                        description: i18n.t('mobileAppFeatures.savedSuccessfully'),
                      });
                    } catch (error) {
                      notification.error({
                        message: i18n.t('common.error'),
                        description: (error as any)?.message,
                      });
                    }
                  }}
                >
                  {i18n.t('common.save')}
                </Button>
              </EditButtonsFlexDiv>
            </>
          )}
          {mode === 'view' && (
            <List
              itemLayout="horizontal"
              dataSource={mobileAppFeatures.features}
              renderItem={(feature) => {
                const {
                  isEquipmentItem,
                  showDataInProfile,
                  dataSource,
                  brainVersion,
                  missionFeedbackUrl,
                  ...rest
                } = feature.properties || {};

                const entries = Object.entries(rest);

                const title = capitalize(startCase(feature.type));

                return (
                  <List.Item>
                    <ItemGridDiv>
                      <div>
                        <Switch style={{ marginRight: '8px' }} disabled checked={!!feature.type} />
                        <b>{title}</b>
                      </div>
                      {isEquipmentItem && (
                        <div>
                          <Switch
                            style={{ marginRight: '8px' }}
                            disabled
                            checked={isEquipmentItem === 'true'}
                          />
                          Display equipment status
                        </div>
                      )}
                      {showDataInProfile && (
                        <div>
                          <Switch
                            style={{ marginRight: '8px' }}
                            disabled
                            checked={showDataInProfile === 'true'}
                          />
                          Show data in profile
                        </div>
                      )}
                      {dataSource && (
                        <div>
                          Data source
                          <Select
                            style={{ width: '180px', marginLeft: '8px' }}
                            disabled
                            options={[
                              {
                                label: 'local',
                                value: 'local',
                              },
                              {
                                label: 'remote',
                                value: 'remote',
                              },
                            ]}
                            value={dataSource}
                            placeholder="Data source"
                          />
                        </div>
                      )}
                      {brainVersion && (
                        <div>
                          Brain version
                          <Select
                            style={{ width: '60px', marginLeft: '8px' }}
                            disabled
                            options={[
                              {
                                label: '4',
                                value: '4',
                              },
                              {
                                label: '3',
                                value: '3',
                              },
                            ]}
                            value={brainVersion}
                            placeholder="Brain version"
                          />
                        </div>
                      )}
                      {missionFeedbackUrl && (
                        <div>
                          Mission feedback URL
                          <Input
                            style={{ width: '180px', marginLeft: '8px' }}
                            disabled
                            value={missionFeedbackUrl}
                            placeholder="Mission feedback URL"
                          />
                        </div>
                      )}
                      {entries.map(([key, value]) => (
                        <div key={key}>
                          {startCase(key)}: {value as string}
                        </div>
                      ))}
                    </ItemGridDiv>
                  </List.Item>
                );
              }}
            />
          )}
        </ListDiv>
      </div>
    </>
  );
});

TabMobileAppFeatures.displayName = 'TabMobileAppFeatures';

export default TabMobileAppFeatures;
