'use client';

import { useReactiveVar } from '@apollo/client';
import { memo, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { newAlertVar } from '~/apollo/reactiveVariables/newAlertVar';
import DashboardOverlayTopLeft from '~/components/DashboardOverlayTopLeft';
import { DETAILS_POPUP_TYPE } from '~/components/DetailsPopup/config/types';
import MainMap from '~/components/maps/MainMap';
import {
  DEFAULT_GPS_POSITION,
  DEFAULT_MAP_ZOOM_LEVEL,
  CARRIER_MAP_ZOOM_LEVEL,
} from '~/config/defaults';
import routes from '~/config/routes';
import useAgentsContext from '~/context/useAgentsContext';
import useAgentStatuses from '~/hooks/useAgentStatuses';
import useInitialLoading from '~/hooks/useInitialLoading';
import usePreventPageScroll from '~/hooks/usePreventPageScroll';
import useQueryParams from '~/hooks/useQueryParams';
import useSubsidiary from '~/hooks/useSubsidiary';
import useTeams from '~/hooks/useTeams';
import type { GpsCoordinates } from '~/types/sensor';

const MapPage = memo(() => {
  const query = useQueryParams();
  const navigate = useNavigate();

  const { resetAgentStatuses } = useAgentStatuses();
  const { setSelectedTeams } = useTeams();
  const { carrierId } = useReactiveVar(newAlertVar);
  const { isInitialLoading } = useInitialLoading();
  const { getAgent, getVehicle } = useAgentsContext();
  const { currentSubsidiary } = useSubsidiary();

  usePreventPageScroll();

  const maybeVehicleOrAgentPosition = useMemo(
    () =>
      query.type === DETAILS_POPUP_TYPE.vehicle
        ? getVehicle(query.id ?? '')?.gps?.value
        : getAgent(query.id ?? '')?.sensors?.gps?.value,
    [getAgent, getVehicle, query.id, query.type],
  );

  const centerPosition: GpsCoordinates = useMemo(
    () => maybeVehicleOrAgentPosition || currentSubsidiary?.location || DEFAULT_GPS_POSITION,
    [currentSubsidiary?.location, maybeVehicleOrAgentPosition],
  );

  const zoom = useMemo(() => {
    const defaultZoom = query.id ? CARRIER_MAP_ZOOM_LEVEL : DEFAULT_MAP_ZOOM_LEVEL;

    return centerPosition !== DEFAULT_GPS_POSITION ? defaultZoom : DEFAULT_MAP_ZOOM_LEVEL;
  }, [centerPosition, query.id]);

  useEffect(() => {
    if (carrierId) {
      navigate(routes.map({ id: carrierId }));
    }
  }, [navigate, carrierId]);

  useEffect(() => {
    if (query.id || carrierId) {
      setSelectedTeams([]);
      resetAgentStatuses();
    }
  }, [carrierId, query.id, resetAgentStatuses, setSelectedTeams]);

  return (
    <>
      <MainMap
        key={`${currentSubsidiary?.id}${isInitialLoading}${JSON.stringify(query)}`}
        isLoading={isInitialLoading}
        isBlurred={false}
        initialZoom={zoom}
        initialLat={centerPosition.lat}
        initialLng={centerPosition.lng}
        followingZoom={undefined}
        followingLat={undefined}
        followingLng={undefined}
        isFocusedOnAgent={!!query?.id}
      />
      <DashboardOverlayTopLeft />
    </>
  );
});

MapPage.displayName = 'MapPage';

export default MapPage;
