'use client';

import { memo, type ReactNode } from 'react';
import styled from 'styled-components';

import theme from '~/theme';

const Ul = styled.ul`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 4px;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Button = styled.button<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  font-size: 15px;
  text-align: left;
  cursor: pointer;
  padding: 12px;
  border: none;
  border-radius: 4px;
  overflow: hidden;
  background: ${(props) => (props.$active ? theme.colors.primaryBlue : '#e0e2e9')};
  color: ${(props) => (props.$active ? theme.colors.white : theme.colors.black)};

  &:hover {
    background: ${(props) => (props.$active ? theme.colors.primaryBlue : '#d8dae0')};
  }
`;

interface Props {
  activeItemKey: string | undefined;
  items: {
    key: string;
    dataId: string;
    icon: ReactNode;
    label: string;
    onClick: () => void;
  }[];
}

const PageSideTabs = memo(({ activeItemKey, items }: Props) => (
  <Ul>
    {items.map((item) => (
      <li key={item.key}>
        <Button data-id={item.dataId} onClick={item.onClick} $active={activeItemKey === item.key}>
          <span style={{ display: 'inline-block', marginRight: '4px' }}>{item.icon}</span>
          {item.label}
        </Button>
      </li>
    ))}
  </Ul>
));

PageSideTabs.displayName = 'PageSideTabs';

export default PageSideTabs;
