'use client';

import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined';
import CaretUpOutlined from '@ant-design/icons/CaretUpOutlined';
import { Tooltip } from 'antd';
import { memo, useState, type ReactNode } from 'react';
import styled from 'styled-components';

import theme from '~/theme';
import { EQUIPMENT_STATUS, type EquipmentStatusDetails } from '~/types/equipment';

import PaperCard from '../../PaperCard';

const StyledPaperCard = styled(PaperCard)<{ $isOpenable?: boolean }>`
  padding: 0;
  border: 1px solid ${theme.colors.greyBackground};
  color: ${theme.colors.darkBlue};

  &:hover {
    border-color: ${(props) =>
      props.$isOpenable ? theme.colors.primaryBlue : theme.colors.greyBackground};
    color: ${(props) => (props.$isOpenable ? theme.colors.primaryBlue : theme.colors.darkBlue)};
  }
`;

const ItemButton = styled.button`
  background: transparent;
  border: none;
  position: relative;
  text-align: left;
  width: 100%;
  padding: 8px 12px;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, auto);
  align-items: center;
  gap: 34px;
  color: currentColor;
`;

const LabelSpan = styled.span`
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.2px;
`;

const ContentDiv = styled.div`
  padding: 0 12px 8px;
`;

export interface EquipmentItem {
  key: string;
  label: string;
  statusDetails: EquipmentStatusDetails | undefined;
  errorTooltip: string | undefined;
  content: ReactNode;
  isOpen: boolean;
}

function getStatusComponentAndColor(status: EQUIPMENT_STATUS): {
  isWorking: boolean;
  twoToneColor: string | [string, string];
} {
  switch (status) {
    case EQUIPMENT_STATUS.no_error:
      return {
        isWorking: true,
        twoToneColor: theme.colors.brightGreen,
      };
    case EQUIPMENT_STATUS.no_status:
    case EQUIPMENT_STATUS.no_sensor:
    case EQUIPMENT_STATUS.sensor_disconnected:
    case EQUIPMENT_STATUS.sensor_unpaired:
    case EQUIPMENT_STATUS.sensor_inactive:
      return {
        isWorking: false,
        twoToneColor: [theme.colors.midGrey, theme.colors.grey],
      };
    case EQUIPMENT_STATUS.ble_disabled:
    case EQUIPMENT_STATUS.phone_ble_disabled:
    case EQUIPMENT_STATUS.sensor_connecting:
    case EQUIPMENT_STATUS.sensor_data_error:
    case EQUIPMENT_STATUS.sensor_error:
    default:
      return {
        isWorking: false,
        twoToneColor: theme.colors.yellow,
      };
  }
}

interface Props {
  item: EquipmentItem;
}

const EquipmentItemCard = memo(({ item }: Props) => {
  const [open, setOpen] = useState<boolean>(item.isOpen);

  const isOpenable = !!item.content;

  const defaultCursor = item.errorTooltip ? 'help' : 'default';

  const statusComponentAndColor = getStatusComponentAndColor(
    item.statusDetails?.status || EQUIPMENT_STATUS.no_status,
  );

  return (
    <Tooltip title={item.errorTooltip} placement="rightTop">
      <div>
        <StyledPaperCard $isOpenable={isOpenable}>
          <ItemButton
            data-id={`EquipmentItemCard-button-${item.key}`}
            onClick={() => {
              setOpen((prevOpen) => !prevOpen);
            }}
            style={{ cursor: isOpenable ? 'pointer' : defaultCursor }}
          >
            <LabelSpan>
              {item.label} {isOpenable && open && <CaretUpOutlined />}
              {isOpenable && !open && <CaretDownOutlined />}
            </LabelSpan>
            {statusComponentAndColor.isWorking ? (
              <CheckCircleTwoTone
                twoToneColor={statusComponentAndColor.twoToneColor}
                style={{ fontSize: '18px' }}
                data-id="ok-icon"
              />
            ) : (
              <CloseCircleTwoTone
                twoToneColor={statusComponentAndColor.twoToneColor}
                style={{ fontSize: '18px' }}
                data-id="nok-icon"
              />
            )}
          </ItemButton>

          {open && isOpenable && <ContentDiv>{item.content}</ContentDiv>}
        </StyledPaperCard>
      </div>
    </Tooltip>
  );
});

EquipmentItemCard.displayName = 'EquipmentItemCard';

export default EquipmentItemCard;
