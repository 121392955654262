'use client';

import { memo, useMemo } from 'react';

import SectionList from '~/components/DetailsPopup/components/SectionList';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import formatNumberWithUnit from '~/utils/parse/formatNumberWithUnit';

const RADIATION_UNIT = 'μSv/h';
const RADIATION_DECIMALS = 3;

interface Props {
  agent: Agent | undefined;
  isHealthy: boolean;
}

const ContentRadiation = memo(({ agent, isHealthy }: Props) => {
  const radiation = agent?.equipmentStatus.radiation.healthy
    ? agent?.sensors?.radiation
    : undefined;

  const gammaValue = formatNumberWithUnit({
    number: isHealthy ? radiation?.value?.gamma : undefined,
    unit: RADIATION_UNIT,
    decimals: RADIATION_DECIMALS,
  });

  const backgroundValue = formatNumberWithUnit({
    number: isHealthy ? radiation?.value?.background : undefined,
    unit: RADIATION_UNIT,
    decimals: RADIATION_DECIMALS,
  });

  const list = useMemo(
    () => [
      {
        labelText: i18n.t('carrierDetailsPopup.radiation.gamma'),
        valueText: gammaValue,
        style: { opacity: gammaValue === 'n/a' ? 0.4 : 1 },
      },
      {
        labelText: i18n.t('carrierDetailsPopup.radiation.background'),
        valueText: backgroundValue,
        style: { opacity: backgroundValue === 'n/a' ? 0.4 : 1 },
      },
    ],
    [gammaValue, backgroundValue],
  );

  if (!agent) {
    return null;
  }

  return <SectionList elements={list} />;
});

ContentRadiation.displayName = 'ContentRadiation';

export default ContentRadiation;
