'use client';

import { useState, useEffect, useRef, memo } from 'react';
import styled from 'styled-components';

import RecenterMapButton from '~/components/maps/RecenterMapButton';
import {
  DEFAULT_MAP_ZOOM_LEVEL,
  DEFAULT_MAP_ZOOM_LEVEL_MIN,
  DEFAULT_MAP_ZOOM_LEVEL_MAX,
  DEFAULT_GPS_POSITION,
} from '~/config/defaults';
import mapThemes from '~/config/mapThemes';
import { currentLanguage } from '~/locales/i18n';
import useMapSettings from '~/store/useMapSettings';
import { AGENT_STATUS } from '~/types/agent';
import type { LocationHistoryPoint } from '~/types/locationHistory';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';
import { formatTime } from '~/utils/dateTime';
import getCenterFromCoordinates from '~/utils/map/getCenterFromCoordinates';
import mapFitBounds from '~/utils/map/mapFitBounds';

import getHourFromTimestamp from '../utils/getHourFromTimestamp';

const HIGHLIGHTED_POINT_COLOR = 'orange';

function getDotContent(isHighlighted: boolean): HTMLDivElement {
  const content = document.createElement('div');
  const color = isHighlighted
    ? HIGHLIGHTED_POINT_COLOR
    : getAgentStatusColor({
        status: AGENT_STATUS.inMission,
        isOffline: false,
      });
  const zIndex = isHighlighted ? 2 : 1;
  content.innerHTML = `
    <div
      class="LocationHistoryMap__dot"
      style="
        background: ${color};
        z-index: ${zIndex};
      ">
    </div>
  `;
  return content;
}

const WrapperDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MapDiv = styled.div`
  width: 100%;
  height: 100%;
`;

export interface Props {
  agentId: string | undefined;
  activeHourHover: string | null;
  activeHourClick: string | null;
  locationPoints: LocationHistoryPoint[];
  isLoading: boolean;
}

const LocationHistoryMapComponent = memo(
  ({ agentId, activeHourHover, activeHourClick, locationPoints, isLoading }: Props) => {
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);

    const [map, setMap] = useState<google.maps.Map>();

    const lastOpenedInfoWindowRef = useRef<google.maps.InfoWindow>();
    const pointMarkersRef = useRef<
      {
        timestamp: string;
        marker: google.maps.marker.AdvancedMarkerElement;
      }[]
    >([]);

    const mapDivRef = useRef<HTMLDivElement>(null);

    const mapTheme = useMapSettings((state) => state.mapTheme);

    useEffect(() => {
      (async () => {
        if (!!map || !agentId || !hasLoaded || !mapDivRef.current || !window.google) {
          return;
        }

        const newMap = new window.google.maps.Map(mapDivRef.current, {
          mapId: mapThemes[mapTheme].id,
          mapTypeId: mapThemes[mapTheme].type,
          // A Map's styles property cannot be set when a mapId is present. When a mapId is present, Map styles are controlled via the cloud console.
          // styles: mapThemes[mapTheme].styles,
          fullscreenControl: false,
          mapTypeControl: false,
          rotateControl: false,
          streetViewControl: false,
          mapTypeControlOptions: { position: window.google.maps.ControlPosition.TOP_RIGHT },
          zoomControlOptions: { position: window.google.maps.ControlPosition.RIGHT_TOP },
          minZoom: DEFAULT_MAP_ZOOM_LEVEL_MIN,
          maxZoom: DEFAULT_MAP_ZOOM_LEVEL_MAX,
          controlSize: 32,
        });
        newMap.setOptions({
          center:
            locationPoints.length > 0
              ? getCenterFromCoordinates(locationPoints)
              : DEFAULT_GPS_POSITION,
          zoom: DEFAULT_MAP_ZOOM_LEVEL,
        });
        const { AdvancedMarkerElement } = (await google.maps.importLibrary(
          'marker',
        )) as google.maps.MarkerLibrary;
        locationPoints.forEach((point) => {
          const isHighlighted = activeHourHover === getHourFromTimestamp(point.timestamp);
          const advancedMarkerElement = new AdvancedMarkerElement({
            map: newMap,
            content: getDotContent(isHighlighted),
            position: {
              lat: point.lat,
              lng: point.lng,
            },
            zIndex: isHighlighted ? Number.MAX_SAFE_INTEGER : 1,
            collisionBehavior: google.maps.CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY,
          });
          advancedMarkerElement?.addListener('click', () => {
            lastOpenedInfoWindowRef.current?.close();
            const date = new Date(point.timestamp).toLocaleString(currentLanguage(), {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: undefined,
              minute: undefined,
              second: undefined,
            });
            const time = formatTime(point.timestamp, 'standard');
            const agentName = window.agentsNameWithAcronymMap?.[agentId];
            const infoWindow = new google.maps.InfoWindow({
              content: `
            <div>
              <h4 style="margin: 0 0 0.25rem; font-size: 14px; font-weight: bold;">${agentName}</h4>
              <p style="margin: 0 0 0.25rem; font-size: 14px;">${date}</p>
              <p style="margin: 0; font-size: 14px;">${time}</p>
            </div>
          `,
              ariaLabel: 'Uluru',
            });
            infoWindow.open({
              anchor: advancedMarkerElement,
              map: newMap,
            });
            lastOpenedInfoWindowRef.current = infoWindow;
          });

          pointMarkersRef.current.push({
            timestamp: point.timestamp,
            marker: advancedMarkerElement,
          });
        });
        mapFitBounds({
          map: newMap,
          points: locationPoints,
        });
        setMap(newMap);
      })();
    }, [activeHourHover, agentId, hasLoaded, locationPoints, map, mapTheme]);

    useEffect(() => {
      if (activeHourClick) {
        // map?.setCenter(activeHourClick);
        mapFitBounds({
          map,
          points: locationPoints.filter(
            (point) => getHourFromTimestamp(point.timestamp) === activeHourClick,
          ),
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(activeHourClick)]);

    useEffect(() => {
      lastOpenedInfoWindowRef.current?.close();
      (async () => {
        pointMarkersRef.current.forEach(({ timestamp, marker }) => {
          const isHighlighted = activeHourHover === getHourFromTimestamp(timestamp);
          // eslint-disable-next-line no-param-reassign
          marker.content = getDotContent(isHighlighted);
          // eslint-disable-next-line no-param-reassign
          marker.zIndex = isHighlighted ? Number.MAX_SAFE_INTEGER : 1;
          return { timestamp, marker };
        });
      })();
    }, [activeHourHover]);

    useEffect(() => {
      if (!isLoading) {
        setHasLoaded(true);
      }
    }, [isLoading]);

    return (
      <WrapperDiv>
        <RecenterMapButton map={map} locationPoints={locationPoints} />
        <MapDiv ref={mapDivRef} data-id="locationHistoryMap" />
      </WrapperDiv>
    );
  },
);

LocationHistoryMapComponent.displayName = 'LocationHistoryMapComponent';

export default LocationHistoryMapComponent;
