'use client';

import { gql, useMutation, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

interface DisassociateBrainFromAgentInput {
  carrier_id: string;
  device_name: string;
}

interface DisassociateBrainFromAgentOutput {
  disassociateDeviceCarrier: {
    id: string;
  };
}

export default function useMutationDisassociateBrainFromAgent(): {
  disassociateBrainFromAgent: (params: {
    variables: DisassociateBrainFromAgentInput;
  }) => Promise<unknown>;
  isDisassociateBrainFromAgentLoading: boolean;
  error?: ApolloError;
} {
  const [disassociateBrainFromAgent, { loading, error }] = useMutation<
    DisassociateBrainFromAgentOutput,
    DisassociateBrainFromAgentInput
  >(
    gql`
      mutation MutationDisassociateBrainFromAgent($carrier_id: ID!, $device_name: String!) {
        disassociateDeviceCarrier(carrier_id: $carrier_id, device_name: $device_name) {
          id
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
    },
  );

  return useMemo(
    () => ({
      disassociateBrainFromAgent,
      isDisassociateBrainFromAgentLoading: loading,
      error,
    }),
    [disassociateBrainFromAgent, error, loading],
  );
}
