'use client';

import isString from 'lodash/isString';
import { memo, type CSSProperties } from 'react';
import styled from 'styled-components';

import useAgentsContext from '~/context/useAgentsContext';
import i18n, { currentLanguage } from '~/locales/i18n';
import theme from '~/theme';
import type { Log } from '~/types/log';
import getAgentNameWithAcronym from '~/utils/agent/getAgentNameWithAcronym';
import getLogTypeIcon from '~/utils/log/getLogTypeIcon';

const Article = styled.article`
  background-color: ${theme.colors.white};
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.thinGrey};
`;

const TypeNameH4 = styled.h4`
  color: ${theme.colors.darkBlue};
  font-size: 1rem;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TimestampDiv = styled.div`
  margin-bottom: 8px;
`;

interface Props {
  log: Log;
  className?: string;
  style?: CSSProperties;
}

const LogCard = memo(({ log, className, style }: Props) => {
  const { getAgent } = useAgentsContext();

  const logData = log.data as unknown as Record<string, string>;

  const renderLogDataItem = (key: string) => {
    const value = logData[key];
    if (!value) {
      return null;
    }
    const genericValue = isString(value) ? value : JSON.stringify(value);
    const agentValue = key === 'carrier_id' ? getAgentNameWithAcronym(getAgent(value)) : null;

    return (
      <div>
        <b>{i18n.t(`logsPage.attribute.${key}`)}:</b>{' '}
        {isString(value) ? agentValue || genericValue : JSON.stringify(value)}
      </div>
    );
  };

  return (
    <Article style={style} className={className}>
      <div />
      <TypeNameH4>
        <span style={{ transform: 'translateY(2px)', fontSize: '22px' }}>
          {getLogTypeIcon(log.data.type)}
        </span>
        {i18n.t(`general.logs.type.${log.data.type}`)}
      </TypeNameH4>
      <TimestampDiv>
        {new Date(log.timestamp).toLocaleString(currentLanguage(), {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        })}
      </TimestampDiv>
      <div>
        {renderLogDataItem('alarm_id')}
        {renderLogDataItem('administrator_id')}
        {renderLogDataItem('carrier_id')}
        {renderLogDataItem('email')}
        {renderLogDataItem('name')}
        {renderLogDataItem('location')}
        {renderLogDataItem('attributes')}
        {renderLogDataItem('attribute_name')}
        {renderLogDataItem('attribute_value')}
        {renderLogDataItem('device_name')}
        {renderLogDataItem('ivs_channel_arn')}
        {renderLogDataItem('ivs_channel_playback_url')}
        {renderLogDataItem('requested_status')}
      </div>
    </Article>
  );
});

LogCard.displayName = 'LogCard';

export default LogCard;
