'use client';

import { Button } from 'antd';
import differenceBy from 'lodash/differenceBy';
import { memo, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import routes from '~/config/routes';
import useAgentsContext from '~/context/useAgentsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import getDysfunctionalEquipment from '~/utils/equipment/getDysfunctionalEquipment';
import logger from '~/utils/logger';
import notification from '~/utils/notification';
import curateUrl from '~/utils/parse/curateUrl';

const MoreButton = styled(Button)`
  padding: 0;
`;

const alarm = new Audio(curateUrl('/sounds/alarm-2-beeps.wav'));

window.agentConnectionAndDeviceErrorListenerAlarm = alarm; // Used in E2E tests

type IssueType = 'connection' | 'device';

interface NotificationType {
  key: string;
  type: IssueType;
  id: string;
  name: string;
}

function getNotification({ id, completeName }: Agent, type: IssueType) {
  return {
    key: `${type}_${id}`,
    type,
    id,
    name: completeName,
  };
}

const AgentConnectionAndDeviceErrorListener = memo(() => {
  const { agents } = useAgentsContext();
  const { companyFeatures } = useCompanyFeatures();
  const navigate = useNavigate();
  const { currentSubsidiary } = useSubsidiary();

  const stateRef = useRef<{
    parsedAgents: Record<string, Agent>;
    activeNotifications: NotificationType[];
    dismissedNotifications: NotificationType[];
  }>({
    parsedAgents: {},
    activeNotifications: [],
    dismissedNotifications: [],
  });

  const dismissNotifications = useCallback(
    (notificationsToDismiss: NotificationType[], withRemove?: boolean) => {
      // TODO: Find out why on logout destruction needs to be delayed till next tick
      setTimeout(() => notificationsToDismiss.forEach(({ key }) => notification.destroy(key)), 0);

      if (withRemove) {
        stateRef.current.activeNotifications = differenceBy(
          stateRef.current.activeNotifications,
          notificationsToDismiss,
          'key',
        );
        stateRef.current.dismissedNotifications = differenceBy(
          stateRef.current.dismissedNotifications,
          notificationsToDismiss,
          'key',
        );
      }
    },
    [],
  );

  const resetState = useCallback(() => {
    stateRef.current.parsedAgents = {};
    dismissNotifications(
      [...stateRef.current.activeNotifications, ...stateRef.current.dismissedNotifications],
      true,
    );
  }, [dismissNotifications]);

  useEffect(
    () => () => {
      resetState();
    },
    [resetState],
  );

  useEffect(() => {
    resetState();
  }, [resetState, currentSubsidiary?.id]);

  useEffect(() => {
    if (!companyFeatures.issueTracking || !agents.length) {
      return;
    }

    const setNotification = (newNotification: NotificationType) => {
      const handleNotificationClose = (key: string) => {
        const foundActiveNotification = stateRef.current.activeNotifications.find(
          (activeNotification) => activeNotification.key === key,
        );
        stateRef.current.activeNotifications = stateRef.current.activeNotifications.filter(
          (activeNotification) => activeNotification !== foundActiveNotification,
        );
        // Pushing notification to dismissed only on close icon click since notification
        // onClose is called after both notification.destroy call and close icon click.
        if (foundActiveNotification) {
          stateRef.current.dismissedNotifications.push(foundActiveNotification);
        }
      };
      notification.warning({
        key: newNotification.key,
        message: i18n.t(`agentConnectionAndDeviceErrorListener.${newNotification.type}Title`, {
          agent: newNotification.name,
        }),
        description: (
          <MoreButton
            type="link"
            onClick={() => {
              navigate(routes.status({ id: newNotification.id }));
              dismissNotifications([newNotification]);
            }}
          >
            {i18n.t('agentConnectionAndDeviceErrorListener.moreInfo')}
          </MoreButton>
        ),
        duration: null,
        onClose: () => handleNotificationClose(newNotification.key),
      });
    };

    const setNotifications = (newNotifications: NotificationType[]) => {
      if (!newNotifications.length) {
        return;
      }
      stateRef.current.activeNotifications = [
        ...newNotifications,
        ...stateRef.current.activeNotifications,
      ];
      newNotifications.forEach(setNotification);
      alarm.play()?.catch((error) => {
        logger.log('AgentConnectionAndDeviceErrorListener: alarm play failed', { error });
      });
    };

    // not allowing positive to go into negative
    // i.e. connected to connection lost and functional to dysfunctional equipment.
    stateRef.current.parsedAgents = agents.reduce(
      (stateAgents, agent) => ({
        ...stateAgents,
        [agent.id]: {
          ...agent,
          ...(stateRef.current.parsedAgents[agent.id]
            ? {
                connectionLost:
                  agent.connectionLost && stateRef.current.parsedAgents[agent.id].connectionLost,
              }
            : {}),
          ...(stateRef.current.parsedAgents[agent.id] &&
          !getDysfunctionalEquipment(
            stateRef.current.parsedAgents[agent.id].equipmentStatus,
            companyFeatures,
          ).length &&
          getDysfunctionalEquipment(agent.equipmentStatus, companyFeatures).length
            ? { equipmentStatus: stateRef.current.parsedAgents[agent.id].equipmentStatus }
            : {}),
        },
      }),
      {} as Record<string, Agent>,
    );

    const connectionLostAgents = agents.filter(
      ({ id, isOffline, connectionLost }) =>
        !isOffline && connectionLost && !stateRef.current.parsedAgents[id]?.connectionLost,
    );

    const connectionNotifications = connectionLostAgents.map((agent) =>
      getNotification(agent, 'connection'),
    );

    const connectionNotificationsToAdd = differenceBy(
      connectionNotifications,
      [...stateRef.current.activeNotifications, ...stateRef.current.dismissedNotifications],
      'key',
    );

    const deviceIssueAgents = agents.filter((agent) => {
      const stateAgent = stateRef.current.parsedAgents[agent.id];
      return (
        stateAgent &&
        !agent.isOffline &&
        getDysfunctionalEquipment(agent.equipmentStatus, companyFeatures).length &&
        !getDysfunctionalEquipment(stateAgent.equipmentStatus, companyFeatures).length
      );
    });

    const deviceNotifications = deviceIssueAgents.map((agent) => getNotification(agent, 'device'));

    const deviceNotificationsToAdd = differenceBy(
      deviceNotifications,
      [...stateRef.current.activeNotifications, ...stateRef.current.dismissedNotifications],
      'key',
    );

    const notificationsToRemove = differenceBy(
      [...stateRef.current.activeNotifications, ...stateRef.current.dismissedNotifications],
      [...connectionNotifications, ...deviceNotifications],
      'key',
    );

    dismissNotifications(notificationsToRemove, true);
    setNotifications([...connectionNotificationsToAdd, ...deviceNotificationsToAdd]);
  }, [dismissNotifications, navigate, companyFeatures, agents]);

  return null;
});

AgentConnectionAndDeviceErrorListener.displayName = 'AgentConnectionAndDeviceErrorListener';

export default AgentConnectionAndDeviceErrorListener;
