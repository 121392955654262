import type { ConfigurationOverrideScope } from '~/types/configuration';

export type ConfigurationClientScope = 'platform' | 'company' | 'subsidiary' | 'agent';

export type WbrOrientation = 'vertical_A' | 'vertical_B' | 'horizontal_A' | 'horizontal_B';

export interface DeviceConfiguration {
  __typename?: 'DeviceConfiguration';
  name?: 'base' | string;
  last_updated?: string;
  activated: boolean | null;
  wbr_orientation: WbrOrientation | null; // Brain orientation [default: 'vertical_A']
  embedded_sound: boolean | null; // Embedded sensor sound [default: false]
  delay_disconn_sec: number | null; // Disconnection delay in seconds (disconnected) light turns red after [default: 10]
  delay_bad_conn_sec: number | null; // Disconnection delay in seconds (bad connection) light turns yellow after [default: 10]
  lte_debug_mode: boolean | null; // Debug mode (which will allow to send LTE signal strength shadow every 10s without LTE bars change) [default: false]
  gps_pr: number | null; // GPS shadow update period in seconds [default: 5]
  physio_pr: number | null; // Physiological shadow update period in seconds [default: 10]
  env_pr: number | null; // Environmental shadow update period in seconds [default: 10]
  // Shadow list
  sl: {
    name: string;
    value: boolean | null;
  }[];
}

/*
Shadow list example:
{
    "fall": true,
    "emergency": true,
    "traak_front": false,
    "traak_back": false,
    "external_temperature": false,
    "gps": true,
    "body_multi_sensor_v1": false,
    "heart_rate": true,
    "battery": true,
    "gas": false,
    "driving_type": false,
    "proximity_tag": true,
    "activity_level": false,
    "act_lvl_acc": false,
    "fall_debug": true,
    "lte_signal_strength": true,
    "rtc_info": true,
    "radiation": false
}
*/

export interface DeviceConfigurationOverride extends DeviceConfiguration {
  scope: ConfigurationOverrideScope;
}

export enum CONFIGURATION_SCOPE_LEVEL {
  platform = 1,
  company = 2,
  subsidiary = 3,
  agent = 4,
}
