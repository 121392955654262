'use client';

import LockOutlined from '@ant-design/icons/LockOutlined';
import QrcodeOutlined from '@ant-design/icons/QrcodeOutlined';
import { Input, Form } from 'antd';
import { memo, useState, useCallback, type FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthFormButton from '~/components/forms/components/AuthFormButton';
import AuthFormHeader from '~/components/forms/components/AuthFormHeader';
import PasswordPolicyPopover from '~/components/PasswordPolicyPopover';
import routes from '~/config/routes';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import browserStorage, { BROWSER_STORAGE_KEY } from '~/utils/browserStorage';
import notification from '~/utils/notification';

interface FormFields {
  confirmationCode: string;
  password: string;
}

const ResetPasswordPage = memo(() => {
  const { passwordReset } = useAuthenticationContext();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormFields>();

  const [confirmDirty, setConfirmDirty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleBlur = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      setConfirmDirty(confirmDirty || !!value);
    },
    [confirmDirty],
  );

  const compareToFirstPassword = useCallback(
    (_rule: object, value: string) => {
      if (value && value !== form.getFieldValue('password')) {
        return Promise.reject(new Error(i18n.t<string>('passwordReset.inconsistentPasswords')));
      }
      return Promise.resolve();
    },
    [form],
  );

  const handleFinish = useCallback(
    ({ confirmationCode, password }: FormFields) => {
      setLoading(true);
      passwordReset({
        newPassword: password.trim(),
        confirmationCode: confirmationCode || '',
      })
        .then(() => {
          notification.success({
            message: i18n.t('passwordReset.successfulTitle'),
            description: i18n.t('passwordReset.successfulText'),
          });
          browserStorage.local.set(BROWSER_STORAGE_KEY.LOGIN_TIME, Date.now());
          navigate(routes.map());
        })
        .catch((catchedError: Error) => {
          notification.error({
            message: i18n.t('passwordReset.failedText'),
            description: catchedError.message,
          });
          setLoading(false);
        });
    },
    [navigate, passwordReset],
  );

  return (
    <Form form={form} onFinish={handleFinish}>
      <AuthFormHeader
        title={i18n.t('passwordReset.checkYourEmailTitle')}
        description={i18n.t('passwordReset.checkYourEmailText')}
      />
      <Form.Item
        name="confirmationCode"
        rules={[
          {
            required: true,
            len: 6,
            message: i18n.t<string>('passwordReset.confirmationCodeRulesMessage'),
            pattern: /^[0-9]+$/,
          },
        ]}
      >
        <Input
          type="number"
          required
          addonBefore={<QrcodeOutlined style={{ color: theme.colors.black }} />}
          size="large"
          placeholder={i18n.t<string>('passwordReset.confirmationCodePlaceholder')}
        />
      </Form.Item>
      <PasswordPolicyPopover>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: i18n.t<string>('passwordReset.newPasswordRulesMessage'),
            },
          ]}
        >
          <Input.Password
            type="password"
            required
            addonBefore={<LockOutlined style={{ color: theme.colors.black }} />}
            placeholder={i18n.t<string>('passwordReset.newPasswordPlaceholder')}
            autoComplete="new-password"
          />
        </Form.Item>
      </PasswordPolicyPopover>
      <Form.Item
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: i18n.t<string>('passwordReset.confirmPasswordRulesMessage'),
          },
          { validator: compareToFirstPassword },
        ]}
      >
        <Input.Password
          type="password"
          required
          addonBefore={<LockOutlined style={{ color: theme.colors.black }} />}
          placeholder={i18n.t<string>('passwordReset.confirmPasswordPlaceholder')}
          onBlur={handleBlur}
          autoComplete="new-password"
        />
      </Form.Item>
      <AuthFormButton loading={loading}>{i18n.t('passwordReset.submitButton')}</AuthFormButton>
    </Form>
  );
});

ResetPasswordPage.displayName = 'ResetPasswordPage';

export default ResetPasswordPage;
