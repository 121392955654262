'use client';

import AntdText, { type TextProps } from 'antd/lib/typography/Text';
import { memo, type ReactNode, type CSSProperties, type MouseEvent } from 'react';

const weights = {
  light: 300,
  regular: 400,
  bold: 700,
};

export interface Props extends TextProps {
  children?: ReactNode;
  weight?: keyof typeof weights;
  className?: string;
  style?: CSSProperties;
  onMouseOver?: (event: MouseEvent<HTMLElement>) => void;
  onMouseOut?: (event: MouseEvent<HTMLElement>) => void;
  'data-id'?: string;
}

/** @deprecated We shouldn't rely on Ant Design for small components like this */
const Text = memo(
  ({
    children,
    weight = 'regular',
    className,
    style = {},
    onMouseOver,
    onMouseOut,
    'data-id': dataId,
  }: Props) => (
    <AntdText
      data-id={dataId}
      className={className}
      style={{
        fontWeight: weights[weight],
        ...style,
      }}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {children}
    </AntdText>
  ),
);

Text.displayName = 'Text';

export default Text;
