'use client';

import { useMutation } from '@apollo/client';
import { useMemo } from 'react';

import MUTATION_ASSOCIATE_ALARM_ADMIN, {
  type AssociateAlarmAdminMutationData,
  type AssociateAlarmAdminMutationVariables,
} from '~/apollo/operations/mutations/MutationAssociateAlarmAdmin';

export default function useMutationAssociateAlarmAdmin() {
  const [associateAlarmAdmin, { loading, error }] = useMutation<
    AssociateAlarmAdminMutationData,
    AssociateAlarmAdminMutationVariables
  >(MUTATION_ASSOCIATE_ALARM_ADMIN);

  return useMemo(
    () => ({
      associateAlarmAdmin,
      loading,
      error,
    }),
    [associateAlarmAdmin, error, loading],
  );
}
