'use client';

import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import type { Attribute } from '~/types/attribute';

interface QueryAgentListWithDetailsInput {
  subsidiaryId: string;
  nextToken: string | null;
  limit: number;
}

interface QueryAgentListWithDetailsOutput {
  subsidiary: {
    __typename: 'Subsidiary';
    id: string;
    name: string;
    carriers: {
      __typename: 'CarrierConnection';
      nextToken: string | null;
      items: {
        __typename: 'Carrier_Cognito' | 'Carrier_NonCognito';
        id: string;
        email: string | null;
        name: string;
        function: string | null;
        created_at: string;
        attributes: Attribute[];
        device: {
          __typename: 'Device';
          name: string;
        } | null;
      }[];
    };
  };
}

export default function useQueryAgentListWithDetails({
  subsidiaryId,
  nextToken,
  limit,
}: {
  subsidiaryId: string | undefined;
  nextToken: string | null;
  limit?: number;
}) {
  const { data, loading, error, refetch } = useQuery<
    QueryAgentListWithDetailsOutput,
    QueryAgentListWithDetailsInput
  >(
    gql`
      query QueryAgentListWithDetails($subsidiaryId: ID!, $nextToken: String, $limit: Int) {
        subsidiary(id: $subsidiaryId) {
          id
          name
          carriers(limit: 1000, nextToken: $nextToken, limit: $limit) {
            nextToken
            items {
              id
              ... on Carrier_Cognito {
                email
              }
              name
              function
              created_at
              attributes {
                name
                value
              }
              device {
                name
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      skip: !subsidiaryId,
      variables: {
        subsidiaryId: subsidiaryId || '',
        nextToken,
        limit: limit || 1000,
      },
    },
  );

  return useMemo(
    () => ({
      agents: data?.subsidiary?.carriers?.items || [],
      isLoading: loading,
      error,
      refetchAgentsWithDetails: refetch,
    }),
    [data?.subsidiary?.carriers?.items, loading, error, refetch],
  );
}
