'use client';

import { memo } from 'react';

import SectionList, {
  type SectionListItem,
} from '~/components/DetailsPopup/components/SectionList';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import formatNumberWithUnit from '~/utils/parse/formatNumberWithUnit';

interface Props {
  agent: Agent | undefined;
  isHealthy: boolean;
}

const ContentHeartRate = memo(({ agent, isHealthy }: Props) => {
  const { companyFeatures } = useCompanyFeatures();

  if (!agent) {
    return null;
  }

  const heartRate = agent?.equipmentStatus.heartRate.status ? agent?.sensors?.heartRate : undefined;

  const heartRateValue = formatNumberWithUnit({
    number: isHealthy ? heartRate?.value : undefined,
    unit: 'BPM',
  });

  const list: SectionListItem[] = [
    ...(companyFeatures.heartRateSensor
      ? [
          {
            style: {
              opacity: heartRateValue === 'n/a' ? 0.4 : 1,
            },
            labelText: i18n.t('carrierDetailsPopup.physiologicalSensors.heartRate'),
            valueText: heartRateValue,
            'data-id': 'heartRatePhysiologicalSensor',
          },
        ]
      : []),
  ];

  return <SectionList data-id="SectionPhysiologicalSensors" elements={list} />;
});

ContentHeartRate.displayName = 'ContentHeartRate';

export default ContentHeartRate;
