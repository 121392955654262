'use client';

import { useEffect, useMemo, useState } from 'react';

function getMatches(query: string): boolean {
  // Prevents SSR issues
  if (typeof window !== 'undefined') {
    return window.matchMedia(query).matches;
  }
  return false;
}

export default function useMediaQuery(query: string): boolean {
  const curatedQuery = query.replace('@media', '').trim();

  const [matches, setMatches] = useState<boolean>(getMatches(curatedQuery));

  const handleChange = () => {
    setMatches(getMatches(curatedQuery));
  };

  useEffect(() => {
    const matchMedia = window.matchMedia(curatedQuery);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen to matchMedia
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener('change', handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener('change', handleChange);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curatedQuery]);

  return useMemo(() => matches, [matches]);
}
