'use client';

import { makeVar } from '@apollo/client';

import type { AGENT_STATUS } from '~/types/agent';

export const SELECTED_AGENT_STATUSES_DEFAULT_VALUE: AGENT_STATUS[] = [];

export const selectedAgentStatusesVar = makeVar<AGENT_STATUS[]>(
  SELECTED_AGENT_STATUSES_DEFAULT_VALUE,
);
