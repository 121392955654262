'use client';

import { memo, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import DetailsPopup from '~/components/DetailsPopup';
import { DETAILS_POPUP_TYPE } from '~/components/DetailsPopup/config/types';
import MainMap from '~/components/maps/MainMap';
import RealtimeLocationDisabledBanner from '~/components/RealtimeLocationDisabledBanner';
import {
  CARRIER_MAP_ZOOM_LEVEL,
  DEFAULT_GPS_POSITION,
  DEFAULT_MAP_ZOOM_LEVEL,
} from '~/config/defaults';
import useAgentsContext from '~/context/useAgentsContext';
import useAgentStatuses from '~/hooks/useAgentStatuses';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useInitialLoading from '~/hooks/useInitialLoading';
import usePreventPageScroll from '~/hooks/usePreventPageScroll';
import useQueryParams from '~/hooks/useQueryParams';
import useSubsidiary from '~/hooks/useSubsidiary';
import useTeams from '~/hooks/useTeams';
import useMapSettings from '~/store/useMapSettings';
import theme from '~/theme';
import type { GpsCoordinates } from '~/types/sensor';

const WrapperDiv = styled.div`
  display: grid;
  grid-template-columns: ${theme.dimensions.detailsPopupWidth}px minmax(0, 1fr);

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const MapDiv = styled.div`
  position: relative;

  ${theme.medias.lteSmall} {
    display: none;
  }
`;

const AbsoluteTopDiv = styled.div`
  position: absolute;
  top: ${theme.dimensions.navbarHeight}px;
  left: ${theme.dimensions.detailsPopupWidth}px;
  padding: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  ${theme.medias.lteSmall} {
    display: none;
  }
`;

const StatusPage = memo(() => {
  const query = useQueryParams();

  const { resetAgentStatuses } = useAgentStatuses();
  const { setSelectedTeams } = useTeams();
  const { isInitialLoading } = useInitialLoading();
  const { getAgent, getVehicle } = useAgentsContext();
  const { currentSubsidiary } = useSubsidiary();
  const { showRealtimeLocationFeature } = useCompanyFeatures();

  usePreventPageScroll();

  const agent = useMemo(() => getAgent(query?.id ?? ''), [getAgent, query?.id]);

  const vehicle = useMemo(
    () => (agent?.attributes?.plate_number ? getVehicle(agent.attributes.plate_number) : undefined),
    [agent?.attributes?.plate_number, getVehicle],
  );

  const agentOrVehiclePosition = useMemo(
    () =>
      query?.type === DETAILS_POPUP_TYPE.vehicle ? vehicle?.gps?.value : agent?.sensors?.gps?.value,
    [agent?.sensors?.gps?.value, query?.type, vehicle?.gps?.value],
  );

  const showRealtimeLocation = showRealtimeLocationFeature(agent);

  const centerPosition = useMemo(() => {
    const defaultLocation = currentSubsidiary?.location || DEFAULT_GPS_POSITION;

    return showRealtimeLocation ? agentOrVehiclePosition || defaultLocation : defaultLocation;
  }, [currentSubsidiary?.location, showRealtimeLocation, agentOrVehiclePosition]);

  const [initialPosition, setInitialPosition] = useState<GpsCoordinates | null>(null);

  useEffect(() => {
    if (agentOrVehiclePosition && !initialPosition) {
      setInitialPosition(centerPosition);
    }
  }, [agentOrVehiclePosition, centerPosition, initialPosition]);

  const initialZoom = useMemo(
    () =>
      centerPosition.lat !== DEFAULT_GPS_POSITION.lat &&
      centerPosition.lng !== DEFAULT_GPS_POSITION.lng
        ? CARRIER_MAP_ZOOM_LEVEL
        : DEFAULT_MAP_ZOOM_LEVEL,
    [centerPosition],
  );

  const shouldFollowCarrier = useMapSettings((state) => state.shouldFollowCarrier);

  useEffect(() => {
    setSelectedTeams([]);
    resetAgentStatuses();
  }, [resetAgentStatuses, setSelectedTeams]);

  if (!query?.id) {
    return null;
  }

  const initialLat = initialPosition?.lat || centerPosition.lat;
  const initialLng = initialPosition?.lng || centerPosition.lng;

  return (
    <WrapperDiv>
      <DetailsPopup queryAgentId={query.id} isLoading={isInitialLoading} />
      <MapDiv>
        <MainMap
          key={`${query.id}${initialZoom}${initialLat}${initialLng}`}
          isLoading={isInitialLoading}
          isBlurred={!showRealtimeLocation}
          initialZoom={initialZoom}
          initialLat={initialLat}
          initialLng={initialLng}
          followingZoom={shouldFollowCarrier ? initialZoom : undefined}
          followingLat={shouldFollowCarrier ? centerPosition.lat : undefined}
          followingLng={shouldFollowCarrier ? centerPosition.lng : undefined}
          isFocusedOnAgent
        />
      </MapDiv>
      {!isInitialLoading && !showRealtimeLocation && (
        <AbsoluteTopDiv>
          <RealtimeLocationDisabledBanner />
        </AbsoluteTopDiv>
      )}
    </WrapperDiv>
  );
});

StatusPage.displayName = 'StatusPage';

export default StatusPage;
