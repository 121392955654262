'use client';

import { gql, useMutation } from '@apollo/client';
import { useMemo } from 'react';

export interface UpdateCompanyVariables {
  company_id: string;
  name: string;
}

export interface UpdateCompanyData {
  updateCompany: {
    __typename: 'Company';
    id: string;
  };
}

export default function useMutationUpdateCompany() {
  // TODO: This GraphQL query is still not implemented on the backend!
  const [updateCompany, { data, loading, error }] = useMutation<
    UpdateCompanyData,
    UpdateCompanyVariables
  >(
    gql`
      mutation MutationUpdateCompany($company_id: ID!, $name: String!) {
        updateCompany(company_id: $company_id, name: $name) {
          id
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
    },
  );

  return useMemo(
    () => ({
      updateCompany,
      result: data?.updateCompany,
      isLoading: loading,
      error,
    }),
    [updateCompany, data?.updateCompany, error, loading],
  );
}
