'use client';

import { Space } from 'antd';
import { memo } from 'react';
import { BsTelephone } from 'react-icons/bs';

import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';

import LabelWithValue from './LabelWithValue';

interface Props {
  agent: Agent | undefined;
}

const InfoPhoneNumber = memo(({ agent }: Props) => {
  if (!agent?.attributes?.phone_number) {
    return null;
  }

  return (
    <Space align="center">
      <BsTelephone color={theme.colors.darkBlue} size={18} />
      <LabelWithValue
        data-id="DetailsPopup-InfoPhoneNumber"
        label={i18n.t('carrierDetailsPopup.phoneNumber')}
        value={agent.attributes.phone_number}
        hasCopyToClipboard
      />
    </Space>
  );
});

InfoPhoneNumber.displayName = 'InfoPhoneNumber';

export default InfoPhoneNumber;
