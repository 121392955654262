'use client';

import { memo, useMemo } from 'react';
import styled from 'styled-components';

import useQueryAlarmConfigurations from '~/apollo/hooks/configurationAlarm/useQueryAlarmConfigurations';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import { SelectCompanySubsidiaryDiv } from '~/components/settings/shared';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { ALARM_TYPE } from '~/types/alarm';

import AlarmConfigurationCollapseList from './components/AlarmConfigurationCollapseList';

const WrapperDiv = styled.div`
  width: 900px;
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

const KNOWN_ALARM_NAMES: ALARM_TYPE[] = [
  ALARM_TYPE.emergency,
  ALARM_TYPE.fall,
  ALARM_TYPE.attack,
  ALARM_TYPE.traak_front,
  ALARM_TYPE.traak_back,
  ALARM_TYPE.stress,
  ALARM_TYPE.abnormal_stops,
  ALARM_TYPE.long_stops,
  ALARM_TYPE.gas_danger,
  ALARM_TYPE.gas_high,
];

const TabAlarmConfigurations = memo(() => {
  const { isSuperAdmin } = useCurrentUserContext();

  const { alarmConfigurations, isLoading } = useQueryAlarmConfigurations({ skip: !isSuperAdmin });

  const defaultAlarmConfigurations = useMemo(
    () =>
      alarmConfigurations.filter((alarmConfiguration) =>
        KNOWN_ALARM_NAMES.includes(alarmConfiguration.name as ALARM_TYPE),
      ),
    [alarmConfigurations],
  );

  const unknownAlarmConfigurations = useMemo(
    () =>
      alarmConfigurations.filter(
        (alarmConfiguration) => !KNOWN_ALARM_NAMES.includes(alarmConfiguration.name as ALARM_TYPE),
      ),
    [alarmConfigurations],
  );

  const alarmNamesNotInConfigurations = useMemo(
    () =>
      KNOWN_ALARM_NAMES.filter(
        (alarmName) =>
          !alarmConfigurations.some((alarmConfiguration) => alarmConfiguration.name === alarmName),
      ),
    [alarmConfigurations],
  );

  if (!isSuperAdmin) {
    return null;
  }

  return (
    <WrapperDiv>
      <SelectCompanySubsidiaryDiv />
      <TabSectionHeader
        title={
          isLoading
            ? i18n.t('common.loading')
            : `${i18n.t('alarmConfigurations.alarmPlatformConfigurations')} (${defaultAlarmConfigurations.length})`
        }
      />
      <AlarmConfigurationCollapseList alarmConfigurations={defaultAlarmConfigurations} />
      {!isLoading && alarmNamesNotInConfigurations.length > 0 && (
        <p>
          <b>
            {i18n.t('alarmConfigurations.missingPlatformConfigurations')} (
            {alarmNamesNotInConfigurations.length}):
          </b>{' '}
          {alarmNamesNotInConfigurations.join(', ')}
        </p>
      )}
      {unknownAlarmConfigurations.length > 0 && (
        <>
          <br />
          <TabSectionHeader
            title={`${i18n.t('alarmConfigurations.unknownAlarmConfigurations')} (${unknownAlarmConfigurations.length})`}
          />
          <AlarmConfigurationCollapseList alarmConfigurations={unknownAlarmConfigurations} />
        </>
      )}
    </WrapperDiv>
  );
});

TabAlarmConfigurations.displayName = 'TabAlarmConfigurations';

export default TabAlarmConfigurations;
