'use client';

import { gql, useQuery } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';

interface QuerySuperAdminsInput {
  nextToken: string | null;
  limit: number;
}

interface QuerySuperAdminsOutput {
  getAllAdministrators: {
    items: {
      __typename: 'SuperAdmin';
      id: string;
      email: string;
      first_name: string | null;
      last_name: string | null;
    }[];
  };
}

export default function useQuerySuperAdmins({
  nextToken,
  limit,
}: {
  nextToken: string | null;
  limit?: number;
}) {
  const { data, loading, error, refetch } = useQuery<QuerySuperAdminsOutput, QuerySuperAdminsInput>(
    gql`
      query QuerySuperAdmins($limit: Int, $nextToken: String) {
        getAllAdministrators(limit: $limit, nextToken: $nextToken) {
          items {
            ... on SuperAdmin {
              id
              email
              first_name
              last_name
            }
          }
        }
      }
    `,
    {
      variables: {
        limit: limit || 1000,
        nextToken,
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
  );

  return useMemo(
    () => ({
      superAdmins: orderBy(
        data?.getAllAdministrators?.items?.filter((admin) => admin.__typename === 'SuperAdmin'),
        ['email'],
        ['asc'],
      ),
      isLoading: loading,
      error,
      refetchSuperAdmins: refetch,
    }),
    [data?.getAllAdministrators?.items, loading, error, refetch],
  );
}
