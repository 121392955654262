'use client';

import { Modal } from 'antd';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import AgentAddEditForm from '~/components/forms/AgentAddEditForm';
import useAgentsContext from '~/context/useAgentsContext';
import i18n from '~/locales/i18n';
import type { ModalProps } from '~/types/modal';

const StyledAgentAddEditForm = styled(AgentAddEditForm)`
  > div:first-child {
    max-height: 70vh;
    overflow-y: auto;
    padding-right: 8px;
  }
`;

export interface AgentAddEditModalProps {
  agentId: string | undefined;
  refetchOnCompleted: () => Promise<void>;
}

const AgentAddEditModal = memo(
  ({ isOpen, onClose, agentId, refetchOnCompleted }: ModalProps & AgentAddEditModalProps) => {
    const { getAgent } = useAgentsContext();

    const agent = useMemo(() => (agentId ? getAgent(agentId) : undefined), [agentId, getAgent]);

    return (
      <Modal
        title={i18n.t(`agentForm.${agent ? 'edit' : 'add'}Agent`)}
        footer={null}
        centered
        width={500}
        open={isOpen}
        onCancel={onClose}
      >
        <StyledAgentAddEditForm
          key={agent?.id}
          agent={agent}
          onSuccess={refetchOnCompleted}
          onClose={onClose}
        />
      </Modal>
    );
  },
);

AgentAddEditModal.displayName = 'AgentAddEditModal';

export default AgentAddEditModal;
