'use client';

import { memo, useMemo } from 'react';

import SectionList from '~/components/DetailsPopup/components/SectionList';
import useAlarmsContext from '~/context/useAlarmsContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import {
  ALARM_TYPE,
  type AlarmWithCarrier,
  type ExistingGas,
  type GasAlarmValue,
} from '~/types/alarm';
import { GAS_MEASUREMENT_TYPE } from '~/types/measurements';
import { GAS_SENSOR_TYPE } from '~/types/sensor';
import formatNumberWithUnit from '~/utils/parse/formatNumberWithUnit';
import parseJSON from '~/utils/parse/parseJSON';

function hasGasAlarmFromType(ongoingAlarms: AlarmWithCarrier[], type: ExistingGas): boolean {
  const gasDangerAlarms = ongoingAlarms.filter(
    (alarm) => alarm.type === ALARM_TYPE.gas_danger || alarm.type === ALARM_TYPE.gas_high,
  );
  const gasHighAlarms = ongoingAlarms.filter((alarm) => alarm.type === ALARM_TYPE.gas_high);
  const gasAlarms = [...gasDangerAlarms, ...gasHighAlarms];
  return gasAlarms.some((alarm) => {
    const gasName = (parseJSON(alarm.value) as GasAlarmValue)?.gas;
    return gasName === type;
  });
}

interface Props {
  agent: Agent | undefined;
  isHealthy: boolean;
}

const ContentGasSensors = memo(({ agent, isHealthy }: Props) => {
  const { ongoingAlarms } = useAlarmsContext();

  const agentOngoingAlarms = useMemo(
    () => ongoingAlarms.filter((alarm) => alarm.carrier.id === agent?.id),
    [agent, ongoingAlarms],
  );

  const gasValues = agent?.equipmentStatus.gas.healthy ? agent?.sensors?.gas : undefined;

  const list: {
    type: GAS_SENSOR_TYPE;
    unit: GAS_MEASUREMENT_TYPE;
    hasAlarm: boolean;
    'data-id': string;
  }[] = useMemo(
    () => [
      {
        type: GAS_SENSOR_TYPE.co,
        unit: GAS_MEASUREMENT_TYPE.PPM,
        hasAlarm: hasGasAlarmFromType(agentOngoingAlarms, 'co'),
        'data-id': 'gasSensorCO',
      },
      {
        type: GAS_SENSOR_TYPE.co2,
        unit: GAS_MEASUREMENT_TYPE.PPM,
        hasAlarm: hasGasAlarmFromType(agentOngoingAlarms, 'co2'),
        'data-id': 'gasSensorCO2',
      },
      {
        type: GAS_SENSOR_TYPE.h2s,
        unit: GAS_MEASUREMENT_TYPE.PPM,
        hasAlarm: hasGasAlarmFromType(agentOngoingAlarms, 'h2s'),
        'data-id': 'gasSensorH2S',
      },
      {
        type: GAS_SENSOR_TYPE.ch4_hc,
        unit: GAS_MEASUREMENT_TYPE.PERCENT_LEL,
        hasAlarm: hasGasAlarmFromType(agentOngoingAlarms, 'ch4/hc'),
        'data-id': 'gasSensorCH4HC',
      },
      {
        type: GAS_SENSOR_TYPE.o2,
        unit: GAS_MEASUREMENT_TYPE.PERCENT,
        hasAlarm: hasGasAlarmFromType(agentOngoingAlarms, 'o2'),
        'data-id': 'gasSensorO2',
      },
    ],
    [agentOngoingAlarms],
  );

  const elements = useMemo(
    () =>
      list.map((item) => {
        const gasValue = formatNumberWithUnit({
          number: isHealthy ? gasValues?.value?.[item.type] : undefined,
          unit: item.unit,
        });
        const hasValue = gasValue !== 'n/a';

        return {
          style: {
            color: hasValue && item.hasAlarm ? theme.colors.red : undefined,
            opacity: hasValue ? 1 : 0.4,
          },
          labelText: i18n.t(`carrierDetailsPopup.gasSensors.${item.type}`),
          valueText: gasValue,
          'data-id': item['data-id'],
        };
      }),
    [gasValues, list, isHealthy],
  );

  if (!agent) {
    return null;
  }

  return <SectionList data-id="SectionGasSensors" elements={elements} />;
});

ContentGasSensors.displayName = 'ContentGasSensors';

export default ContentGasSensors;
