'use client';

import { useQuery } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';

import QUERY_COMPANY_SUBSIDIARY_LIST, {
  type CompanySubsidiaryListQueryData,
  type CompanySubsidiaryListQueryVariables,
} from '~/apollo/operations/queries/QueryCompanySubsidiaryList';

export default function useQueryCompanySubsidiaryList(companyID: string, skip = false) {
  const { data, loading, error, refetch } = useQuery<
    CompanySubsidiaryListQueryData,
    CompanySubsidiaryListQueryVariables
  >(QUERY_COMPANY_SUBSIDIARY_LIST, {
    variables: { companyID },
    skip,
  });

  return useMemo(
    () => ({
      subsidiaryList: orderBy(
        data?.company?.subsidiaries?.items || [],
        [(subsidiary) => subsidiary.name.toLowerCase()],
        ['asc'],
      ),
      isLoading: loading,
      error,
      refetch,
    }),
    [data?.company?.subsidiaries?.items, loading, error, refetch],
  );
}
