'use client';

import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { Button, Form, Input, Radio, Select, Space } from 'antd';
import orderBy from 'lodash/orderBy';
import { memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import useMutationCreateCompanyAdmin from '~/apollo/hooks/admins/useMutationCreateCompanyAdmin';
import useMutationCreateSubsidiaryAdmin from '~/apollo/hooks/admins/useMutationCreateSubsidiaryAdmin';
import useMutationCreateSuperAdmin from '~/apollo/hooks/admins/useMutationCreateSuperAdmin';
import SettingsSelectCompany from '~/components/settings/components/SettingsSelectCompany';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import { USER_TYPE } from '~/types/user';
import notification from '~/utils/notification';

import AccountCreated from './components/AccountCreated';

const BottomFlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export interface FormValues {
  role: USER_TYPE;
  email: string;
  subsidiaryId: string | undefined;
}

interface Props {
  initialValues?: Partial<FormValues>;
  onSuccess: () => Promise<void>;
  onClose?: () => void;
}

const AdminAddEditForm = memo(({ initialValues, onSuccess, onClose }: Props) => {
  const { isSubsidiaryAdmin, isSuperAdmin } = useCurrentUserContext();

  const isSuperAdminForm = initialValues?.role === USER_TYPE.SuperAdmin;

  const {
    createSuperAdmin,
    isLoading: isCreateSuperAdminLoading,
    createdSuperAdmin,
  } = useMutationCreateSuperAdmin();
  const {
    createCompanyAdmin,
    isLoading: isCreateCompanyAdminLoading,
    createdCompanyAdmin,
  } = useMutationCreateCompanyAdmin();
  const {
    createSubsidiaryAdmin,
    isLoading: isCreateSubsidiaryAdminLoading,
    createdSubsidiaryAdmin,
  } = useMutationCreateSubsidiaryAdmin();

  const wasAdminCreated = !!createdSuperAdmin || !!createdCompanyAdmin || !!createdSubsidiaryAdmin;

  const isLoading =
    isCreateSuperAdminLoading || isCreateCompanyAdminLoading || isCreateSubsidiaryAdminLoading;

  const { currentCompany } = useCompany();
  const { currentSubsidiary, subsidiaryList } = useSubsidiary();

  const [form] = Form.useForm<FormValues>();

  const role = Form.useWatch('role', form);

  const subsidiaryOptions = useMemo(
    () =>
      isSubsidiaryAdmin
        ? [
            {
              value: currentSubsidiary?.id,
              label: currentSubsidiary?.name,
            },
          ]
        : orderBy(
            subsidiaryList.map(({ id, name }) => ({
              value: id,
              label: name,
              lowercaseLabel: name.toLowerCase(),
            })),
            ['lowercaseLabel'],
            ['asc'],
          ),
    [currentSubsidiary?.id, currentSubsidiary?.name, isSubsidiaryAdmin, subsidiaryList],
  );

  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      if (formValues.role === USER_TYPE.SubsidiaryAdmin && !formValues.subsidiaryId) {
        notification.error({
          message: i18n.t('common.error'),
          description: i18n.t('companySettingsPage.adminsTab.youNeedToSelectASubsidiary'),
        });
        return;
      }
      try {
        if (formValues.role === USER_TYPE.SuperAdmin) {
          await createSuperAdmin({
            variables: {
              email: formValues.email,
            },
          });
        } else if (formValues.role === USER_TYPE.CompanyAdmin) {
          await createCompanyAdmin({
            variables: {
              email: formValues.email,
              companyId: currentCompany?.id || '',
            },
          });
        } else {
          await createSubsidiaryAdmin({
            variables: {
              email: formValues.email,
              subsidiaryId: formValues.subsidiaryId || '',
            },
          });
        }
        notification.success({
          message: i18n.t('common.success'),
          description: i18n.t('companySettingsPage.adminsTab.adminCreatedSuccessfully'),
        });
        await onSuccess();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        notification.error({
          message: i18n.t('common.error'),
          description: (error as any)?.message,
        });
      }
    },
    [createSuperAdmin, createCompanyAdmin, createSubsidiaryAdmin, currentCompany?.id, onSuccess],
  );

  if (wasAdminCreated) {
    return <AccountCreated email={form.getFieldValue('email')} onClose={onClose} />;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      validateTrigger="onBlur"
      initialValues={{
        ...initialValues,
        role: isSubsidiaryAdmin
          ? USER_TYPE.SubsidiaryAdmin
          : initialValues?.role || USER_TYPE.CompanyAdmin,
        subsidiaryId: isSubsidiaryAdmin ? currentSubsidiary?.id : initialValues?.subsidiaryId,
      }}
      autoComplete="off"
      onFinish={handleSubmit}
    >
      <div style={{ height: '8px' }} />
      <Form.Item name="role" label={i18n.t('common.role')}>
        <Radio.Group
          value={role}
          onChange={(event) => {
            form.setFieldValue('role', event.target.value);
          }}
        >
          <Space direction="horizontal">
            {isSuperAdminForm ? (
              <Radio value={USER_TYPE.SuperAdmin} disabled>
                {i18n.t(`roles.${USER_TYPE.SuperAdmin}`)}
              </Radio>
            ) : (
              <>
                <Radio value={USER_TYPE.CompanyAdmin} disabled={isLoading || isSubsidiaryAdmin}>
                  {i18n.t(`roles.${USER_TYPE.CompanyAdmin}`)}
                </Radio>
                <Radio value={USER_TYPE.SubsidiaryAdmin} disabled={isLoading}>
                  {i18n.t(`roles.${USER_TYPE.SubsidiaryAdmin}`)}
                </Radio>
              </>
            )}
          </Space>
        </Radio.Group>
      </Form.Item>
      {!isSuperAdminForm && (
        <>
          {isSuperAdmin && (
            <Form.Item label={i18n.t('common.company')}>
              <SettingsSelectCompany disabled={!isSuperAdmin} isLoading={isLoading} />
            </Form.Item>
          )}
          {role === USER_TYPE.SubsidiaryAdmin && (
            <Form.Item name="subsidiaryId" label={i18n.t('common.subsidiary')}>
              <Select
                disabled={isLoading || isSubsidiaryAdmin}
                value={currentSubsidiary?.id}
                options={subsidiaryOptions}
                placeholder={i18n.t<string>('common.subsidiary')}
                onChange={(value) => {
                  form.setFieldValue('subsidiaryId', value);
                }}
              />
            </Form.Item>
          )}
        </>
      )}
      <Form.Item name="email" label={i18n.t('common.email')}>
        <Input
          placeholder={i18n.t<string>('common.emailAddress')}
          type="email"
          required
          disabled={isLoading}
        />
      </Form.Item>
      <BottomFlexDiv>
        <Button size="middle" disabled={isLoading} onClick={onClose}>
          {i18n.t('common.cancel')}
        </Button>
        <Button
          size="middle"
          type="primary"
          htmlType="submit"
          loading={isLoading}
          icon={<SaveOutlined />}
        >
          {i18n.t('common.save')}
        </Button>
      </BottomFlexDiv>
    </Form>
  );
});

AdminAddEditForm.displayName = 'AdminAddEditForm';

export default AdminAddEditForm;
