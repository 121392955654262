'use client';

import BellOutlined from '@ant-design/icons/BellOutlined';
import { Badge, Tooltip, ConfigProvider } from 'antd';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import Link from '~/components/Link';
import routes from '~/config/routes';
import useAlarmsContext from '~/context/useAlarmsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import { ALARM_LEVEL } from '~/types/alarm';
import getAlarmColorFromLevel from '~/utils/alarm/getAlarmColorFromLevel';
import getHighestAlarmLevel from '~/utils/alarm/getHighestAlarmLevel';

import { circleButtonStyles } from './NavbarContactsOnCall';

const StyledLink = styled(Link)`
  ${circleButtonStyles}
`;

const NavbarAlarmsLink = memo(() => {
  const { showAlarmsPageFeature } = useCompanyFeatures();
  const { ongoingAlarms } = useAlarmsContext();

  const alarmLevel = useMemo(() => getHighestAlarmLevel(ongoingAlarms), [ongoingAlarms]);

  if (!showAlarmsPageFeature) {
    return null;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorError: getAlarmColorFromLevel(alarmLevel || ALARM_LEVEL.Critical).text,
        },
      }}
    >
      <Tooltip title={i18n.t('common.alarms')}>
        <Badge
          count={ongoingAlarms.length}
          offset={[0, 0]}
          style={{ boxShadow: 'none' }}
          size="small"
        >
          <StyledLink to={routes.alarms} data-id="alarmsNavbarLink">
            <BellOutlined />
          </StyledLink>
        </Badge>
      </Tooltip>
    </ConfigProvider>
  );
});

NavbarAlarmsLink.displayName = 'NavbarAlarmsLink';

export default NavbarAlarmsLink;
