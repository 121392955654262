'use client';

import CloseOutlined from '@ant-design/icons/CloseOutlined';
import DownOutlined from '@ant-design/icons/DownOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import { useReactiveVar } from '@apollo/client';
import { Button, Select, Tree, Typography } from 'antd';
import type { DataNode } from 'antd/es/tree';
import uniqueId from 'lodash/uniqueId';
import { memo, useState } from 'react';
import styled from 'styled-components';

import { alertSoundMutedVar } from '~/apollo/reactiveVariables/alertSoundMutedVar';
import { currentCompanyIdentifierVar } from '~/apollo/reactiveVariables/currentCompanyIdentifierVar';
import { currentSubsidiaryIdentifierVar } from '~/apollo/reactiveVariables/currentSubsidiaryIdentifierVar';
import { newAlertVar } from '~/apollo/reactiveVariables/newAlertVar';
import { reconnectTimestampVar } from '~/apollo/reactiveVariables/reconnectTimestampVar';
import { selectedTeamsVar } from '~/apollo/reactiveVariables/selectedTeamsVar';
import useAgentsContext from '~/context/useAgentsContext';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useAgentsStatistics from '~/hooks/useAgentsStatistics';
import useCompany from '~/hooks/useCompany';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useConnectionStatus from '~/hooks/useConnectionStatus';
import useSubsidiary from '~/hooks/useSubsidiary';
import useTeams from '~/hooks/useTeams';
import theme from '~/theme';
import { formatTime } from '~/utils/dateTime';

const GUTTER = '12px';

const WrapperDiv = styled.div`
  position: fixed;
  bottom: ${GUTTER};
  left: ${GUTTER};
  height: 400px;
  width: 600px;
  max-width: calc(100vw - ${GUTTER} - ${GUTTER});
  background: ${theme.colors.white};
  z-index: ${theme.layers.developerConsole};
  border: 1px solid ${theme.colors.thinGrey};
  border-radius: 5px;
  overflow-y: scroll;
`;

const TopDiv = styled.div`
  position: sticky;
  top: 0;
  z-index: ${theme.layers.developerConsole + 1};
  background: ${theme.colors.white};
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 100px) minmax(0, 25px);
  grid-gap: 8px;
  padding: ${GUTTER} ${GUTTER} calc(${GUTTER} / 2);
`;

const BottomDiv = styled.div`
  padding: 0 ${GUTTER} ${GUTTER};
`;

function createTreeNodesRecursively(values: unknown, prevKey: string = uniqueId()): DataNode[] {
  return Object.entries(values || {}).map(([key, value]) => {
    switch (typeof value) {
      case 'function': {
        return {
          title: `${key}: function`,
          key: `${prevKey}-${key}-function`,
        };
      }
      case 'object': {
        const curatedTitle = Number.isFinite(parseInt(key, 10)) ? `item ${key}` : `${key}`;
        const curatedKey = `${prevKey}-${curatedTitle}-${value}`;
        return {
          title: Array.isArray(value) ? `${curatedTitle}: [${value.length}]` : curatedTitle,
          key: curatedKey,
          children: createTreeNodesRecursively(value, curatedKey),
        };
      }
      default: {
        return {
          title: `${key}: ${value}`,
          key: `${prevKey}-${key}-${value}`,
        };
      }
    }
  });
}

interface Props {
  onClose: () => void;
}

const DeveloperConsoleApp = memo(({ onClose }: Props) => {
  const reactiveVars = {
    currentCompanyIdentifierVar: useReactiveVar(currentCompanyIdentifierVar),
    currentSubsidiaryIdentifierVar: useReactiveVar(currentSubsidiaryIdentifierVar),
    selectedTeamsVar: useReactiveVar(selectedTeamsVar),
    reconnectTimestampVar: useReactiveVar(reconnectTimestampVar),
    newAlertVar: useReactiveVar(newAlertVar),
    alertSoundMutedVar: useReactiveVar(alertSoundMutedVar),
  };

  const values = {
    apolloStoreCache: window.getApolloStoreCache?.(),
    reactiveVars,
    useAuthenticationContext: useAuthenticationContext(),
    useCurrentUserContext: useCurrentUserContext(),
    useAgentsContext: useAgentsContext(),
    useAgentsStatistics: useAgentsStatistics(),
    useConnectionStatus: useConnectionStatus(),
    useCompany: useCompany(),
    useCompanyFeatures: useCompanyFeatures(),
    useSubsidiary: useSubsidiary(),
    useTeams: useTeams(),
  };

  const [selectedValue, setSelectedValue] = useState<keyof typeof values | null>(null);
  const [treeData, setTreeData] = useState<{
    timestamp: string;
    nodes: DataNode[];
  }>({
    timestamp: new Date().toISOString(),
    nodes: [],
  });

  const handleChangeValue = (key: keyof typeof values) => {
    setTreeData({
      timestamp: new Date().toISOString(),
      nodes: createTreeNodesRecursively(values[key]),
    });
  };

  return (
    <WrapperDiv>
      <TopDiv>
        <Select<keyof typeof values>
          showSearch
          placeholder="Select a node"
          options={Object.keys(values).map((key) => ({ value: key, label: key }))}
          value={selectedValue}
          onChange={(key) => {
            setSelectedValue(key);
            handleChangeValue(key);
          }}
        />
        <Button
          icon={<ReloadOutlined />}
          disabled={!selectedValue}
          onClick={() => {
            if (selectedValue) {
              handleChangeValue(selectedValue);
            }
          }}
        >
          Refesh
        </Button>
        <Button type="dashed" icon={<CloseOutlined />} onClick={onClose} />
      </TopDiv>
      {treeData.nodes.length > 0 && (
        <BottomDiv>
          <Tree showLine switcherIcon={<DownOutlined />} treeData={treeData.nodes} />
          <Typography.Text>
            <b>Timestamp:</b> {formatTime(treeData.timestamp)}
          </Typography.Text>
        </BottomDiv>
      )}
    </WrapperDiv>
  );
});

DeveloperConsoleApp.displayName = 'DeveloperConsoleApp';

export default DeveloperConsoleApp;
