'use client';

import { gql, useQuery } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useMemo, useState } from 'react';

interface QueryAllBrainsInput {
  nextToken: string | null;
  limit: number;
}

export interface QueryAllBrainsOutput {
  getAllDevices: {
    __typename: 'DeviceConnection';
    nextToken: string | null;
    items: {
      __typename: 'Device';
      name: string;
      carrier: null | {
        __typename: 'Carrier_NonCognito' | 'Carrier_Cognito';
        id: string;
        name: string;
        email: string | null;
        requested_association: string | null; // Device name
        subsidiary: {
          __typename: 'Subsidiary';
          id: string;
          name: string;
          company: {
            __typename: 'Company';
            id: string;
            name: string;
          };
        };
      };
    }[];
  };
}

export default function useQueryAllBrains() {
  const limit = 1000;

  const [allBrains, setAllBrains] = useState<QueryAllBrainsOutput['getAllDevices']['items']>([]);

  const { data, loading, error, refetch } = useQuery<QueryAllBrainsOutput, QueryAllBrainsInput>(
    gql`
      query QueryAllBrains($limit: Int, $nextToken: String) {
        getAllDevices(limit: $limit, nextToken: $nextToken) {
          nextToken
          items {
            name
            carrier {
              ... on Carrier_NonCognito {
                id
                name
              }
              ... on Carrier_Cognito {
                id
                name
                email
              }
              requested_association
              subsidiary {
                id
                name
                company {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        limit,
        nextToken: null,
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    if (data?.getAllDevices?.nextToken) {
      (async () => {
        await refetch({
          limit,
          nextToken: data.getAllDevices.nextToken,
        });
      })();
    }
  }, [data?.getAllDevices?.nextToken, refetch]);

  useEffect(() => {
    if (data?.getAllDevices?.items) {
      setAllBrains((prev) =>
        orderBy(uniqBy([...prev, ...data.getAllDevices.items], 'name'), ['name'], ['asc']),
      );
    }
  }, [data?.getAllDevices?.items]);

  return useMemo(
    () => ({
      allBrains,
      isLoading: loading,
      error,
      refetchAllBrains: async () => {
        setAllBrains([]);
        await refetch({ nextToken: null });
      },
    }),
    [allBrains, loading, error, refetch],
  );
}
