'use client';

import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { Button, Form, Input, Modal, Spin } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import useMutationCreateCompany from '~/apollo/hooks/company/useMutationCreateCompany';
import useMutationUpdateCompany from '~/apollo/hooks/company/useMutationUpdateCompany';
import useQueryCompanyListWithDetails from '~/apollo/hooks/company/useQueryCompanyListWithDetails';
import i18n from '~/locales/i18n';
import type { ModalProps } from '~/types/modal';
import notification from '~/utils/notification';

const BottomActionsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BottomDiv = styled.div`
  margin-top: 16px;
  opacity: 0.5;
`;

interface FormValues {
  name: string;
}

export interface CompanyAddEditModalProps {
  companyId: string | undefined;
  refetchOnCompleted: () => Promise<void>;
}

const CompanyAddEditModal = memo(
  ({ isOpen, onClose, companyId, refetchOnCompleted }: ModalProps & CompanyAddEditModalProps) => {
    const { createCompany, isLoading: isCreating } = useMutationCreateCompany();
    const { updateCompany, isLoading: isUpdating } = useMutationUpdateCompany();
    const { companyListWithDetails, isLoading: isCompanyListWithDetailsLoading } =
      useQueryCompanyListWithDetails();

    const isLoading = isCreating || isUpdating || isCompanyListWithDetailsLoading;

    const [form] = Form.useForm<FormValues>();

    const isEdit = !!companyId;

    const maybeCompany = companyListWithDetails.find((company) => company.id === companyId);

    const handleSubmit = async ({ name }: FormValues) => {
      try {
        if (isEdit) {
          await updateCompany({
            variables: {
              company_id: companyId,
              name: name.trim(),
            },
          });
        } else {
          await createCompany({
            variables: {
              name: name.trim(),
            },
          });
        }
        onClose();
        notification.success({
          message: i18n.t('common.success'),
          description: isEdit
            ? i18n.t('backofficeCompaniesPage.companyUpdatedSuccess')
            : i18n.t('backofficeCompaniesPage.companyCreatedSuccess'),
        });
        await refetchOnCompleted();
      } catch (error) {
        notification.error({
          message: (error as any)?.message || i18n.t('common.error'),
        });
      }
    };

    return (
      <Modal
        title={
          isEdit
            ? i18n.t('backofficeCompaniesPage.editCompany')
            : i18n.t('backofficeCompaniesPage.createCompany')
        }
        footer={null}
        centered
        width={500}
        open={isOpen}
        onCancel={onClose}
      >
        {isCompanyListWithDetailsLoading ? (
          <Spin style={{ marginTop: '0.5rem' }} />
        ) : (
          <>
            <Form
              form={form}
              layout="vertical"
              validateTrigger="onBlur"
              initialValues={undefined}
              autoComplete="off"
              onFinish={handleSubmit}
            >
              <div style={{ height: '8px' }} />
              <Form.Item name="name" label={i18n.t('backofficeCompaniesPage.companyName')}>
                <Input
                  placeholder={i18n.t<string>('backofficeCompaniesPage.nameOfTheCompany')}
                  disabled={isLoading}
                  defaultValue={maybeCompany?.name}
                  required
                />
              </Form.Item>
              <BottomActionsDiv>
                <Button size="middle" disabled={isLoading} onClick={onClose}>
                  {i18n.t('common.cancel')}
                </Button>
                <Button
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  icon={<SaveOutlined />}
                >
                  {isEdit ? i18n.t('common.save') : i18n.t('common.create')}
                </Button>
              </BottomActionsDiv>
            </Form>
            {isEdit && (
              <BottomDiv>
                <b>{i18n.t('backofficeCompaniesPage.companyId')}:</b> {companyId}
              </BottomDiv>
            )}
          </>
        )}
      </Modal>
    );
  },
);

CompanyAddEditModal.displayName = 'CompanyAddEditModal';

export default CompanyAddEditModal;
