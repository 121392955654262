'use client';

import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

export interface CompanySubsidiaryListWithDetailsQueryVariables {
  companyId: string;
  nextToken: string | null;
}

export interface CompanySubsidiaryListWithDetailsQueryData {
  company: {
    __typename: 'Company';
    id: string;
    subsidiaries: {
      __typename: 'SubsidiaryConnection';
      nextToken: string | null;
      items: {
        __typename: 'Subsidiary';
        id: string;
        name: string;
        created_at: string;
        location: {
          lat: number;
          lng: number;
        };
        admins: {
          __typename: 'AdministratorConnection';
          items: {
            email: string;
          }[];
        };
        carriers: {
          __typename: 'CarrierConnection';
          items: {
            id: string;
            name: string;
            email: string;
          }[];
        };
      }[];
    };
  };
}

export default function useQueryCompanySubsidiaryListWithDetails({
  companyId,
}: {
  companyId: string;
}) {
  const { data, loading, error, refetch } = useQuery<
    CompanySubsidiaryListWithDetailsQueryData,
    CompanySubsidiaryListWithDetailsQueryVariables
  >(
    gql`
      query QueryCompanySubsidiaryListWithDetails($companyId: ID!, $nextToken: String) {
        company(id: $companyId) {
          id
          subsidiaries(limit: 1000, nextToken: $nextToken) {
            nextToken
            items {
              id
              name
              created_at
              location {
                lat
                lng
              }
              admins(limit: 1000) {
                items {
                  email
                }
              }
              carriers(limit: 1000) {
                items {
                  id
                  name
                  ... on Carrier_Cognito {
                    email
                  }
                  ... on Carrier_NonCognito {
                    name
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        companyId,
        nextToken: null,
      },
      skip: !companyId,
    },
  );

  return useMemo(
    () => ({
      subsidiaryListWithDetails: data?.company?.subsidiaries?.items || [],
      isLoading: loading,
      error,
      refetchSubsidiaryListWithDetails: refetch,
    }),
    [data?.company?.subsidiaries?.items, loading, error, refetch],
  );
}
