'use client';

import { memo, type ReactNode, type CSSProperties } from 'react';
import { Link as ReactRouterDomLink, type LinkProps } from 'react-router-dom';
import styled from 'styled-components';

import theme from '~/theme';

const StyledLink = styled(ReactRouterDomLink)`
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

interface Props {
  children: ReactNode;
  to: LinkProps['to'];
  onClick?: () => void;
  target?: string;
  title?: string;
  className?: string;
  style?: CSSProperties;
  'data-id'?: string;
}

const Link = memo(
  ({
    children,
    to,
    onClick = () => undefined,
    target = '_self',
    title,
    className,
    style,
    'data-id': dataId,
  }: Props) => (
    <StyledLink
      to={to}
      onClick={onClick}
      target={target}
      rel={target === '_blank' ? 'nofollow noopener noreferrer' : ''}
      title={title}
      className={className}
      style={style}
      data-id={dataId}
    >
      {children}
    </StyledLink>
  ),
);

Link.displayName = 'Link';

export default Link;

export const BlackLink = styled(Link)<{ $forced?: boolean }>`
  color: ${theme.colors.black};

  &:hover {
    color: ${(props) => (props.$forced ? theme.colors.black : undefined)};
  }
`;

export const UnderlineLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;
