'use client';

import { useMemo } from 'react';

import useAgentsContext from '~/context/useAgentsContext';
import useAlarmsContext from '~/context/useAlarmsContext';

export default function useInitialLoading(): { isInitialLoading: boolean } {
  const { isInitialLoading: isAlarmsLoading } = useAlarmsContext();
  const { isInitialLoading: isAgentsLoading } = useAgentsContext();

  return useMemo(
    () => ({
      isInitialLoading: isAlarmsLoading || isAgentsLoading,
    }),
    [isAgentsLoading, isAlarmsLoading],
  );
}
