'use client';

import { useQuery, useReactiveVar } from '@apollo/client';
import { useMemo, useState } from 'react';
import useInterval from 'react-use/lib/useInterval';

import QUERY_ME, { type MeQueryData } from '~/apollo/operations/queries/QueryMe';
import { reconnectTimestampVar } from '~/apollo/reactiveVariables/reconnectTimestampVar';
import logger from '~/utils/logger';

const ONLINE_STATUS_CHECK_PERIOD_MS = 2000;
const APPSYNC_STATUS_CHECK_PERIOD_MS = 10000;

export default function useConnectionStatus(): {
  navigatorOnline: boolean;
  lastOnlineTimestamp: number;
  lastCheckTimestamp: number;
  appsyncReconnectedTimestamp: number;
} {
  const { error, refetch } = useQuery<MeQueryData>(QUERY_ME, { fetchPolicy: 'network-only' });
  const reconnectTimestampValue = useReactiveVar(reconnectTimestampVar);
  const [navigatorOnline, setNavigatorOnline] = useState<boolean>(true);
  const [lastCheckTimestamp, setLastCheckTimestamp] = useState<number>(0);
  const [lastOnlineTimestamp, setLastOnlineTimestamp] = useState<number>(0);
  const [appsyncReconnectedTimestamp, setAppsyncReconnectedTimestamp] = useState<number>(0);
  const [appsyncOnline, setAppsyncOnline] = useState<boolean>(true);

  useInterval(() => {
    // A simple check for online / offline status.
    // It does not provice guarantee that the server is reachable.
    // It doesn't even guarantee that connection to internet works.
    // It only says there is some network connectivity if onLine is true.
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
    const online = window.navigator.onLine;
    const now = Date.now();
    if (online) {
      setLastOnlineTimestamp(now);
    }
    setLastCheckTimestamp(now);
    setNavigatorOnline(online);
  }, ONLINE_STATUS_CHECK_PERIOD_MS);

  useInterval(() => {
    logger.log('useConnectionStatus: testing appsync connection', {
      timestamp: new Date().toISOString(),
    });
    refetch();
    if (error) {
      logger.log('useConnectionStatus: connection error');
      setAppsyncOnline(false);
    } else if (!appsyncOnline) {
      logger.log('useConnectionStatus: reconnected');
      setAppsyncOnline(true);
      const now = Date.now();
      setAppsyncReconnectedTimestamp(now);
      if (now !== reconnectTimestampValue) {
        reconnectTimestampVar(now);
      }
    }
  }, APPSYNC_STATUS_CHECK_PERIOD_MS);

  return useMemo(
    () => ({
      navigatorOnline,
      lastCheckTimestamp,
      lastOnlineTimestamp,
      appsyncReconnectedTimestamp,
    }),
    [appsyncReconnectedTimestamp, lastCheckTimestamp, lastOnlineTimestamp, navigatorOnline],
  );
}
