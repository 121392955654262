'use client';

import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { Button, Spin } from 'antd';
import { memo, type CSSProperties, type ReactNode } from 'react';
import styled from 'styled-components';

import theme from '~/theme';

const StyledButton = styled(Button)`
  background-color: ${theme.colors.primaryBlue} !important;
  border-radius: 6px;
  height: 48px;
  width: 100%;

  &:hover {
    background-color: ${theme.colors.darkBlue} !important;
  }
`;

interface Props {
  children: ReactNode;
  loading?: boolean;
  className?: string;
  style?: CSSProperties;
  'data-id'?: string;
}

const AuthFormButton = memo(({ children, loading, className, style, 'data-id': dataId }: Props) => (
  <StyledButton
    type="primary"
    htmlType="submit"
    size="large"
    disabled={loading}
    className={className}
    style={style}
    data-id={dataId}
  >
    {loading ? (
      <Spin
        indicator={<LoadingOutlined style={{ color: theme.colors.white, fontSize: 18 }} spin />}
      />
    ) : (
      children
    )}
  </StyledButton>
));

AuthFormButton.displayName = 'AuthFormButton';

export default AuthFormButton;
