'use client';

import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { Button, DatePicker, Descriptions, Modal, Select, Spin, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { memo, useState, useEffect, useMemo, useRef, type ReactNode } from 'react';
import { RiFolderDownloadFill } from 'react-icons/ri';
import styled from 'styled-components';

import useQueryCarrierVideoRecordings from '~/apollo/hooks/videoRecording/useQueryCarrierVideoRecordings';
import useQueryVideoRecording from '~/apollo/hooks/videoRecording/useQueryVideoRecording';
import TimeAgo from '~/components/TimeAgo';
import VideoPlayer from '~/components/VideoPlayer';
import { SUPPORT_EMAIL } from '~/config/constants';
import useAgentsContext from '~/context/useAgentsContext';
import i18n, { currentLanguage } from '~/locales/i18n';
import theme from '~/theme';
import type { ModalProps } from '~/types/modal';
import getAgentNameWithAcronym from '~/utils/agent/getAgentNameWithAcronym';
import getAgentsOptions from '~/utils/agent/getAgentsOptions';
import { formatDate, formatDateTime, formatTime } from '~/utils/dateTime';
import logger from '~/utils/logger';

const TOP_HEIGHT = '33px';
const BOTTOM_HEIGHT = '600px';

const BORDER = '1px solid #e8e8e8';

const StyledModal = styled(Modal)`
  max-width: 100%;

  & div.ant-modal-content {
    padding: 0;
    overflow: hidden;
  }
`;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  ${theme.medias.extraSmall} {
    gap: 6px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const AgentSelect = styled(Select)`
  width: 240px;
  max-width: 100%;

  ${theme.medias.extraSmall} {
    width: 100%;
  }
`;

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 360px) minmax(0, 1fr);

  ${theme.medias.small} {
    grid-template-columns: minmax(0, 240px) minmax(0, 1fr);
  }

  ${theme.medias.extraSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const LeftDiv = styled.div`
  border-right: ${BORDER};

  ${theme.medias.extraSmall} {
    order: 2;
  }
`;

const TopDiv = styled.div`
  height: ${TOP_HEIGHT};
  border-bottom: ${BORDER};

  ${theme.medias.extraSmall} {
    order: 1;
  }
`;

const Ul = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: ${BOTTOM_HEIGHT};
  overflow-x: hidden;
  overflow-y: auto;
`;

const Li = styled.li`
  & > span {
    display: inline-block;
    width: 100%;
  }
`;

const ItemTopDiv = styled.div`
  padding: 6px 12px;
  border-bottom: ${BORDER};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ItemButton = styled.button<{ $active: boolean }>`
  cursor: pointer;
  background: ${(props) => (props.$active ? theme.colors.primaryBlue : 'transparent')};
  color: ${(props) => (props.$active ? theme.colors.white : theme.colors.black)};
  padding: 12px;
  border: none;
  border-bottom: ${BORDER};
  font-size: 16px;
  text-align: left;
  width: 100%;

  &:hover {
    background: ${(props) => (props.$active ? theme.colors.primaryBlue : theme.colors.lightGrey)};
  }
`;

const ContentDiv = styled.div`
  padding: 0 12px;
  max-height: 100%;

  ${theme.medias.extraSmall} {
    margin-bottom: 8px;
  }
`;

const DateTimeDiv = styled.div`
  margin-top: 8px;
  opacity: 0.65;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  ${theme.medias.lteSmall} {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

const TitleH2 = styled.h2`
  margin: 8px 0;
`;

const VideoPlayerDiv = styled.div`
  margin-bottom: 12px;
  height: 360px;
  background: black;
  border-radius: 8px;
  overflow: hidden;

  ${theme.medias.extraSmall} {
    height: 240px;
  }
`;

const HelpDiv = styled.div`
  padding-top: 14px;
  padding-left: 6px;
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 12px;
`;

const BottomDiv = styled.div`
  margin: 12px 0 0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
`;

export interface VideoRecordingsModalProps {
  defaultAgentId: string | undefined;
  initialDateRange: [string, string]; // Example: ['2023-07-04 14:27:55', '2023-07-28 14:28:00'];
}

const VideoRecordingsModal = memo(
  ({
    isOpen,
    onClose,
    defaultAgentId,
    initialDateRange,
  }: ModalProps & VideoRecordingsModalProps) => {
    const { agents } = useAgentsContext();

    const [agentId, setAgentId] = useState<string | undefined>(defaultAgentId);

    const agent = useMemo(() => agents.find((a) => a.id === agentId), [agentId, agents]);

    const nextTokenRef = useRef<string | null>(null);

    const [currentVideoId, setCurrentVideoId] = useState<string>();

    const [activeDateRange, setActiveDateRange] = useState<[string, string]>(initialDateRange);

    const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);

    const {
      videoRecordings,
      refetchVideoRecordings,
      fetchNextPage,
      isLoading: isVideoRecordingsLoading,
      videoRecordingsNextToken,
    } = useQueryCarrierVideoRecordings({
      agentId: agentId || '',
      startAfter: activeDateRange[0] ? new Date(activeDateRange[0]).toISOString() : null,
      startBefore: activeDateRange[1] ? new Date(activeDateRange[1]).toISOString() : null,
    });

    const { videoRecording, isLoading: isVideoRecordingLoading } = useQueryVideoRecording({
      videoRecordingId: currentVideoId,
    });

    const searchAgentOptions = useMemo(
      () =>
        getAgentsOptions(agents).map((a) => (a.value !== agent?.id ? a : { ...a, disabled: true })),
      [agent?.id, agents],
    );

    useEffect(() => {
      if (agent?.id) {
        setCurrentVideoId(undefined);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agent?.id]);

    useEffect(() => {
      setIsNextPageLoading(false);
    }, [videoRecordingsNextToken]);

    useEffect(() => {
      setCurrentVideoId(undefined);
      refetchVideoRecordings();
      nextTokenRef.current = null;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(activeDateRange)]);

    useEffect(() => {
      if (videoRecordings?.[0]?.id && videoRecordings?.[0]?.view_url) {
        if (!currentVideoId) {
          setCurrentVideoId(videoRecordings[0].id);
        }
        nextTokenRef.current = videoRecordingsNextToken;
      }
    }, [currentVideoId, videoRecordings, videoRecordingsNextToken]);

    const contentDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      contentDivRef.current?.scrollTo(0, 0);

      if (isOpen && videoRecording) {
        logger.log('VideoRecordingsModal: opened video', { videoRecording });
      }
    }, [isOpen, videoRecording]);

    const recordingDetails: {
      label: string;
      value: ReactNode;
    }[] = [
      {
        label: i18n.t('carrierDetailsPopup.videoRecordings.details.agent'),
        value: <b>{getAgentNameWithAcronym(agent)}</b>,
      },
      {
        label: i18n.t('carrierDetailsPopup.videoRecordings.details.team'),
        value: agent?.team || 'n/a',
      },
      {
        label: i18n.t('carrierDetailsPopup.videoRecordings.details.device'),
        value: agent?.deviceName || 'n/a',
      },
      {
        label: i18n.t('carrierDetailsPopup.videoRecordings.details.date'),
        value: videoRecording?.start_recording
          ? formatDateTime(videoRecording.start_recording, 'standard')
          : null,
      },
      {
        label: i18n.t('carrierDetailsPopup.videoRecordings.details.duration'),
        value: new Date(videoRecording?.duration_ms ?? 0).toISOString().substring(11, 19),
      },
    ];

    const isLoading = isVideoRecordingsLoading || isNextPageLoading;

    const hasLoadMore = nextTokenRef.current && !isLoading && videoRecordings.length > 0;
    const hasLoading =
      isVideoRecordingLoading || (isVideoRecordingsLoading && videoRecordings.length === 0);
    const hasVideo =
      !isVideoRecordingLoading && currentVideoId && videoRecording && videoRecordings.length > 0;

    const emailSubject = encodeURIComponent(
      i18n.t<string>('carrierDetailsPopup.videoRecordings.emailSubject'),
    );
    const emailBody = encodeURIComponent(
      i18n.t<string>('carrierDetailsPopup.videoRecordings.emailBody', {
        videoId: videoRecording?.id,
      }),
    );

    return (
      <StyledModal
        title={
          <TitleDiv>
            <span>{i18n.t('carrierDetailsPopup.videoRecordings.modalTitle')}</span>
            <AgentSelect
              showSearch
              placeholder="Select a person"
              optionFilterProp="children"
              onChange={(value) => {
                setAgentId(value as string);
              }}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              value={agent?.id}
              options={searchAgentOptions}
            />
          </TitleDiv>
        }
        footer={null}
        centered
        width={1200}
        open={isOpen}
        onCancel={onClose}
        styles={{
          header: {
            padding: '16px',
            margin: 0,
            borderBottom: BORDER,
          },
        }}
      >
        <GridDiv>
          <LeftDiv>
            <TopDiv>
              <DatePicker.RangePicker
                value={
                  activeDateRange[0] && activeDateRange[1]
                    ? [
                        dayjs(activeDateRange[0], 'YYYY-MM-DD HH:mm:ss'),
                        dayjs(activeDateRange[1], 'YYYY-MM-DD HH:mm:ss'),
                      ]
                    : undefined
                }
                showTime
                size="middle"
                bordered={false}
                onCalendarChange={(_, dateRange) => {
                  if (dateRange[0] && dateRange[1]) {
                    setActiveDateRange(dateRange);
                  }
                  if (!dateRange[0] && !dateRange[1]) {
                    setActiveDateRange(['', '']);
                  }
                }}
                allowClear
                format={(value) => formatDateTime(value.toISOString(), 'standard')}
                renderExtraFooter={
                  activeDateRange[0] && activeDateRange[1]
                    ? () => (
                        <Button
                          type="primary"
                          size="small"
                          icon={<CloseOutlined />}
                          onClick={() => {
                            setActiveDateRange(['', '']);
                          }}
                        >
                          {i18n.t('common.clear')}
                        </Button>
                      )
                    : undefined
                }
              />
            </TopDiv>
            <ItemTopDiv>
              {isLoading
                ? i18n.t('common.loading')
                : i18n.t('carrierDetailsPopup.videoRecordings.foundCountVideos', {
                    count: videoRecordings.length,
                    countValue: `${videoRecordings.length}${hasLoadMore ? '+' : ''}`,
                  })}
            </ItemTopDiv>
            <Ul data-id="video-recordings-list">
              {videoRecordings.map((video) => (
                <Li key={video.id}>
                  <ItemButton
                    $active={currentVideoId === video.id}
                    onClick={() => {
                      setCurrentVideoId(video.id);
                    }}
                  >
                    <TimeAgo date={video.start_recording} />
                    <DateTimeDiv>
                      <div>
                        {formatDate(video.start_recording, 'readable')}{' '}
                        {formatTime(video.start_recording, 'standard')}
                      </div>
                      <div>{new Date(video.duration_ms ?? 0).toISOString().substring(11, 19)}</div>
                    </DateTimeDiv>
                  </ItemButton>
                </Li>
              ))}
              {hasLoadMore && (
                <Li>
                  <ItemButton
                    $active={false}
                    onClick={() => {
                      fetchNextPage(nextTokenRef.current);
                      setIsNextPageLoading(true);
                      // refetchVideoRecordings();
                    }}
                  >
                    <RiFolderDownloadFill
                      style={{ marginRight: '8px', transform: 'translateY(2px)' }}
                    />
                    <b>{i18n.t('carrierDetailsPopup.videoRecordings.loadMoreVideos')}</b>
                  </ItemButton>
                </Li>
              )}
            </Ul>
          </LeftDiv>
          <ContentDiv ref={contentDivRef}>
            {hasLoading && (
              <HelpDiv>
                <Spin />
                <span>{i18n.t('common.loading')}</span>
              </HelpDiv>
            )}
            {!hasLoading && !hasVideo && (
              <HelpDiv>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: i18n.t<string>(
                      'carrierDetailsPopup.videoRecordings.noVideosFoundForAgentHtml',
                      {
                        agentName: getAgentNameWithAcronym(agent),
                      },
                    ),
                  }}
                />
              </HelpDiv>
            )}
            {hasVideo && (
              <>
                <TitleH2 data-id="highlighted-feature-title">
                  {new Date(videoRecording.start_recording).toLocaleString(currentLanguage(), {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                  })}
                </TitleH2>
                <VideoPlayerDiv>
                  <VideoPlayer
                    key={videoRecording.view_url}
                    url={videoRecording.view_url}
                    width="100%"
                    height="100%"
                  />
                </VideoPlayerDiv>
                <Descriptions
                  bordered
                  column={1}
                  size="small"
                  labelStyle={{ width: '110px', fontWeight: 'bold' }}
                >
                  {recordingDetails.map(({ label, value }) => (
                    <Descriptions.Item key={label} label={label}>
                      {value}
                    </Descriptions.Item>
                  ))}
                </Descriptions>
                <BottomDiv>
                  <Tooltip
                    title={i18n.t('carrierDetailsPopup.videoRecordings.reportTooltip')}
                    placement="bottomLeft"
                  >
                    <a href={`mailto:${SUPPORT_EMAIL}?subject=${emailSubject}&body=${emailBody}`}>
                      <Button size="small" type="primary" danger>
                        {i18n.t('carrierDetailsPopup.videoRecordings.report')}
                      </Button>
                    </a>
                  </Tooltip>
                  <span style={{ opacity: 0.5, lineHeight: 1 }}>
                    <b>{i18n.t('carrierDetailsPopup.videoRecordings.videoId')}:</b>{' '}
                    {videoRecording?.id}
                  </span>
                </BottomDiv>
              </>
            )}
          </ContentDiv>
        </GridDiv>
      </StyledModal>
    );
  },
);

VideoRecordingsModal.displayName = 'VideoRecordingsModal';

export default VideoRecordingsModal;
