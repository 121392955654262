'use client';

import { Layout } from 'antd';
import styled from 'styled-components';

import theme from '~/theme';

const NavbarHeader = styled(Layout.Header)`
  height: ${theme.dimensions.navbarHeight}px;
  background: ${theme.colors.darkBlue};
  padding: 0 16px 0 16px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: ${theme.layers.navBar};
  user-select: none;
`;

NavbarHeader.displayName = 'NavbarHeader';

export default NavbarHeader;
