'use client';

import { useQuery, type ApolloError } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import QUERY_TOKENS, {
  type CompanyM2MTokensQueryData,
  type CompanyM2MTokensQueryVariables,
} from '~/apollo/operations/queries/QueryCompanyM2MTokens';
import i18n from '~/locales/i18n';
import type { M2MToken } from '~/types/m2mToken';
import logger from '~/utils/logger';
import notification from '~/utils/notification';

export default function useQueryCompanyM2MTokens(
  companyId?: string,
  skip?: boolean,
): {
  tokens: M2MToken[];
  loading: boolean;
  error: ApolloError | undefined;
} {
  const { fetchMore, data, loading, error } = useQuery<
    CompanyM2MTokensQueryData,
    CompanyM2MTokensQueryVariables
  >(QUERY_TOKENS, {
    variables: { companyId: companyId || '' },
    skip: skip || !companyId,
  });

  useEffect(() => {
    if (skip || loading || !companyId || !data?.company?.m2m_tokens?.nextToken) {
      return;
    }

    fetchMore({
      variables: { nextToken: data.company.m2m_tokens.nextToken },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        logger.log('useQueryCompanyM2MTokens: fetchMore m2m tokens', { companyId });
        return {
          ...previousResult,
          company: {
            ...previousResult.company,
            m2m_tokens: {
              ...previousResult.company?.m2m_tokens,
              items: [
                ...(previousResult.company?.m2m_tokens?.items || []),
                ...(fetchMoreResult.company?.m2m_tokens?.items || []),
              ],
              nextToken: fetchMoreResult.company?.m2m_tokens?.nextToken,
            },
          },
        };
      },
    }).catch(() => {
      notification.error({
        message: i18n.t('general.notifications.fetchDataErrorTitle'),
        description: i18n.t('general.notifications.fetchDataErrorDescription'),
      });
    });
  }, [fetchMore, skip, loading, companyId, data?.company?.m2m_tokens?.nextToken]);

  return useMemo(
    () => ({
      tokens: data?.company?.m2m_tokens?.items || [],
      loading,
      error,
    }),
    [data?.company?.m2m_tokens?.items, loading, error],
  );
}
