'use client';

import { memo } from 'react';
import styled from 'styled-components';

import useAgentLocation from '~/hooks/useAgentLocation';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import type { Agent } from '~/types/agent';
import type { Vehicle } from '~/types/vehicle';

import InfoLocation from './components/InfoLocation';
import InfoMissionDuration from './components/InfoMissionDuration';
import InfoPhoneNumber from './components/InfoPhoneNumber';
import InfoSpeed from './components/InfoSpeed';

const Div = styled.div`
  display: grid;
  gap: 8px;
  grid-template-rows: auto;
`;

interface Props {
  agent: Agent | undefined;
  vehicle: Vehicle | undefined;
}

const SectionInfo = memo(({ agent, vehicle }: Props) => {
  const { showRealtimeLocationFeature } = useCompanyFeatures();
  const { address, gps } = useAgentLocation(vehicle?.gps?.value || agent?.sensors?.gps?.value);

  const showLocationInfo = showRealtimeLocationFeature(agent);

  return (
    <Div data-id="SectionInfo">
      {showLocationInfo && <InfoLocation gps={gps} address={address} />}
      <InfoSpeed gps={gps} />
      <InfoMissionDuration agent={agent} />
      <InfoPhoneNumber agent={agent} />
    </Div>
  );
});

SectionInfo.displayName = 'SectionInfo';

export default SectionInfo;
