'use client';

import ApiOutlined from '@ant-design/icons/ApiOutlined';
import BellOutlined from '@ant-design/icons/BellOutlined';
import LaptopOutlined from '@ant-design/icons/LaptopOutlined';
import MobileOutlined from '@ant-design/icons/MobileOutlined';
import ProductOutlined from '@ant-design/icons/ProductOutlined';
import ShopOutlined from '@ant-design/icons/ShopOutlined';
import { memo, useCallback, useEffect, useState, type ReactNode } from 'react';
import { GrUserAdmin, GrUserNew, GrUserSettings } from 'react-icons/gr';
import { TbBuildingSkyscraper } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

import Container from '~/components/Container';
import PageContentWrapper from '~/components/PageContentWrapper';
import PageHeader from '~/components/PageHeader';
import PageSideTabs from '~/components/PageSideTabs';
import {
  SettingsGridDiv,
  SettingsStickyDiv,
  SettingsTabContentDiv,
  SettingsTabTitleH3,
} from '~/components/settings/shared';
import routes, { type BackofficeTab } from '~/config/routes';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useQueryParams from '~/hooks/useQueryParams';
import i18n from '~/locales/i18n';
import TabBrainProfiles from '~/pages//CompanySettingsPage/components/TabBrainProfiles';
import TabAdmins from '~/pages/CompanySettingsPage/components/TabAdmins';
import TabAgents from '~/pages/CompanySettingsPage/components/TabAgents';
import TabSubsidiaries from '~/pages/CompanySettingsPage/components/TabSubsidiaries';

import TabAlarmConfigurations from './components/TabAlarmConfigurations';
import TabBrains from './components/TabBrains';
import TabCompanies from './components/TabCompanies';
import TabDashboardFeatures from './components/TabDashboardFeatures';
import TabMobileAppFeatures from './components/TabMobileAppFeatures';
import TabSuperAdmins from './components/TabSuperAdmins';

const backofficeTabs: {
  key: BackofficeTab;
  icon: ReactNode;
}[] = [
  { key: 'companies', icon: <ShopOutlined /> },
  { key: 'subsidiaries', icon: <TbBuildingSkyscraper /> },
  { key: 'admins', icon: <GrUserSettings /> },
  { key: 'agents', icon: <GrUserNew /> },
  { key: 'brains', icon: <ApiOutlined /> },
  { key: 'brain-profiles', icon: <ProductOutlined /> },
  { key: 'dashboard-features', icon: <LaptopOutlined /> },
  { key: 'mobile-app-features', icon: <MobileOutlined /> },
  { key: 'super-admins', icon: <GrUserAdmin /> },
  { key: 'alarm-configurations', icon: <BellOutlined /> },
];

const BackofficePage = memo(() => {
  const { isSuperAdmin } = useCurrentUserContext();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const [tab, setTab] = useState<BackofficeTab>((queryParams.tab as BackofficeTab) || 'companies');

  useEffect(() => {
    if (queryParams.tab) {
      setTab(queryParams.tab as BackofficeTab);
    }
  }, [queryParams.tab]);

  const handleTabSelect = useCallback(
    (selectedTab: BackofficeTab) => {
      setTab(selectedTab);
      navigate(routes.backoffice({ ...queryParams, tab: selectedTab }));
    },
    [navigate, queryParams],
  );

  if (!isSuperAdmin) {
    return null;
  }

  return (
    <PageContentWrapper>
      <Container>
        <SettingsGridDiv>
          <aside>
            <PageHeader
              title={i18n.t('backofficePage.title')}
              subtitle={i18n.t('backofficePage.subtitle')}
            />
            <SettingsStickyDiv>
              <PageSideTabs
                activeItemKey={tab}
                items={backofficeTabs.map(({ key, icon }) => ({
                  key,
                  dataId: `backoffice-${key}-link`,
                  icon,
                  label: i18n.t(`backofficePage.tabs.${key}`),
                  onClick: () => handleTabSelect(key),
                }))}
              />
            </SettingsStickyDiv>
          </aside>
          <SettingsTabContentDiv>
            <SettingsTabTitleH3 data-id="backoffice-page-tab-title">
              {i18n.t(`backofficePage.tabs.${tab}`)}
            </SettingsTabTitleH3>
            {tab === 'companies' && <TabCompanies />}
            {tab === 'subsidiaries' && <TabSubsidiaries isBackofficePage />}
            {tab === 'admins' && <TabAdmins isBackofficePage />}
            {tab === 'brains' && <TabBrains />}
            {tab === 'brain-profiles' && <TabBrainProfiles isBackofficePage />}
            {tab === 'agents' && <TabAgents isBackofficePage />}
            {tab === 'dashboard-features' && <TabDashboardFeatures />}
            {tab === 'mobile-app-features' && <TabMobileAppFeatures />}
            {tab === 'super-admins' && <TabSuperAdmins />}
            {tab === 'alarm-configurations' && <TabAlarmConfigurations />}
          </SettingsTabContentDiv>
        </SettingsGridDiv>
      </Container>
    </PageContentWrapper>
  );
});

BackofficePage.displayName = 'BackofficePage';

export default BackofficePage;
