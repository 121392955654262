'use client';

import { gql, useMutation, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

interface DeleteAdminInput {
  adminId: string;
}

export default function useMutationDeleteAdmin(): {
  deleteAdmin: (params: { variables: DeleteAdminInput }) => Promise<unknown>;
  isLoading: boolean;
  error?: ApolloError;
} {
  const [deleteAdmin, { loading, error }] = useMutation<void, DeleteAdminInput>(
    gql`
      mutation MutationDeleteAdmin($adminId: ID!) {
        deleteAdministrator(id: $adminId)
      }
    `,
    {
      fetchPolicy: 'no-cache',
    },
  );

  return useMemo(
    () => ({
      deleteAdmin,
      isLoading: loading,
      error,
    }),
    [deleteAdmin, error, loading],
  );
}
