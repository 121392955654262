'use client';

import { memo } from 'react';

import useMediaQuery from '~/hooks/useMediaQuery';
import theme from '~/theme';

import NavbarDesktop from './NavbarDesktop';
import NavbarMobile from './NavbarMobile';

const Navbar = memo(() => {
  const lteSmall = useMediaQuery(theme.medias.lteSmall);

  if (lteSmall) {
    return <NavbarMobile />;
  }

  return <NavbarDesktop />;
});

Navbar.displayName = 'Navbar';

export default Navbar;
