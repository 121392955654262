'use client';

import { Modal } from 'antd';
import { memo } from 'react';

import AboutContent from '~/components/AboutContent';
import i18n from '~/locales/i18n';
import type { ModalProps } from '~/types/modal';

const AboutModal = memo(({ isOpen, onClose }: ModalProps) => (
  <Modal
    title={i18n.t('header.user.about')}
    footer={null}
    centered
    width={500}
    open={isOpen}
    onCancel={onClose}
  >
    <AboutContent />
  </Modal>
));

AboutModal.displayName = 'AboutModal';

export default AboutModal;
