'use client';

import * as IVSPlayer from 'amazon-ivs-player';
import { memo, useState, useEffect, useRef, type MouseEvent, type RefObject } from 'react';
import styled from 'styled-components';

import Text from '~/components/Text';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import { STREAM_STATE, type IvsStream } from '~/types/videoStream';
import logger from '~/utils/logger';
import notification from '~/utils/notification';
import curateUrl from '~/utils/parse/curateUrl';
import getVideoStreamLogId from '~/utils/video/getVideoStreamLogId';

const GUTTER = '8px';
const VIDEO_PLAYER_BACKGROUND_COLOR = '#333333';

const VideoWrapperDiv = styled.div`
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const PositionRelativeDiv = styled.div`
  position: relative;
  display: block;
`;

const StyledText = styled(Text)`
  height: ${theme.dimensions.videoStreamPlayerHeight}px;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
`;

const VideoPopupDiv = styled.div`
  top: ${GUTTER};
  left: ${GUTTER};
  color: ${theme.colors.darkGrey};
  width: calc(100% - ${GUTTER} - ${GUTTER});
  padding: 12px;
  position: absolute;
  border-radius: 8px;
  pointer-events: none;
  background-color: ${theme.colors.white};
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const MAXIMUM_RELOAD_ATTEMPTS = 40;
const DEFAULT_PLAYER_AUTOPLAY = true;
const DEFAULT_PLAYER_MUTED = true;
const DEFAULT_PLAYER_VOLUME = 1;
const DEFAULT_REBUFFER_TO_LIVE = true;

function useLoadIvsPlayer(videoStream?: IvsStream): {
  videoElement: RefObject<HTMLVideoElement>;
} {
  const videoElement = useRef<HTMLVideoElement>(null);
  const player = useRef<IVSPlayer.MediaPlayer | null>(null);
  const reloadAttempts = useRef<number>(0);

  const streamUrl = `${videoStream?.playback_url}?token=${videoStream?.playback_key}`;

  useEffect(() => {
    const isStreamReady =
      videoStream?.ivs_stream_state === STREAM_STATE.START &&
      videoStream?.playback_url &&
      videoStream?.playback_key;

    logger.log('useLoadIvsPlayer: useEffect create', {
      videoStream,
      isStreamReady,
      videoStreamLogId: getVideoStreamLogId(videoStream?.playback_url),
      hasIvsPlayer: !!IVSPlayer,
      hasPlayer: !!player.current,
    });

    if (!isStreamReady || !IVSPlayer || player.current) {
      return undefined;
    }

    // First, check if the browser supports the Amazon IVS player.
    if (!IVSPlayer.isPlayerSupported) {
      notification.warning({
        message: 'Player not supported',
        description: 'The current browser does not support the Amazon IVS player.',
      });

      return undefined;
    }

    // Initialize player
    player.current = IVSPlayer.create({
      wasmBinary: curateUrl('/libs/amazon-ivs-player/amazon-ivs-wasmworker.min.wasm'),
      wasmWorker: curateUrl('/libs/amazon-ivs-player/amazon-ivs-wasmworker.min.js'),
    });

    if (videoElement.current) {
      player.current.attachHTMLVideoElement(videoElement.current);
    }

    const handleErrorEvent = (error: IVSPlayer.PlayerError) => {
      logger.log('useLoadIvsPlayer: handleErrorEvent', {
        videoStream,
        reloadAttempts: reloadAttempts.current,
        error,
      });

      if (reloadAttempts.current < MAXIMUM_RELOAD_ATTEMPTS) {
        reloadAttempts.current += 1;
        setTimeout(() => {
          player.current?.load(streamUrl);
        }, 1000);
      } else {
        notification.error({
          message: 'Player error',
          description: error.message,
        });
      }
    };

    // Attach event listeners
    player.current.addEventListener(IVSPlayer.PlayerEventType.ERROR, handleErrorEvent);
    // Setup stream default settings
    player.current.setRebufferToLive(DEFAULT_REBUFFER_TO_LIVE);
    player.current.setAutoplay(DEFAULT_PLAYER_AUTOPLAY);
    player.current.setVolume(DEFAULT_PLAYER_VOLUME);
    player.current.setMuted(DEFAULT_PLAYER_MUTED);
    // Debugging tool
    // player.current.setLogLevel(IVSPlayer.LogLevel.DEBUG);
    // Attempt to load the stream
    player.current.load(streamUrl);

    // Unsubscribing on component unload
    return () => {
      logger.log('useLoadIvsPlayer: useEffect destroy', {
        videoStream,
        videoStreamLogId: getVideoStreamLogId(videoStream?.playback_url),
        hasIvsPlayer: !!IVSPlayer,
        hasPlayer: !!player.current,
      });

      player.current?.removeEventListener(IVSPlayer.PlayerEventType.ERROR, handleErrorEvent);
      player.current?.delete();
      player.current = null;
    };
  }, [videoStream, streamUrl]);

  return { videoElement };
}

interface Props {
  agent: Agent | undefined;
  videoStream: IvsStream | undefined;
  isLoadingOrRunning: boolean;
  isStartOrRequestStartLoading: boolean;
  shouldPlayVideoFromAlarm: boolean;
}

const VideoStreamPlayerLowLatency = memo(
  ({
    agent,
    videoStream,
    isLoadingOrRunning,
    isStartOrRequestStartLoading,
    shouldPlayVideoFromAlarm,
  }: Props) => {
    const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);

    const { videoElement } = useLoadIvsPlayer(videoStream);

    const handleMouseOut = (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setIsPopupVisible(false);
    };

    const handleMouseOver = (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setIsPopupVisible(true);
    };

    const streamUrl = `${videoStream?.playback_url}?token=${videoStream?.playback_key}`;

    const isVideoSteamRunning = videoStream?.ivs_stream_state === STREAM_STATE.START;

    if (!agent) {
      return null;
    }

    const videoPlaceholderText = isStartOrRequestStartLoading
      ? i18n.t('common.loading')
      : i18n.t('carrierDetailsPopup.videoStreaming.offline');

    const mainText = shouldPlayVideoFromAlarm
      ? i18n.t('carrierDetailsPopup.videoStreaming.videoSteamWaitingToStart')
      : videoPlaceholderText;

    const popupText = isStartOrRequestStartLoading
      ? i18n.t('carrierDetailsPopup.videoStreaming.videoSteamWaitingToStart')
      : i18n.t('carrierDetailsPopup.videoStreaming.videoWillStartStreaming');

    return (
      <div>
        {isVideoSteamRunning && streamUrl ? (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video
            width="100%"
            height={theme.dimensions.videoStreamPlayerHeight}
            ref={videoElement}
            playsInline
            controls
            autoPlay
            data-id="video-stream-player"
            style={{
              backgroundColor: VIDEO_PLAYER_BACKGROUND_COLOR,
              borderRadius: '6px',
              overflow: 'hidden',
            }}
          />
        ) : (
          <VideoWrapperDiv>
            <PositionRelativeDiv data-id="video-stream-placeholder">
              {isPopupVisible && <VideoPopupDiv>{popupText}</VideoPopupDiv>}
              <StyledText
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                style={{
                  backgroundColor: isLoadingOrRunning
                    ? VIDEO_PLAYER_BACKGROUND_COLOR
                    : theme.colors.greyBackground,
                  color: isLoadingOrRunning ? theme.colors.white : theme.colors.darkBlue,
                }}
              >
                {mainText}
              </StyledText>
            </PositionRelativeDiv>
          </VideoWrapperDiv>
        )}
      </div>
    );
  },
);

VideoStreamPlayerLowLatency.displayName = 'VideoStreamPlayerLowLatency';

export default VideoStreamPlayerLowLatency;
