'use client';

import { Button, Modal } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import useAuthenticationContext from '~/context/useAuthenticationContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import i18n from '~/locales/i18n';
import useUserInteractions from '~/store/useUserInteractions';
import type { ModalProps } from '~/types/modal';

const DescriptionDiv = styled.div`
  margin-top: 12px;
`;

const ActionsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const WelcomeBackModal = memo(({ isOpen, onClose }: ModalProps) => {
  const { isAuthenticated, idToken } = useAuthenticationContext();
  const { currentUser } = useCurrentUserContext();

  const userEmail = currentUser?.email || idToken?.email;
  const userName =
    [currentUser?.first_name, currentUser?.last_name].filter((name) => !!name).join(' ') ||
    [idToken?.given_name, idToken?.family_name].filter((name) => !!name).join(' ');

  const userInteractedWithDocument = useUserInteractions(
    (state) => state.userInteractedWithDocument,
  );

  if (!isAuthenticated || userInteractedWithDocument) {
    return null;
  }

  return (
    <Modal
      title={i18n.t('welcomeBackModal.title')}
      footer={null}
      centered
      width={500}
      open={isOpen}
      onOk={onClose}
      onCancel={onClose}
      style={{ animationDuration: '0s' }}
    >
      <DescriptionDiv>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: i18n.t<string>('welcomeBackModal.messageHtml', {
              email: userEmail,
            }),
          }}
        />
      </DescriptionDiv>
      <ActionsDiv>
        <b>{userName}</b>
        <Button data-id="WelcomeBackModal-buttonOk" onClick={onClose}>
          {i18n.t('welcomeBackModal.ok')}
        </Button>
      </ActionsDiv>
    </Modal>
  );
});

WelcomeBackModal.displayName = 'WelcomeBackModal';

export default WelcomeBackModal;
