import theme from '~/theme';
import { ALARM_LEVEL } from '~/types/alarm';

export default function getAlarmColorFromLevel(level: ALARM_LEVEL): {
  background: string;
  text: string;
} {
  // If you change colors here also change them in public/icons/markers/alarm/*.svg
  switch (level) {
    case ALARM_LEVEL.Information:
      return {
        background: '#e6f7ff',
        text: theme.colors.primaryBlue,
      };
    case ALARM_LEVEL.Warning:
      return {
        background: '#fff2cc',
        text: '#ffa500',
      };
    case ALARM_LEVEL.Critical:
    default:
      return {
        background: '#ffe1e1',
        text: theme.colors.red,
      };
  }
}
