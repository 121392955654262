'use client';

import VideoCameraOutlined from '@ant-design/icons/VideoCameraOutlined';
import { Button, Tooltip } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import VideoStreamPlayer from '~/components/VideoStreamPlayer';
import useModalsContext from '~/context/useModalsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';

const RecordingsDiv = styled.div`
  margin-top: 10px;
`;

interface Props {
  agent: Agent | undefined;
}

const SectionVideoStreaming = memo(({ agent }: Props) => {
  const { companyFeatures } = useCompanyFeatures();
  const { openModal } = useModalsContext();

  return (
    <div data-id="SectionVideoStreaming">
      <VideoStreamPlayer agent={agent} />
      {companyFeatures.playbackRecordedVideos && (
        <RecordingsDiv>
          <Tooltip
            title={i18n.t('carrierDetailsPopup.videoStreaming.recordingsTooltip')}
            placement="bottomLeft"
          >
            <Button
              type="dashed"
              icon={<VideoCameraOutlined data-id="video-recordings-btn" />}
              onClick={() => {
                if (agent?.id) {
                  openModal({
                    type: 'videoRecordings',
                    defaultAgentId: agent.id,
                    initialDateRange: ['', ''],
                  });
                }
              }}
            >
              {i18n.t('carrierDetailsPopup.videoStreaming.recordings')}
            </Button>
          </Tooltip>
        </RecordingsDiv>
      )}
    </div>
  );
});

SectionVideoStreaming.displayName = 'SectionVideoStreaming';

export default SectionVideoStreaming;
