'use client';

import { memo } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as PrismSyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';

import curateUrl from '~/utils/parse/curateUrl';

interface Props {
  markdown: string | undefined;
}

const MarkdownContent = memo(({ markdown }: Props) => {
  if (!markdown) {
    return null;
  }

  return (
    <ReactMarkdown
      // eslint-disable-next-line react/no-children-prop
      children={markdown}
      transformImageUri={curateUrl}
      components={{
        // eslint-disable-next-line react/no-unstable-nested-components
        code({ children, className, ...rest }) {
          const match = /language-(\w+)/.exec(className || '');
          return match ? (
            <PrismSyntaxHighlighter
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...rest}
              // eslint-disable-next-line react/no-children-prop
              children={String(children).replace(/\n$/, '')}
              style={prism}
              language={match[1]}
              PreTag="div"
            />
          ) : (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <code {...rest} className={className}>
              {children}
            </code>
          );
        },
      }}
    />
  );
});

MarkdownContent.displayName = 'MarkdownContent';

export default MarkdownContent;
