'use client';

import { memo } from 'react';
import styled from 'styled-components';

const Div = styled.div`
  margin-bottom: 24px;
`;

const H2 = styled.h2`
  margin: 0;
  margin-bottom: 4px;
`;

const P = styled.p`
  margin: 0;
`;

interface Props {
  title: string;
  description: string;
  textAlign?: 'center' | 'left';
}

const AuthFormHeader = memo(({ title, description, textAlign = 'center' }: Props) => (
  <Div style={{ textAlign }}>
    <H2>{title}</H2>
    <P>{description}</P>
  </Div>
));

AuthFormHeader.displayName = 'AuthFormHeader';

export default AuthFormHeader;
