'use client';

import { Modal } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import AdminAddEditForm, { type FormValues } from '~/components/forms/AdminAddEditForm';
import i18n from '~/locales/i18n';
import type { ModalProps } from '~/types/modal';

const StyledAdminAddEditForm = styled(AdminAddEditForm)`
  > div:first-child {
    max-height: 70vh;
    overflow-y: auto;
    padding-right: 8px;
  }
`;

export interface AdminAddEditModalProps {
  initialValues: Partial<FormValues> | undefined;
  refetchOnCompleted: () => Promise<void>;
}

const AdminAddEditModal = memo(
  ({ isOpen, onClose, initialValues, refetchOnCompleted }: ModalProps & AdminAddEditModalProps) => (
    <Modal
      title={i18n.t(
        `companySettingsPage.adminsTab.${initialValues?.email ? 'edit' : 'createNew'}Admin`,
      )}
      footer={null}
      centered
      width={500}
      open={isOpen}
      onCancel={onClose}
    >
      <StyledAdminAddEditForm
        key={initialValues?.email}
        initialValues={initialValues}
        onSuccess={refetchOnCompleted}
        onClose={onClose}
      />
    </Modal>
  ),
);

AdminAddEditModal.displayName = 'AdminAddEditModal';

export default AdminAddEditModal;
