'use client';

import { memo } from 'react';

import useCompany from '~/hooks/useCompany';

import SectionDeveloperTools from './components/SectionDeveloperTools';
import SectionIntegrationTokens from './components/SectionIntegrationTokens';

const TabIntegrations = memo(() => {
  const { currentCompany } = useCompany();

  if (!currentCompany?.id) {
    return null;
  }

  return (
    <>
      <SectionDeveloperTools />
      <SectionIntegrationTokens />
    </>
  );
});

TabIntegrations.displayName = 'TabIntegrations';

export default TabIntegrations;
