'use client';

import { useQuery, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import QUERY_CURRENT_USER, {
  type CurrentUserQueryData,
} from '~/apollo/operations/queries/QueryCurrentUser';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import type { UserType } from '~/types/user';

interface UseQueryCurrentUserResponseType {
  currentUser?: UserType;
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => void;
}

export default function useQueryCurrentUser(): UseQueryCurrentUserResponseType {
  const { isAuthenticated } = useAuthenticationContext();

  const { refetch, data, loading, error } = useQuery<CurrentUserQueryData>(QUERY_CURRENT_USER, {
    skip: !isAuthenticated,
  });

  const currentUser = useMemo(
    () =>
      data?.me
        ? {
            ...data.me,
            type: data.me.__typename,
            company: data.me.company || data.me.subsidiary?.company,
          }
        : undefined,
    [data],
  );

  return useMemo(
    () => ({
      currentUser,
      isLoading: loading,
      error,
      refetch,
    }),
    [currentUser, loading, error, refetch],
  );
}
