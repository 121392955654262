'use client';

import { memo, useMemo, type CSSProperties } from 'react';
import ReactTimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import enTranslations from 'react-timeago/lib/language-strings/en';
import frTranslations from 'react-timeago/lib/language-strings/fr';

import { currentLanguage } from '~/locales/i18n';
import { LOCALE_CODE } from '~/types/locale';

interface Props {
  date: string | number | Date;
  title?: string;
  className?: string;
  style?: CSSProperties;
}

const TimeAgo = memo(({ date, title, className, style }: Props) => {
  const language = currentLanguage();

  const formatter = useMemo(() => {
    const translations = (() => {
      switch (language) {
        case LOCALE_CODE.FRENCH:
          return frTranslations;
        case LOCALE_CODE.ENGLISH:
        default:
          return enTranslations;
      }
    })();
    return buildFormatter(translations);
  }, [language]);

  if (!date) {
    return null;
  }

  return (
    <ReactTimeAgo
      formatter={formatter}
      date={date}
      title={title || ''}
      className={className}
      style={style}
      // React-Timeago is live by default and will auto update it's value. However, if you don't want this behaviour, you can set live:false.
      live
      // A custom function that can be used instead of Date.now() to get the current value of epochSeconds in formatter below. This can be specifically useful for server rendering when you want the datetime to be the same on the server and client.
      now={() => Date.now()}
      // The minimum number of seconds that the component should wait before updating. The component will still update if you pass new props. Use this if, for example, you don't want to update every second for recent times.
      minPeriod={0}
      // The opposite of minPeriod. Use this to force dates to update more often than the default behaviour. For example, you can use this update a time every 5 minutes even after it is more than an hour old.
      maxPeriod={0.1}
    />
  );
});

TimeAgo.displayName = 'TimeAgo';

export default TimeAgo;
