'use client';

import { Tooltip } from 'antd';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import useAgentsContext from '~/context/useAgentsContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import getAgentStatusTranslation from '~/utils/agent/getAgentStatusTranslation';

const AGENT_HEADER_SPACING = '0.25rem';

const CIRCLE_WIDTH = '44px';

const ProfileNameDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, ${CIRCLE_WIDTH}) minmax(0, 1fr);
  align-items: center;
  grid-gap: 12px;
  margin-bottom: 16px;
`;

const ProfileCircleDiv = styled.div`
  align-items: center;
  display: flex;
  font-weight: regular;
  justify-content: center;
  border-radius: 32px;
  margin-right: 0;
  font-size: 14px;
  height: ${CIRCLE_WIDTH};
  width: ${CIRCLE_WIDTH};
  border: 1px solid ${theme.colors.white};
  margin: 0;
  cursor: help;
`;

const TitleH1 = styled.h1`
  line-height: 1;
  margin: 0 0 ${AGENT_HEADER_SPACING};
  font-size: 16px;
  color: ${theme.colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SubtitleH2 = styled.h2`
  line-height: 1;
  margin: 0;
  font-size: 12px;
  opacity: 0.7;
  color: ${theme.colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  activeAgentId: string;
}

const AgentHeader = memo(({ activeAgentId }: Props) => {
  const { agents } = useAgentsContext();

  const agent = useMemo(() => agents.find((a) => a.id === activeAgentId), [activeAgentId, agents]);

  const agentStatusText = useMemo(
    () => (agent?.status ? getAgentStatusTranslation(agent) : ''),
    [agent],
  );

  return (
    <ProfileNameDiv>
      <Tooltip title={agentStatusText} placement="topLeft">
        <ProfileCircleDiv
          style={{
            backgroundColor: theme.colors.darkBlue,
            borderColor: theme.colors.darkBlue,
            color: theme.colors.white,
          }}
          data-id="details-popup-bullet-label"
        >
          {agent?.attributes?.acronym}
        </ProfileCircleDiv>
      </Tooltip>
      <div>
        <TitleH1 data-id="details-popup-title">{agent?.completeName}</TitleH1>
        <SubtitleH2 data-id="details-popup-subtitle">
          {i18n.t('agentsPage.agent')}
          {agent?.team ? ` (${agent.team})` : ''}
        </SubtitleH2>
      </div>
    </ProfileNameDiv>
  );
});

AgentHeader.displayName = 'AgentHeader';

export default AgentHeader;
