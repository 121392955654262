'use client';

import { useQuery, type ApolloError } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';

import QUERY_DEVICE_CONFIGURATIONS, {
  type DeviceConfigurationsQueryData,
  type DeviceConfigurationsQueryVariables,
} from '~/apollo/operations/queries/QueryDeviceConfigurations';
import type { DeviceConfiguration } from '~/types/configurationDevice';

export default function useQueryDeviceConfigurations({ skip = false }: { skip: boolean }): {
  deviceConfigurations: DeviceConfiguration[];
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => void;
} {
  const { data, loading, error, refetch } = useQuery<
    DeviceConfigurationsQueryData,
    DeviceConfigurationsQueryVariables
  >(QUERY_DEVICE_CONFIGURATIONS, {
    variables: {
      nextToken: null,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip,
  });

  return useMemo(
    () => ({
      deviceConfigurations: orderBy(data?.configurations?.items || [], ['name'], ['asc']),
      isLoading: loading,
      error,
      refetch,
    }),
    [data?.configurations?.items, loading, error, refetch],
  );
}
