'use client';

import UserOutlined from '@ant-design/icons/UserOutlined';
import { Input, Form } from 'antd';
import { memo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthFormButton from '~/components/forms/components/AuthFormButton';
import AuthFormHeader from '~/components/forms/components/AuthFormHeader';
import { BlackLink } from '~/components/Link';
import routes from '~/config/routes';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import notification from '~/utils/notification';

interface FormFields {
  email: string;
}
const ForgotPasswordPage = memo(() => {
  const { forgotPassword } = useAuthenticationContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const handleFinish = useCallback(
    ({ email }: FormFields) => {
      setLoading(true);
      forgotPassword({ email })
        .then(() => {
          notification.success({
            message: i18n.t('forgotPassword.successTitle'),
            description: i18n.t('forgotPassword.successDescription'),
          });
          navigate(routes.resetPassword({ email }));
        })
        .catch((catchedError: Error) => {
          notification.error({
            message: i18n.t('forgotPassword.failTitle'),
            description: catchedError.message,
          });
          setLoading(false);
        });
    },
    [forgotPassword, navigate],
  );

  return (
    <Form onFinish={handleFinish}>
      <AuthFormHeader
        title={i18n.t('forgotPassword.title')}
        description={i18n.t('forgotPassword.description')}
      />
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: i18n.t<string>('forgotPassword.invalidEmail'),
          },
        ]}
      >
        <Input
          required
          type="email"
          addonBefore={<UserOutlined style={{ color: theme.colors.black }} />}
          placeholder={i18n.t<string>('forgotPassword.email')}
        />
      </Form.Item>
      <Form.Item>
        <AuthFormButton loading={loading}>{i18n.t('forgotPassword.submit')}</AuthFormButton>
      </Form.Item>
      <BlackLink to={routes.login}>{i18n.t('forgotPassword.goBack')}</BlackLink>
    </Form>
  );
});

ForgotPasswordPage.displayName = 'ForgotPasswordPage';

export default ForgotPasswordPage;
