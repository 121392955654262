'use client';

import { useEffect, useMemo, useState } from 'react';

import { computeDuration } from '~/utils/dateTime';

interface Props {
  startTime: string | undefined;
  endTime: string | undefined;
}

export default function useLiveDuration({ startTime, endTime }: Props): string {
  const [durationText, setDurationText] = useState<string>(
    startTime ? computeDuration(startTime, endTime) : '',
  );

  useEffect(() => {
    let intervalId: NodeJS.Timer;

    if (startTime && !endTime) {
      intervalId = setInterval(() => {
        setDurationText(computeDuration(startTime, undefined));
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [startTime, endTime]);

  return useMemo(() => durationText, [durationText]);
}
