'use client';

import { Modal } from 'antd';
import { memo } from 'react';

import BrainAssociateToAgentForm, {
  type Props,
} from '~/components/forms/BrainAssociateToAgentForm';
import i18n from '~/locales/i18n';
import type { ModalProps } from '~/types/modal';

export interface BrainAssociateToAgentModalProps {
  allBrains: Props['allBrains'];
  initialValues: Props['initialValues'];
  refetchOnCompleted: () => Promise<void>;
}

const BrainAssociateToAgentModal = memo(
  ({
    isOpen,
    onClose,
    allBrains,
    initialValues,
    refetchOnCompleted,
  }: ModalProps & BrainAssociateToAgentModalProps) => (
    <Modal
      title={i18n.t('backofficePage.brainsTab.associateBrain')}
      footer={null}
      centered
      width={500}
      open={isOpen}
      onCancel={onClose}
    >
      <BrainAssociateToAgentForm
        key={JSON.stringify(initialValues || {})}
        allBrains={allBrains}
        initialValues={initialValues}
        onSuccess={refetchOnCompleted}
        onClose={onClose}
      />
    </Modal>
  ),
);

BrainAssociateToAgentModal.displayName = 'BrainAssociateToAgentModal';

export default BrainAssociateToAgentModal;
