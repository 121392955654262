'use client';

import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import { createCustomEqual } from 'fast-equals';
import { useRef, useEffect, type EffectCallback } from 'react';

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: number | google.maps.LatLng, b: number | google.maps.LatLng) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }
    return deepEqual(a, b);
  },
);

function useDeepCompareMemoize(value: unknown) {
  const ref = useRef<unknown>();
  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

export default function useDeepCompareEffectForMaps(
  callback: EffectCallback,
  dependencies: unknown[],
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
