'use client';

import type { Locale as AntdLocale } from 'antd/es/locale';
import antdLocaleEn from 'antd/locale/en_US';
import antdLocaleFr from 'antd/locale/fr_FR';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekday from 'dayjs/plugin/weekday';
import i18n, { type TFunction } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import merge from 'lodash/merge';
import { initReactI18next } from 'react-i18next';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';

import { LOCALE_CODE, LOCALE_TEXT, type LocaleType } from '~/types/locale';

import antdOverwriteEn from './antd-overwrites/en-antd-overwrite.json';
import antdOverwriteFr from './antd-overwrites/fr-antd-overwrite.json';
import { en, fr } from './texts';

dayjs.extend(localeData);
dayjs.extend(updateLocale);
dayjs.extend(weekday);

dayjs.locale(LOCALE_CODE.ENGLISH);
dayjs.updateLocale(LOCALE_CODE.ENGLISH, {
  weekStart: 1,
});

dayjs.locale(LOCALE_CODE.FRENCH);
dayjs.updateLocale(LOCALE_CODE.FRENCH, {
  weekStart: 1,
});

const locale = {
  english: {
    code: LOCALE_CODE.ENGLISH,
    label: LOCALE_TEXT.ENGLISH,
  },
  french: {
    code: LOCALE_CODE.FRENCH,
    label: LOCALE_TEXT.FRENCH,
  },
};

export const defaultLocale: LocaleType = locale.english;

export function getLocale(code: LOCALE_CODE): LocaleType | undefined {
  return Object.values(locale).find((element: LocaleType) => element.code === code);
}

export function setLocale(language: LOCALE_CODE): Promise<TFunction> {
  return i18n.changeLanguage(language);
}

export function getCurrentLocale(): LocaleType | undefined {
  return getLocale(i18n.language as LOCALE_CODE);
}
export function currentLanguage(): LOCALE_CODE {
  return (i18n.language as LOCALE_CODE) ?? defaultLocale;
}

export function getLocaleList(): LocaleType[] {
  return Object.values(locale);
}

export function getAntdLocale(): AntdLocale {
  switch (currentLanguage()) {
    case LOCALE_CODE.FRENCH:
      return merge(antdLocaleFr, antdOverwriteFr);
    case LOCALE_CODE.ENGLISH:
    default:
      return merge(antdLocaleEn, antdOverwriteEn);
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      [locale.english.code]: { translation: en },
      [locale.french.code]: { translation: fr },
    },
    detection: {
      order: ['navigator'],
    },
    lng: localStorage.getItem('i18nextLng') ?? defaultLocale.code,
    fallbackLng: defaultLocale.code,
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    react: {
      nsMode: 'default',
    },
  });

export default i18n;
