'use client';

import ApiOutlined from '@ant-design/icons/ApiOutlined';
import MinusOutlined from '@ant-design/icons/MinusOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import { Table, Tooltip, Button, Tag, type TableColumnsType } from 'antd';
import { memo, useCallback, useMemo, type Key, type ReactNode } from 'react';
import ReactAvatar from 'react-avatar';
import styled from 'styled-components';

import useMutationAssociateBrainToAgent from '~/apollo/hooks/brain/useMutationAssociateBrainToAgent';
import useMutationDisassociateBrainFromAgent from '~/apollo/hooks/brain/useMutationDisassociateBrainFromAgent';
import useQueryAllBrains from '~/apollo/hooks/brain/useQueryAllBrains';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import {
  SettingsTopFlexDiv,
  SettingsButtonsFlexDiv,
  SelectCompanySubsidiaryDiv,
} from '~/components/settings/shared';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import notification from '~/utils/notification';
import sortTableColumnWithEmptyValues from '~/utils/table/sortTableColumnWithEmptyValues';

import { TextAvatarDiv } from '../TabSuperAdmins/TabSuperAdmins';

const WrapperDiv = styled.div`
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

const StyledTable = styled(Table)`
  overflow-x: auto;
  border: none;
  margin-bottom: 16px;

  td {
    background: #ffffff;
  }
`;

const ActionsDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

interface TableDataType {
  key: Key;
  deviceName: string;
  agentId: string | null;
  agentName: string | null;
  agentEmail: string | null;
  companyId: string | null;
  companyName: string | null;
  subsidiaryId: string | null;
  subsidiaryName: string | null;
  requestedAssociation: string | null;
  actions: ReactNode;
}

const TabBrains = memo(() => {
  const { isLoading: isLoadingCurrentUser } = useCurrentUserContext();
  const { openModal } = useModalsContext();

  const { allBrains, isLoading: isQueryAllDevicesLoading, refetchAllBrains } = useQueryAllBrains();

  const { isAssociateBrainToAgentLoading } = useMutationAssociateBrainToAgent();
  const { disassociateBrainFromAgent, isDisassociateBrainFromAgentLoading } =
    useMutationDisassociateBrainFromAgent();

  const isLoading =
    isLoadingCurrentUser ||
    isQueryAllDevicesLoading ||
    isAssociateBrainToAgentLoading ||
    isDisassociateBrainFromAgentLoading;

  const totalRefetch = useCallback(async () => {
    await Promise.all([refetchAllBrains()]);
  }, [refetchAllBrains]);

  const columns: TableColumnsType<TableDataType> = useMemo(
    () => [
      {
        dataIndex: 'deviceName',
        title: i18n.t('common.brain'),
        sorter: (a, b) => a.deviceName.toLowerCase().localeCompare(b.deviceName.toLowerCase()),
        defaultSortOrder: 'ascend',
        render: (text) => <b>{text}</b>,
      },
      {
        dataIndex: 'agentName',
        title: i18n.t('common.agentCode'),
        sorter: (a, b, sortOrder) =>
          sortTableColumnWithEmptyValues(a.agentName, b.agentName, sortOrder),
        render: (text) => (text ? <Tag color={theme.colors.primaryBlue}>{text}</Tag> : '-'),
      },
      {
        dataIndex: 'agentEmail',
        title: i18n.t('common.agentEmail'),
        sorter: (a, b, sortOrder) =>
          sortTableColumnWithEmptyValues(a.agentEmail, b.agentEmail, sortOrder),
        render: (text) => text || '-',
      },
      {
        dataIndex: 'companyName',
        title: i18n.t('common.company'),
        sorter: (a, b, sortOrder) =>
          sortTableColumnWithEmptyValues(a.companyName, b.companyName, sortOrder),
        render: (text) =>
          text ? (
            <TextAvatarDiv>
              <ReactAvatar name={text} size="26px" /> {text}
            </TextAvatarDiv>
          ) : (
            '-'
          ),
      },
      {
        dataIndex: 'subsidiaryName',
        title: i18n.t('common.subsidiary'),
        sorter: (a, b, sortOrder) =>
          sortTableColumnWithEmptyValues(a.subsidiaryName, b.subsidiaryName, sortOrder),
        render: (text) =>
          text ? (
            <TextAvatarDiv>
              <ReactAvatar name={text} size="26px" /> {text}
            </TextAvatarDiv>
          ) : (
            '-'
          ),
      },
      {
        dataIndex: 'actions',
        title: i18n.t('common.actions'),
        fixed: 'right',
      },
    ],
    [],
  );

  const data: TableDataType[] = useMemo(
    () =>
      allBrains.map((brain) => ({
        key: brain.name,
        deviceName: brain.name,
        agentId: brain.carrier?.id || null,
        agentName: brain.carrier?.name || null,
        agentEmail: brain.carrier?.email || null,
        companyId: brain.carrier?.subsidiary?.company?.id || null,
        companyName: brain.carrier?.subsidiary?.company?.name || null,
        subsidiaryId: brain.carrier?.subsidiary?.id || null,
        subsidiaryName: brain.carrier?.subsidiary?.name || null,
        requestedAssociation: brain.carrier?.requested_association || null,
        actions: (
          <ActionsDiv>
            <Tooltip
              title={
                brain.carrier?.id
                  ? i18n.t('backofficePage.brainsTab.brainAlreadyAssociated')
                  : i18n.t('backofficePage.brainsTab.associateBrain')
              }
            >
              <Button
                disabled={isLoading || !!brain.carrier?.id}
                onClick={() => {
                  openModal({
                    type: 'brainAssociateToAgent',
                    allBrains,
                    initialValues: {
                      deviceName: brain.name,
                    },
                    refetchOnCompleted: totalRefetch,
                  });
                }}
                icon={<PlusOutlined />}
              />
            </Tooltip>
            <Tooltip
              title={
                !brain.carrier?.id
                  ? i18n.t('backofficePage.brainsTab.brainAlreadyDisassociated')
                  : i18n.t('backofficePage.brainsTab.disassociateBrain')
              }
            >
              <Button
                danger
                disabled={isLoading || !brain.carrier?.id}
                onClick={() => {
                  openModal({
                    type: 'confirmation',
                    title: i18n.t('backofficePage.brainsTab.disassociateBrain'),
                    description: (
                      <>
                        <div
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: i18n.t<string>(
                              'backofficePage.brainsTab.areYouSureYouWantToDisassociateBrainHtml',
                              {
                                brain: brain.name,
                                agent: `${brain.carrier?.name} (${brain.carrier?.email})`,
                                subsidiary: `${brain.carrier?.subsidiary?.name} (${brain.carrier?.subsidiary?.company?.name})`,
                              },
                            ),
                          }}
                        />
                        <br />
                        <div
                          style={{
                            fontStyle: 'italic',
                          }}
                        >
                          <WarningOutlined />{' '}
                          {i18n.t('backofficePage.brainsTab.disassociateBrainWarningMessage')}
                        </div>
                      </>
                    ),
                    actionType: 'danger',
                    action: async () => {
                      if (!brain?.carrier?.id) {
                        return;
                      }
                      try {
                        await disassociateBrainFromAgent({
                          variables: {
                            carrier_id: brain.carrier.id,
                            device_name: brain.name,
                          },
                        });
                        await totalRefetch();
                        notification.success({
                          message: i18n.t('common.success'),
                          description: i18n.t(
                            'backofficePage.brainsTab.brainDisassociatedSuccessfully',
                          ),
                        });
                      } catch (error) {
                        notification.error({
                          message: (error as any)?.message || i18n.t('common.error'),
                        });
                      }
                    },
                  });
                }}
                icon={<MinusOutlined />}
              />
            </Tooltip>
          </ActionsDiv>
        ),
      })),
    [allBrains, disassociateBrainFromAgent, isLoading, openModal, totalRefetch],
  );

  return (
    <WrapperDiv>
      <SelectCompanySubsidiaryDiv />
      <SettingsTopFlexDiv>
        <TabSectionHeader
          title={
            isLoading
              ? i18n.t('common.loading')
              : `${i18n.t('backofficePage.brainsTab.listOfBrains')} (${allBrains.length})`
          }
        />
        <SettingsButtonsFlexDiv>
          <Button
            loading={isLoading}
            icon={<ReloadOutlined />}
            onClick={async () => {
              await totalRefetch();
            }}
          >
            {i18n.t('common.refresh')}
          </Button>
          <Button
            loading={isLoading}
            type="primary"
            icon={<ApiOutlined />}
            onClick={() => {
              openModal({
                type: 'brainAssociateToAgent',
                allBrains,
                initialValues: undefined,
                refetchOnCompleted: totalRefetch,
              });
            }}
          >
            {i18n.t('backofficePage.brainsTab.associateBrain')}
          </Button>
        </SettingsButtonsFlexDiv>
      </SettingsTopFlexDiv>
      <StyledTable
        tableLayout="auto"
        loading={isLoading}
        columns={columns}
        dataSource={data}
        sortDirections={['ascend', 'descend', 'ascend']}
        pagination={{
          defaultPageSize: 1000,
          showSizeChanger: false,
          pageSizeOptions: undefined,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} brains`,
          hideOnSinglePage: true,
        }}
      />
    </WrapperDiv>
  );
});

TabBrains.displayName = 'TabBrains';

export default TabBrains;
