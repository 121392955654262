'use client';

import { Modal } from 'antd';
import { memo, type ReactNode } from 'react';

import type { ModalProps } from '~/types/modal';

export interface InfoModalProps {
  title: string;
  content: ReactNode;
}

const InfoModal = memo(({ isOpen, onClose, title, content }: ModalProps & InfoModalProps) => (
  <Modal title={title} footer={null} centered width={600} open={isOpen} onCancel={onClose}>
    {content}
  </Modal>
));

InfoModal.displayName = 'InfoModal';

export default InfoModal;
