'use client';

import { Modal } from 'antd';
import { memo, useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';

import EditorialContent from '~/components/EditorialContent';
import LoadingSpinner from '~/components/LoadingSpinner';
import MarkdownContent from '~/components/MarkdownContent';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { LOCALE_CODE } from '~/types/locale';
import type { ModalProps } from '~/types/modal';
import logger from '~/utils/logger';

const BORDER = '1px solid #e8e8e8';
const MODAL_CONTENT_HEIGHT = '600px';

const StyledModal = styled(Modal)`
  max-width: 100%;

  & div.ant-modal-content {
    padding: 0;
    overflow: hidden;
  }
`;

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 200px) minmax(0, 1fr);
  height: ${MODAL_CONTENT_HEIGHT};
  overflow: hidden;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 150px) minmax(0, 1fr);
  }
`;

const LeftDiv = styled.div`
  height: ${MODAL_CONTENT_HEIGHT};
  overflow: hidden;
  border-right: ${BORDER};
`;

const Ul = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow-y: auto;
  height: ${MODAL_CONTENT_HEIGHT};
`;

const Li = styled.li`
  & > span {
    display: inline-block;
    width: 100%;
  }
`;

const ItemButton = styled.button<{ $active: boolean }>`
  cursor: pointer;
  background: ${(props) => (props.$active ? theme.colors.primaryBlue : 'transparent')};
  color: ${(props) => (props.$active ? theme.colors.white : theme.colors.black)};
  padding: 16px;
  border: none;
  border-left: ${BORDER};
  border-bottom: ${BORDER};
  font-size: 16px;
  text-align: left;
  width: 100%;

  &:hover {
    background: ${(props) => (props.$active ? theme.colors.primaryBlue : theme.colors.lightGrey)};
  }
`;

const ContentDiv = styled.div`
  padding: 12px 12px 0;
  overflow-y: auto;
`;

const TitleH2 = styled.h2`
  margin: 0 0 16px;
`;

const DOCUMENTATION_PAGES = [
  {
    id: 'general',
    title: 'General',
  },
  {
    id: 'measurements',
    title: 'Measurements',
  },
  {
    id: 'alarms',
    title: 'Alarms',
  },
  {
    id: 'location',
    title: 'Location',
  },
  {
    id: 'pagination',
    title: 'Pagination',
  },
  {
    id: 'videoStreams',
    title: 'Video streams',
  },
] as const;

type DocumentatioPageId = (typeof DOCUMENTATION_PAGES)[number]['id'];

const ApiDocumentationModal = memo(({ isOpen, onClose }: ModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<DocumentatioPageId>(DOCUMENTATION_PAGES[0].id);
  const [markdownPages, setMarkdownPages] = useState<Partial<Record<DocumentatioPageId, string>>>(
    {},
  );

  const markdownContent = markdownPages[currentPage];

  const fetchMarkdownItem = useCallback(
    async (
      item: DocumentatioPageId,
    ): Promise<{ item: DocumentatioPageId; markdown: string | undefined }> => {
      try {
        const fileName: `${DocumentatioPageId}.${LOCALE_CODE}.md` = `${item}.en.md`;
        const result = await import(`~/locales/content/apiDocumentation/${fileName}`);
        const response = await fetch(result.default);
        const markdown = await response.text();
        return { item, markdown };
      } catch (error) {
        logger.error('ApiDocumentationModal: fetchMarkdownItem error', { error });
        return { item, markdown: undefined };
      }
    },
    [],
  );

  useEffect(() => {
    setCurrentPage(DOCUMENTATION_PAGES[0].id);
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const items = await Promise.all(
        DOCUMENTATION_PAGES.map((item) => fetchMarkdownItem(item.id)),
      );
      setMarkdownPages(items.reduce((acc, item) => ({ ...acc, [item.item]: item.markdown }), {}));
      setIsLoading(false);
    })();
  }, [fetchMarkdownItem]);

  const menuListRef = useRef<HTMLUListElement>(null);
  const contentDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    contentDivRef.current?.scrollTo(0, 0);
    if (isOpen) {
      logger.log('ApiDocumentationModal: opened item', { currentPage });
    }
  }, [isOpen, currentPage]);

  useEffect(() => {
    menuListRef.current?.scrollTo(0, 0);
    contentDivRef.current?.scrollTo(0, 0);
  }, [isOpen]);

  return (
    <StyledModal
      title={i18n.t('integrations.apiDocumentation')}
      footer={null}
      centered
      width={1200}
      open={isOpen}
      onCancel={onClose}
      styles={{
        header: {
          padding: '16px',
          margin: 0,
          borderBottom: BORDER,
        },
      }}
    >
      <GridDiv>
        <LeftDiv>
          <Ul ref={menuListRef} data-id="highlighted-feature-list">
            {DOCUMENTATION_PAGES.map((item) => (
              <Li key={item.id}>
                <ItemButton
                  $active={currentPage === item.id}
                  onClick={() => {
                    setCurrentPage(item.id);
                  }}
                >
                  {item.title}
                </ItemButton>
              </Li>
            ))}
          </Ul>
        </LeftDiv>
        <ContentDiv ref={contentDivRef}>
          <TitleH2 data-id="highlighted-feature-title">
            {DOCUMENTATION_PAGES.find((item) => item.id === currentPage)?.title}
          </TitleH2>
          {isLoading ? (
            <LoadingSpinner style={{ marginTop: '16px' }} />
          ) : (
            <EditorialContent>
              <MarkdownContent markdown={markdownContent} />
            </EditorialContent>
          )}
        </ContentDiv>
      </GridDiv>
    </StyledModal>
  );
});

ApiDocumentationModal.displayName = 'ApiDocumentationModal';

export default ApiDocumentationModal;
