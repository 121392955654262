'use client';

import { useMutation, useReactiveVar, type ApolloError } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import MUTATION_SET_COMPANY_ATTRIBUTE, {
  type SetCompanyAttributeMutationData,
  type SetCompanyAttributeMutationVariables,
} from '~/apollo/operations/mutations/MutationSetCompanyAttribute';
import QUERY_COMPANY_DETAILS from '~/apollo/operations/queries/QueryCompanyDetails';
import { currentCompanyIdentifierVar } from '~/apollo/reactiveVariables/currentCompanyIdentifierVar';

type SetCompanyAttributeParams = Omit<SetCompanyAttributeMutationVariables, 'companyId'>;

export default function useMutationSetCompanyAttribute(): {
  setCompanyAttribute: (params: SetCompanyAttributeParams) => Promise<void>;
  updatedCompany: SetCompanyAttributeMutationData['setCompanyAttribute'] | undefined;
  isLoading: boolean;
  error: ApolloError | undefined;
} {
  const currentCompanyId = useReactiveVar(currentCompanyIdentifierVar);

  const [setCompanyAttributeMutation, { data, loading, error }] = useMutation<
    SetCompanyAttributeMutationData,
    SetCompanyAttributeMutationVariables
  >(MUTATION_SET_COMPANY_ATTRIBUTE, {
    refetchQueries: [
      { query: QUERY_COMPANY_DETAILS, variables: { companyID: currentCompanyId } },
      'QueryCompanyDetails',
    ],
  });

  const setCompanyAttribute = useCallback(
    async ({ name, value }: SetCompanyAttributeParams) => {
      await setCompanyAttributeMutation({
        variables: { companyId: currentCompanyId, name, value },
      });
    },
    [currentCompanyId, setCompanyAttributeMutation],
  );

  return useMemo(
    () => ({
      setCompanyAttribute,
      updatedCompany: data?.setCompanyAttribute,
      isLoading: loading,
      error,
    }),
    [data?.setCompanyAttribute, error, loading, setCompanyAttribute],
  );
}
