'use client';

import { memo } from 'react';

import type { CarrierVideoStreamQueryData } from '~/apollo/operations/queries/QueryCarrierVideoStream';
import type { Agent } from '~/types/agent';
import { STREAM_TYPE } from '~/types/videoStream';

import VideoStreamPlayerLowLatency from './components/VideoStreamPlayerLowLatency';
import VideoStreamPlayerRealtime from './components/VideoStreamPlayerRealtime';

interface Props {
  agent: Agent;
  videoStream: CarrierVideoStreamQueryData['carrier']['video_stream'] | undefined;
  isLoadingOrRunning: boolean;
  isStartOrRequestStartLoading: boolean;
  shouldPlayVideoFromAlarm: boolean;
}

const VideoStreamPlayerElement = memo(
  ({
    agent,
    videoStream,
    isLoadingOrRunning,
    isStartOrRequestStartLoading,
    shouldPlayVideoFromAlarm,
  }: Props) => {
    const shouldUseIvsRealtime = videoStream?.ivs_stream_type === STREAM_TYPE.IVS_REALTIME;

    return (
      <div>
        {shouldUseIvsRealtime ? (
          <VideoStreamPlayerRealtime agent={agent} videoStream={videoStream} />
        ) : (
          <VideoStreamPlayerLowLatency
            agent={agent}
            videoStream={videoStream}
            isLoadingOrRunning={isLoadingOrRunning}
            isStartOrRequestStartLoading={isStartOrRequestStartLoading}
            shouldPlayVideoFromAlarm={shouldPlayVideoFromAlarm}
          />
        )}
      </div>
    );
  },
);

VideoStreamPlayerElement.displayName = 'VideoStreamPlayerElement';

export default VideoStreamPlayerElement;
