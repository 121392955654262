'use client';

import { useMutation, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import MUTATION_CREATE_AGENT, {
  type CreateAgentCognitoMutationVariables,
  type CreateAgentCognitoMutationData,
} from '~/apollo/operations/mutations/MutationCreateAgentCognito';

export default function useMutationCreateAgentCognito(): {
  createAgentCognito: (params: {
    variables: CreateAgentCognitoMutationVariables;
  }) => Promise<unknown>;
  isLoading: boolean;
  error?: ApolloError;
  createdAgentCognito?: CreateAgentCognitoMutationData['createCognitoCarrier'];
} {
  const [createAgentCognito, { loading, error, data }] = useMutation<
    CreateAgentCognitoMutationData,
    CreateAgentCognitoMutationVariables
  >(MUTATION_CREATE_AGENT, {
    fetchPolicy: 'no-cache',
  });

  return useMemo(
    () => ({
      createAgentCognito,
      isLoading: loading,
      error,
      createdAgentCognito: data?.createCognitoCarrier,
    }),
    [createAgentCognito, data?.createCognitoCarrier, error, loading],
  );
}
