'use client';

import { memo, type ReactNode, type CSSProperties } from 'react';
import styled from 'styled-components';

const Div = styled.div`
  padding: 0 16px;
`;

interface Props {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const Container = memo(({ children, className, style }: Props) => (
  <Div className={className} style={style}>
    {children}
  </Div>
));

Container.displayName = 'Container';

export default Container;
