export enum ALARM_LEVEL {
  Critical = 'Critical',
  Warning = 'Warning',
  Information = 'Information',
}

export enum ALARM_SOURCE {
  Device = 'Device',
  External = 'External',
}

export enum ALARM_TYPE {
  emergency = 'emergency',
  fall = 'fall',
  attack = 'attack',
  traak_front = 'traak_front',
  traak_back = 'traak_back',
  stress = 'stress',
  abnormal_stops = 'abnormal_stops',
  long_stops = 'long_stops',
  gas_danger = 'gas_danger',
  gas_high = 'gas_high',
}

export enum ALARM_STATUS {
  Active = 'Active',
  Assigned = 'Assigned',
  Dismissed = 'Dismissed',
}

export enum ALARM_DISMISS_TYPE {
  Acknowledged = 'Acknowledged',
  Cancelled = 'Cancelled',
}

export interface Alarm {
  __typename?: 'Alarm';
  id: string;
  level: ALARM_LEVEL;
  source: ALARM_SOURCE;
  type: ALARM_TYPE;
  value: string | number;
  created_at: string;
  status: ALARM_STATUS;
  can_cancel: boolean;
  can_assign: boolean;
  assigned_at: string | null;
  administrator: {
    id: string;
    email: string;
    first_name: string | null;
    last_name: string | null;
  } | null;
  can_dismiss_dashboard: boolean;
  dismissed_at: string | null;
  dismiss_requested_at: string | null;
  dismiss_reason: ALARM_DISMISS_TYPE | string | null;
  dismiss_reason_details: string | null; // JSON
  dismissed_by: {
    id: string;
    email: string;
  } | null;
}

export type ExistingGas = 'ch4/hc' | 'co' | 'co2' | 'h2s' | 'o2';

export interface GasDangerAlarmValue {
  gas: ExistingGas;
  type: 3 | 4 | 5 | 6 | 7;
  // 3: Third Level Alarm
  // 5: Over Scale Alarm
  // 6: Time Weighted Average Alarm
  // 7: Short Term Exposure Limit Alarm
}

export interface GasHighAlarmValue {
  gas: ExistingGas;
  type: 1 | 2;
  // 1: First Level Warning
  // 2: Second Weighted Average Warning
}

export type GasAlarmValue = GasDangerAlarmValue | GasHighAlarmValue;

export interface AlarmWithCarrier extends Alarm {
  carrier: {
    id: string;
    nameWithAcronym: string;
  };
}
