'use client';

import { useMutation, useReactiveVar, type ApolloError } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import MUTATION_UPDATE_SUBSIDIARY, {
  type UpdateSubsidiaryMutationData,
  type UpdateSubsidiaryMutationVariables,
} from '~/apollo/operations/mutations/MutationUpdateSubsidiary';
import QUERY_COMPANY_DETAILS from '~/apollo/operations/queries/QueryCompanyDetails';
import { currentCompanyIdentifierVar } from '~/apollo/reactiveVariables/currentCompanyIdentifierVar';

export default function useMutationUpdateSubsidiary(): {
  updateSubsidiary: (params: UpdateSubsidiaryMutationVariables) => Promise<void>;
  updatedSubsidiary: UpdateSubsidiaryMutationData['updateSubsidiary'] | undefined;
  isLoading: boolean;
  error: ApolloError | undefined;
} {
  const companyId = useReactiveVar(currentCompanyIdentifierVar);

  const [updateSubsidiaryMutation, { data, loading, error }] = useMutation<
    UpdateSubsidiaryMutationData,
    UpdateSubsidiaryMutationVariables
  >(MUTATION_UPDATE_SUBSIDIARY, {
    refetchQueries: [
      { query: QUERY_COMPANY_DETAILS, variables: { companyID: companyId } },
      'QueryCompanyDetails',
    ],
  });

  const updateSubsidiary = useCallback(
    async ({ subsidiaryId, name, location }: UpdateSubsidiaryMutationVariables) => {
      await updateSubsidiaryMutation({
        variables: { subsidiaryId, name, location },
      });
    },
    [updateSubsidiaryMutation],
  );

  return useMemo(
    () => ({
      updateSubsidiary,
      updatedSubsidiary: data?.updateSubsidiary,
      isLoading: loading,
      error,
    }),
    [data?.updateSubsidiary, error, loading, updateSubsidiary],
  );
}
