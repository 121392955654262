'use client';

import { memo } from 'react';

import useCompany from '~/hooks/useCompany';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useSubsidiary from '~/hooks/useSubsidiary';

import QuickSightReports from './components/QuickSightReports';
import QuickSightReportsWithApi from './components/QuickSightReportsWithApi';

const ReportsPage = memo(() => {
  const { companyFeatures } = useCompanyFeatures();
  const { currentCompany } = useCompany();
  const { currentSubsidiary } = useSubsidiary();

  return (
    <QuickSightReportsWithApi>
      <QuickSightReports
        companyFeatures={companyFeatures}
        currentCompany={currentCompany}
        currentSubsidiary={currentSubsidiary}
      />
    </QuickSightReportsWithApi>
  );
});

ReportsPage.displayName = 'ReportsPage';

export default ReportsPage;
