'use client';

import { useCallback, useEffect, useMemo, useState } from 'react';

import type { GpsCoordinates } from '~/types/sensor';

export default function useReverseGeocoding(coords: GpsCoordinates | null | undefined): {
  address: string;
} {
  const geocoder = useMemo(() => window.google && new google.maps.Geocoder(), []);
  const [address, setAddress] = useState<string>('');

  const getAddress = useCallback(
    ({ lat, lng }: GpsCoordinates) =>
      geocoder
        ?.geocode({ location: { lat, lng } })
        .then((response: google.maps.GeocoderResponse) => {
          setAddress(response.results[0]?.formatted_address || '');
        })
        .catch(() => {
          setAddress('');
        }),
    [geocoder],
  );

  useEffect(() => {
    if (Number.isFinite(coords?.lat) && Number.isFinite(coords?.lng)) {
      getAddress({ lat: coords?.lat as number, lng: coords?.lng as number });
    }
  }, [getAddress, coords?.lat, coords?.lng]);

  return useMemo(() => ({ address }), [address]);
}
