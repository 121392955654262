import type { DeviceConfiguration } from '~/types/configurationDevice';
import { EQUIPMENT_STATUS, type EquipmentStatusMap } from '~/types/equipment';
import type { GpsCoordinates, GpsSensorType } from '~/types/sensor';

export const DEFAULT_GPS_POSITION: GpsCoordinates = Object.freeze({
  // Center of Switzerland
  lat: 46.897518,
  lng: 8.173022,
});

export const DEFAULT_GPS_SENSOR: GpsSensorType = Object.freeze({
  value: DEFAULT_GPS_POSITION,
  timestamp: new Date().toISOString(),
});

export const DEFAULT_MAP_ZOOM_LEVEL = 8; // Zoomed out to show all of Switzerland
export const DEFAULT_MAP_ZOOM_LEVEL_MIN = 3;
export const DEFAULT_MAP_ZOOM_LEVEL_MAX = 21;

export const CARRIER_MAP_ZOOM_LEVEL = 14;

export const DEFAULT_EQUIPMENT: EquipmentStatusMap = Object.freeze({
  connectionLost: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  offline: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  emergencyButton: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  heartRate: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  physiologicalTemperature: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  bodyTemperature: { status: EQUIPMENT_STATUS.no_sensor, healthy: false }, // deprecated
  gas: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  radiation: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  traakFront: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  traakBack: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  battery: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  lteSignalStrength: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
  oxygenSupply: { status: EQUIPMENT_STATUS.no_sensor, healthy: false },
});

export const DEFAULT_BASE_DEVICE_CONFIGURATION = {
  activated: true,
  embedded_sound: false,
  wbr_orientation: 'vertical_A',
  delay_bad_conn_sec: 10,
  delay_disconn_sec: 45,
  physio_pr: 10,
  env_pr: 10,
  gps_pr: 5,
  lte_debug_mode: false,
  sl: [
    { name: 'fall', value: true },
    { name: 'emergency', value: true },
    { name: 'traak_front', value: false },
    { name: 'traak_back', value: false },
    { name: 'external_temperature', value: false },
    { name: 'gps', value: true },
    { name: 'body_multi_sensor_v1', value: true },
    { name: 'heart_rate', value: true },
    { name: 'battery', value: true },
    { name: 'gas', value: false },
    { name: 'driving_type', value: true },
    { name: 'proximity_tag', value: true },
    { name: 'activity_level', value: true },
    { name: 'act_lvl_acc', value: true },
    { name: 'fall_debug', value: true },
    { name: 'lte_signal_strength', value: true },
    { name: 'rtc_info', value: true },
    { name: 'radiation', value: false },
  ],
} satisfies DeviceConfiguration;
