'use client';

import { Select } from 'antd';
import { useMemo, useCallback, type ReactNode, memo } from 'react';

import { CURRENT_SUBSIDIARY_IDENTIFIER_DEFAULT_VALUE } from '~/apollo/reactiveVariables/currentSubsidiaryIdentifierVar';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';

import NavbarSelect from './NavbarSelect';

const E2E_COMPANY_PREFIX = 'Dashboard E2E';

const NavbarSelectCompany = memo(() => {
  const { setCurrentCompanyId, currentCompany, companyList } = useCompany();
  const { setCurrentSubsidiaryId } = useSubsidiary();

  const sortedCompanies = useMemo(
    () =>
      // We have E2E companies at the top of the list so that we can select them in Cypress
      window.Cypress
        ? [
            ...companyList.filter((company) => company.name.startsWith(E2E_COMPANY_PREFIX)),
            ...companyList.filter((company) => !company.name.startsWith(E2E_COMPANY_PREFIX)),
          ]
        : companyList,
    [companyList],
  );

  const options: ReactNode[] = useMemo(
    () =>
      sortedCompanies.map((company) => (
        <Select.Option
          key={company.id}
          value={company.id}
          data-id={`company-select-option-${company.id}`}
        >
          {company.name}
        </Select.Option>
      )),
    [sortedCompanies],
  );

  const value: string = useMemo(
    () =>
      sortedCompanies.find((company) => company.id === currentCompany?.id)?.name ||
      i18n.t('footer.selectCompany'),
    [sortedCompanies, currentCompany?.id],
  );

  const handleChange = useCallback(
    (companyId: string) => {
      setCurrentCompanyId(companyId);
      setCurrentSubsidiaryId(CURRENT_SUBSIDIARY_IDENTIFIER_DEFAULT_VALUE);
    },
    [setCurrentCompanyId, setCurrentSubsidiaryId],
  );

  return (
    <NavbarSelect value={value} onChange={handleChange} data-id="company-select">
      {options}
    </NavbarSelect>
  );
});

NavbarSelectCompany.displayName = 'NavbarSelectCompany';

export default NavbarSelectCompany;
