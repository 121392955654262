'use client';

import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import Spin from 'antd/lib/spin';
import { memo, type CSSProperties } from 'react';
import styled from 'styled-components';

import theme from '~/theme';

const FullscreenSpin = styled(Spin)`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: ${theme.layers.loadingSpinner};
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  className?: string;
  style?: CSSProperties;
  /** @deprecated We should delete this prop and use a separate component instead */
  fullscreen?: boolean;
}

const LoadingSpinner = memo(({ fullscreen, className, style }: Props) => {
  const dataId = 'LoadingSpinner';

  return fullscreen ? (
    <FullscreenSpin
      indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
      className={className}
      style={style}
      data-id={dataId}
    />
  ) : (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      className={className}
      style={style}
      data-id={dataId}
    />
  );
});

LoadingSpinner.displayName = 'LoadingSpinner';

export default LoadingSpinner;
