'use client';

import { useQuery, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import QUERY_COMPANY_DETAILS, {
  type CompanyDetailsQueryData,
  type CompanyDetailsQueryVariables,
} from '~/apollo/operations/queries/QueryCompanyDetails';

export default function useQueryCompanyDetails({
  companyID,
  skip = false,
}: {
  companyID: string;
  skip?: boolean;
}): {
  companyDetails?: CompanyDetailsQueryData['company'];
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => void;
} {
  const { data, loading, error, refetch } = useQuery<
    CompanyDetailsQueryData,
    CompanyDetailsQueryVariables
  >(QUERY_COMPANY_DETAILS, {
    variables: { companyID },
    skip,
  });

  return useMemo(
    () => ({
      companyDetails: data?.company,
      isLoading: loading,
      error,
      refetch,
    }),
    [data?.company, error, loading, refetch],
  );
}
