'use client';

import { createContext, useContext, useMemo, useCallback, useState, type ReactNode } from 'react';

import AboutModal from '~/components/modals/AboutModal';
import AdminAddEditModal, {
  type AdminAddEditModalProps,
} from '~/components/modals/AdminAddEditModal';
import AgentAddEditModal, {
  type AgentAddEditModalProps,
} from '~/components/modals/AgentAddEditModal';
import AgentDebugModal, { type AgentDebugModalProps } from '~/components/modals/AgentDebugModal';
import AlarmModal, { type AlarmModalProps } from '~/components/modals/AlarmModal';
import ApiDocumentationModal from '~/components/modals/ApiDocumentationModal';
import BrainAssociateToAgentModal, {
  type BrainAssociateToAgentModalProps,
} from '~/components/modals/BrainAssociateToAgentModal';
import BrainConfigurationAddEditModal, {
  BrainConfigurationAddEditModalProps,
} from '~/components/modals/BrainConfigurationAddEditModal';
import CompanyAddEditModal, {
  type CompanyAddEditModalProps,
} from '~/components/modals/CompanyAddEditModal';
import ConfirmationModal, {
  type ConfirmationModalProps,
} from '~/components/modals/ConfirmationModal';
import EmergencyContactAddModal from '~/components/modals/EmergencyContactAddModal';
import EmergencyContactsOnCallModal from '~/components/modals/EmergencyContactsOnCallModal';
import HighlightedFeaturesModal from '~/components/modals/HighlightedFeaturesModal';
import InfoModal, { type InfoModalProps } from '~/components/modals/InfoModal';
import LocationHistoryModal, {
  type LocationHistoryModalProps,
} from '~/components/modals/LocationHistoryModal';
import SubsidiaryAddEditModal, {
  type SubsidiaryAddEditModalProps,
} from '~/components/modals/SubsidiaryAddEditModal';
import VideoRecordingsModal, {
  type VideoRecordingsModalProps,
} from '~/components/modals/VideoRecordingsModal';
import WelcomeBackModal from '~/components/modals/WelcomeBackModal';

type OpenModalParams =
  // Generic
  | ({
      type: 'confirmation';
    } & ConfirmationModalProps)
  | ({
      type: 'info';
    } & InfoModalProps)
  // Admin
  | ({
      type: 'adminAddEdit';
    } & AdminAddEditModalProps)
  // Agent
  | ({
      type: 'agentAddEdit';
    } & AgentAddEditModalProps)
  | ({
      type: 'agentDebug';
    } & AgentDebugModalProps)
  // Alarm
  | ({
      type: 'alarm';
    } & AlarmModalProps)
  // Emergency contacts
  | {
      type: 'emergencyContactAdd';
    }
  | {
      type: 'emergencyContactsOnCall';
    }
  // General specific
  | {
      type: 'welcomeBack';
    }
  | {
      type: 'about';
    }
  | {
      type: 'apiDocumentation';
    }
  | {
      type: 'highlightedFeatures';
    }
  | ({
      type: 'companyAddEdit';
    } & CompanyAddEditModalProps)
  | ({
      type: 'subsidiaryAddEdit';
    } & SubsidiaryAddEditModalProps)
  | ({
      type: 'brainConfigurationAddEdit';
    } & BrainConfigurationAddEditModalProps)
  | ({
      type: 'locationHistory';
    } & LocationHistoryModalProps)
  | ({
      type: 'videoRecordings';
    } & VideoRecordingsModalProps)
  | ({
      type: 'brainAssociateToAgent';
    } & BrainAssociateToAgentModalProps);

interface ModalsContextType {
  openModal: (params: OpenModalParams) => void;
  closeModals: () => void;
}

const ModalsContext = createContext<ModalsContextType>({
  openModal: () => undefined,
  closeModals: () => undefined,
});

ModalsContext.displayName = 'ModalsContext';

export function ModalsContextProvider({ children }: { children: ReactNode }) {
  const [activeModal, setActiveModal] = useState<OpenModalParams | null>(null);

  const openModal = useCallback(
    (params: OpenModalParams) => {
      setActiveModal(params);
    },
    [setActiveModal],
  );

  const closeModals = useCallback(() => {
    setActiveModal(null);
  }, [setActiveModal]);

  const maybeModal = useMemo(
    () =>
      (() => {
        switch (activeModal?.type) {
          // Generic
          case 'confirmation':
            return (
              <ConfirmationModal
                isOpen
                onClose={closeModals}
                title={activeModal?.title}
                description={activeModal?.description}
                actionType={activeModal?.actionType}
                action={activeModal?.action}
              />
            );
          case 'info':
            return (
              <InfoModal
                isOpen
                onClose={closeModals}
                title={activeModal?.title}
                content={activeModal?.content}
              />
            );
          // Admin
          case 'adminAddEdit':
            return (
              <AdminAddEditModal
                isOpen
                onClose={closeModals}
                initialValues={activeModal?.initialValues}
                refetchOnCompleted={activeModal?.refetchOnCompleted}
              />
            );
          // Agent
          case 'agentAddEdit':
            return (
              <AgentAddEditModal
                isOpen
                onClose={closeModals}
                agentId={activeModal?.agentId}
                refetchOnCompleted={activeModal?.refetchOnCompleted}
              />
            );
          case 'agentDebug':
            return <AgentDebugModal isOpen onClose={closeModals} agentId={activeModal?.agentId} />;
          // Alarm
          case 'alarm':
            return (
              <AlarmModal
                isOpen
                onClose={closeModals}
                defaultTab={activeModal?.defaultTab}
                agentId={activeModal?.agentId}
                alarmId={activeModal?.alarmId}
              />
            );
          // Emergency contacts
          case 'emergencyContactAdd':
            return <EmergencyContactAddModal isOpen onClose={closeModals} />;
          case 'emergencyContactsOnCall':
            return <EmergencyContactsOnCallModal isOpen onClose={closeModals} />;
          // General specific
          case 'welcomeBack':
            return <WelcomeBackModal isOpen onClose={closeModals} />;
          case 'about':
            return <AboutModal isOpen onClose={closeModals} />;
          case 'apiDocumentation':
            return <ApiDocumentationModal isOpen onClose={closeModals} />;
          case 'highlightedFeatures':
            return <HighlightedFeaturesModal isOpen onClose={closeModals} />;
          case 'companyAddEdit':
            return (
              <CompanyAddEditModal
                isOpen
                onClose={closeModals}
                companyId={activeModal?.companyId}
                refetchOnCompleted={activeModal?.refetchOnCompleted}
              />
            );
          case 'subsidiaryAddEdit':
            return (
              <SubsidiaryAddEditModal
                isOpen
                onClose={closeModals}
                subsidiaryId={activeModal?.subsidiaryId}
                refetchOnCompleted={activeModal?.refetchOnCompleted}
              />
            );
          case 'brainConfigurationAddEdit':
            return (
              <BrainConfigurationAddEditModal
                isOpen
                onClose={closeModals}
                isEdit={activeModal?.isEdit}
                itemScope={activeModal?.itemScope}
                itemId={activeModal?.itemId}
                isBackofficePage={activeModal?.isBackofficePage}
              />
            );
          case 'locationHistory':
            return (
              <LocationHistoryModal isOpen onClose={closeModals} agentId={activeModal?.agentId} />
            );
          case 'videoRecordings':
            return (
              <VideoRecordingsModal
                isOpen
                onClose={closeModals}
                defaultAgentId={activeModal?.defaultAgentId}
                initialDateRange={activeModal?.initialDateRange}
              />
            );
          case 'brainAssociateToAgent':
            return (
              <BrainAssociateToAgentModal
                isOpen
                onClose={closeModals}
                allBrains={activeModal?.allBrains}
                initialValues={activeModal?.initialValues}
                refetchOnCompleted={activeModal?.refetchOnCompleted}
              />
            );
          default:
            return null;
        }
      })(),
    [activeModal, closeModals],
  );

  const value: ModalsContextType = useMemo(
    () => ({ openModal, closeModals }),
    [openModal, closeModals],
  );

  return (
    <ModalsContext.Provider value={value}>
      {maybeModal}
      {children}
    </ModalsContext.Provider>
  );
}

export default function useModalsContext() {
  return useContext(ModalsContext);
}
