'use client';

import { makeVar } from '@apollo/client';

interface NewAlert {
  carrierId?: string;
}

export const NEW_ALERT_DEFAULT_VALUE: NewAlert = {};

export const newAlertVar = makeVar<NewAlert>(NEW_ALERT_DEFAULT_VALUE);
