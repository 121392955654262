import { EQUIPMENT_STATUS, type EquipmentStatusDetails } from '~/types/equipment';

interface Params {
  status: EQUIPMENT_STATUS | undefined;
  connectionLost: boolean;
  isOffline: boolean;
}

function isEquipmentHealthy({ status, connectionLost, isOffline }: Params): boolean {
  if (connectionLost || isOffline) {
    return false;
  }
  switch (status) {
    case EQUIPMENT_STATUS.no_error:
      return true;
    case EQUIPMENT_STATUS.no_sensor:
    case EQUIPMENT_STATUS.phone_ble_disabled:
    case EQUIPMENT_STATUS.ble_disabled:
    case EQUIPMENT_STATUS.sensor_connecting:
    case EQUIPMENT_STATUS.sensor_disconnected:
    case EQUIPMENT_STATUS.sensor_error:
    case EQUIPMENT_STATUS.sensor_inactive:
    case EQUIPMENT_STATUS.sensor_unpaired:
    case EQUIPMENT_STATUS.sensor_data_error:
    default:
      return false;
  }
}

export default function getEquipmentStatusDetails({
  status,
  connectionLost,
  isOffline,
}: Params): EquipmentStatusDetails {
  return {
    status: status || EQUIPMENT_STATUS.no_sensor,
    healthy: isEquipmentHealthy({ status, connectionLost, isOffline }),
  };
}
