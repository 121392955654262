'use client';

import { ReactNode, memo } from 'react';
import styled from 'styled-components';

import useModalsContext from '~/context/useModalsContext';

const Button = styled.button`
  margin: 0;
  background: rgba(0, 0, 0, 0.05);
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const SubtitleH3 = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
`;

const ListUl = styled.ul`
  margin-top: 1rem;
  margin-bottom: 0;
  padding-inline-start: 30px;
`;

const ListLi = styled.li``;

interface Props {
  buttonText: string;
  modalTitle: string;
  modalSubtitle: ReactNode;
  modalList: string[];
}

const TableInfoListButton = memo(({ buttonText, modalTitle, modalSubtitle, modalList }: Props) => {
  const { openModal } = useModalsContext();

  return (
    <Button
      onClick={() => {
        openModal({
          type: 'info',
          title: modalTitle,
          content: (
            <>
              <SubtitleH3>{modalSubtitle}</SubtitleH3>
              {modalList.length > 0 && (
                <ListUl>
                  {modalList.map((item) => (
                    <ListLi key={item}>{item}</ListLi>
                  ))}
                </ListUl>
              )}
            </>
          ),
        });
      }}
    >
      {buttonText}
    </Button>
  );
});

TableInfoListButton.displayName = 'TableInfoListButton';

export default TableInfoListButton;
