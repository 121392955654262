'use client';

import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import SoundOutlined from '@ant-design/icons/SoundOutlined';
import { Modal, Tabs, ConfigProvider } from 'antd';
import { memo, useState, useEffect, useMemo, type ReactNode } from 'react';

import PinPointAlarmMap from '~/components/maps/PinPointAlarmMap';
import TimeAgo from '~/components/TimeAgo';
import useAgentsContext from '~/context/useAgentsContext';
import useAlarmsContext from '~/context/useAlarmsContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { ModalProps } from '~/types/modal';
import isAlarmOngoing from '~/utils/alarm/isAlarmOngoing';

import AlarmDetailsTab from './components/AlarmDetailsTab';
import AlarmManageTab from './components/AlarmManageTab';

type AlarmModalTab = 'manage' | 'details';

export interface AlarmModalProps {
  defaultTab: AlarmModalTab;
  agentId: string | undefined;
  alarmId: string | undefined;
}

const AlarmModal = memo(
  ({ isOpen, onClose, agentId, alarmId, defaultTab }: ModalProps & AlarmModalProps) => {
    const { agents } = useAgentsContext();
    const { alarms } = useAlarmsContext();

    const agent = useMemo(() => agents.find((a) => a.id === agentId), [agents, agentId]);
    const alarm = useMemo(() => alarms.find((a) => a.id === alarmId), [alarms, alarmId]);

    const isOngoing = isAlarmOngoing(alarm);

    const [activeTab, setActiveTab] = useState<AlarmModalTab>(isOngoing ? defaultTab : 'details');

    useEffect(() => {
      if (!isOngoing) {
        setActiveTab('details');
      }
    }, [isOngoing]);

    const tabItems: {
      key: string | AlarmModalTab;
      label: string;
      children: null;
      icon: ReactNode;
    }[] = useMemo(
      () => [
        ...(isOngoing
          ? [
              {
                key: 'manage',
                label: i18n.t('carrierDetailsPopup.manageAlarm'),
                children: null,
                icon: <SoundOutlined style={{ transform: 'translateY(1px)' }} />,
              },
            ]
          : []),
        {
          key: 'details',
          label: i18n.t('common.details'),
          children: null,
          icon: <FileTextOutlined style={{ transform: 'translateY(1px)' }} />,
        },
      ],
      [isOngoing],
    );

    return (
      <Modal
        title={
          <>
            {i18n.t('common.alarm')}{' '}
            <>
              (<TimeAgo date={alarm?.created_at || new Date()} />)
            </>
          </>
        }
        style={{ position: 'relative' }}
        width={800}
        centered
        open={isOpen}
        onCancel={onClose}
        footer={null}
      >
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: theme.colors.black,
            },
          }}
        >
          <Tabs
            size="small"
            activeKey={activeTab}
            items={tabItems}
            onChange={(key: string) => {
              setActiveTab(key as AlarmModalTab);
            }}
          />
        </ConfigProvider>
        <PinPointAlarmMap alarm={alarm} />
        {activeTab === 'manage' && <AlarmManageTab agent={agent} alarm={alarm} onClose={onClose} />}
        {activeTab === 'details' && (
          <AlarmDetailsTab
            alarm={alarm}
            openAlarmManageTab={() => {
              setActiveTab('manage');
            }}
          />
        )}
      </Modal>
    );
  },
);

AlarmModal.displayName = 'AlarmModal';

export default AlarmModal;
