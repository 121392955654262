'use client';

import { Image as AntdImage, type ImageProps } from 'antd';
import { memo, type CSSProperties } from 'react';
import styled from 'styled-components';

const StyledAntdImage = styled(AntdImage)`
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
`;

interface Props {
  src?: string;
  width?: ImageProps['width'];
  preview?: ImageProps['preview'];
  className?: string;
  style?: CSSProperties;
  'data-id'?: string;
}

const Image = memo(
  ({ src, width, preview = false, className, style, 'data-id': dataId }: Props) => (
    <StyledAntdImage
      src={src}
      width={width}
      preview={preview}
      className={className}
      style={style}
      data-id={dataId}
    />
  ),
);

Image.displayName = 'Image';

export default Image;
