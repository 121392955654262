'use client';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import { Table, Tooltip, Button, Tag, type TableColumnsType } from 'antd';
import { memo, useCallback, useMemo, type Key, type ReactNode } from 'react';
import styled from 'styled-components';

import useMutationDeleteAgent from '~/apollo/hooks/agent/useMutationDeleteAgent';
import useQueryAgentListWithDetails from '~/apollo/hooks/agent/useQueryAgentListWithDetails';
import SettingsSelectCompany from '~/components/settings/components/SettingsSelectCompany';
import SettingsSelectSubsidiary from '~/components/settings/components/SettingsSelectSubsidiary';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import {
  SelectCompanySubsidiaryDiv,
  SettingsButtonsFlexDiv,
  SettingsTopFlexDiv,
} from '~/components/settings/shared';
import TimeAgo from '~/components/TimeAgo';
import useAgentsContext from '~/context/useAgentsContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { formatDateTime } from '~/utils/dateTime';
import notification from '~/utils/notification';
import formatPhoneNumber from '~/utils/phoneNumber/formatPhoneNumber';

const WrapperDiv = styled.div`
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

const StyledTable = styled(Table)`
  overflow-x: auto;
  border: none;
  margin-bottom: 16px;

  td {
    background: #ffffff;
  }
`;

const ActionsDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

interface TableDataType {
  key: Key;
  nameAndAcronym: string;
  code: string;
  wearinApp: 'YES' | 'NO';
  brainId: string;
  email: string;
  acronym: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  team: string;
  createdAt: string;
  actions: ReactNode;
}

const columns: TableColumnsType<TableDataType> = [
  {
    dataIndex: 'nameAndAcronym',
    title: i18n.t('common.agent'),
    sorter: (a, b) => a.code.toLowerCase().localeCompare(b.code.toLowerCase()),
    defaultSortOrder: 'ascend',
    render: (text) => <b>{text}</b>,
  },
  {
    dataIndex: 'code',
    title: i18n.t('common.code'),
    sorter: (a, b) => a.code.toLowerCase().localeCompare(b.code.toLowerCase()),
    render: (text) => <Tag color={theme.colors.primaryBlue}>{text}</Tag>,
  },
  {
    dataIndex: 'email',
    title: i18n.t('common.email'),
    sorter: (a, b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()),
    render: (text) => text,
  },
  {
    dataIndex: 'brainId',
    title: i18n.t('common.brain'),
    sorter: (a, b) => a.brainId.toLowerCase().localeCompare(b.brainId.toLowerCase()),
    render: (text) => (
      <Tooltip
        title={
          text ? (
            <>
              <b>{i18n.t('common.brainId')}:</b>
              <br />
              {text}
            </>
          ) : undefined
        }
        placement="top"
      >
        <Tag style={{ cursor: text ? 'help' : 'default' }} color={text ? 'default' : 'error'}>
          {text ? i18n.t('common.yes') : i18n.t('common.no')}
        </Tag>
      </Tooltip>
    ),
  },
  {
    dataIndex: 'wearinApp',
    title: i18n.t('companySettingsPage.agentsTab.wearinApp'),
    sorter: (a, b) => a.wearinApp.toLowerCase().localeCompare(b.wearinApp.toLowerCase()),
    render: (text) => (
      <Tag color={text === 'YES' ? 'blue' : 'default'}>
        {text === 'YES' ? i18n.t('common.yes') : i18n.t('common.no')}
      </Tag>
    ),
  },
  {
    dataIndex: 'team',
    title: i18n.t('common.team'),
    sorter: (a, b) => a.team.toLowerCase().localeCompare(b.team.toLowerCase()),
    render: (text) => text,
  },
  {
    dataIndex: 'phoneNumber',
    title: i18n.t('common.phoneNumber'),
    sorter: (a, b) => a.phoneNumber.toLowerCase().localeCompare(b.phoneNumber.toLowerCase()),
    render: (text) => formatPhoneNumber(text) || text,
  },
  {
    dataIndex: 'createdAt',
    title: i18n.t('common.created'),
    sorter: (a, b) => a.createdAt.toLowerCase().localeCompare(b.createdAt.toLowerCase()),
    render: (text) => (
      <Tooltip title={formatDateTime(text)}>
        <span style={{ cursor: 'help' }}>
          <TimeAgo date={text} />
        </span>
      </Tooltip>
    ),
  },
  {
    dataIndex: 'actions',
    title: i18n.t('common.actions'),
    fixed: 'right',
  },
];

interface Props {
  isBackofficePage: boolean;
}

const TabAgents = memo(({ isBackofficePage }: Props) => {
  const { refetchAgentsContext } = useAgentsContext();
  const { companyFeatures } = useCompanyFeatures();
  const { isLoading: isLoadingCurrentUser, isSuperAdmin, isCompanyAdmin } = useCurrentUserContext();
  const { openModal } = useModalsContext();
  const { currentSubsidiary } = useSubsidiary();

  const isSuperOrCompanyAdmin = isSuperAdmin || isCompanyAdmin;

  const {
    agents,
    refetchAgentsWithDetails,
    isLoading: isQueryAgentsWithDetailsLoading,
  } = useQueryAgentListWithDetails({ subsidiaryId: currentSubsidiary?.id, nextToken: null });

  const { deleteAgent, loading: isDeleteAgentLoading } = useMutationDeleteAgent();

  const isLoading = isLoadingCurrentUser || isQueryAgentsWithDetailsLoading || isDeleteAgentLoading;

  const totalRefetch = useCallback(async () => {
    await Promise.all([refetchAgentsWithDetails(), refetchAgentsContext()]);
  }, [refetchAgentsWithDetails, refetchAgentsContext]);

  const data: TableDataType[] = useMemo(
    () =>
      agents.map((agent) => {
        const firstName = agent.attributes.find((a) => a.name === 'first_name')?.value?.trim();
        const lastName = agent.attributes.find((a) => a.name === 'last_name')?.value?.trim();
        const acronym = agent.attributes.find((a) => a.name === 'acronym')?.value?.trim();

        const title = [firstName, lastName].filter(Boolean).join(' ') || agent.name;

        return {
          nameAndAcronym:
            [title, acronym ? `(${acronym})` : undefined].filter(Boolean).join(' ') ||
            agent.name ||
            '-',
          key: agent.id,
          code: agent.name,
          wearinApp: agent.__typename === 'Carrier_Cognito' ? 'YES' : 'NO',
          brainId: agent.device?.name || '',
          email: agent.email || '-',
          acronym: acronym || '-',
          firstName: firstName || '-',
          lastName: lastName || '-',
          phoneNumber:
            agent.attributes.find((a) => a.name === 'phone_number')?.value?.trim() || '-',
          team: agent.attributes.find((a) => a.name === 'team')?.value?.trim() || '-',
          createdAt: agent.created_at,
          actions: (
            <ActionsDiv>
              <Tooltip title={i18n.t('companySettingsPage.agentsTab.editAgent')}>
                <Button
                  onClick={() => {
                    openModal({
                      type: 'agentAddEdit',
                      agentId: agent.id,
                      refetchOnCompleted: totalRefetch,
                    });
                  }}
                  icon={<EditOutlined />}
                  data-id={`edit-agent-btn-${agent.email || agent.id}`}
                />
              </Tooltip>
              {(companyFeatures.deleteAgents || isBackofficePage) && (
                <Tooltip title={i18n.t('companySettingsPage.agentsTab.deleteAgent')}>
                  <Button
                    data-id={`delete-agent-btn-${agent.email || agent.id}`}
                    icon={<DeleteOutlined />}
                    danger
                    disabled={isLoading}
                    onClick={() => {
                      openModal({
                        type: 'confirmation',
                        title: i18n.t('companySettingsPage.agentsTab.deleteAgent'),
                        description: (
                          <div
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: i18n.t<string>(
                                'companySettingsPage.agentsTab.areYouSureYouWantToDeleteAgentHtml',
                                {
                                  email: agent.email || agent.name,
                                },
                              ),
                            }}
                          />
                        ),
                        actionType: 'danger',
                        action: async () => {
                          try {
                            await deleteAgent({
                              variables: {
                                agentId: agent.id,
                              },
                            });
                            await totalRefetch();
                            notification.success({
                              message: i18n.t('common.success'),
                              description: i18n.t(
                                'companySettingsPage.agentsTab.agentDeletedSuccess',
                              ),
                            });
                          } catch (error) {
                            notification.error({
                              message: (error as any)?.message || i18n.t('common.error'),
                            });
                          }
                        },
                      });
                    }}
                  />
                </Tooltip>
              )}
            </ActionsDiv>
          ),
        };
      }),
    [
      agents,
      companyFeatures.deleteAgents,
      isBackofficePage,
      isLoading,
      openModal,
      totalRefetch,
      deleteAgent,
    ],
  );

  return (
    <WrapperDiv>
      <SelectCompanySubsidiaryDiv>
        {isBackofficePage && (
          <div>
            <TabSectionHeader title={i18n.t('companyFeatures.selectedCompany')} />
            <SettingsSelectCompany isLoading={isLoading} />
          </div>
        )}
        {isSuperOrCompanyAdmin && (
          <div>
            <TabSectionHeader title={i18n.t('companyFeatures.selectedSubsidiary')} />
            <SettingsSelectSubsidiary isLoading={isLoading} />
          </div>
        )}
      </SelectCompanySubsidiaryDiv>
      <SettingsTopFlexDiv>
        <TabSectionHeader
          title={
            isLoading
              ? i18n.t('common.loading')
              : `${i18n.t('companySettingsPage.agentsTab.subsidiaryAgents')} (${agents.length})`
          }
        />
        <SettingsButtonsFlexDiv>
          <Button
            loading={isLoading}
            icon={<ReloadOutlined />}
            onClick={async () => {
              await totalRefetch();
            }}
          >
            {i18n.t('common.refresh')}
          </Button>
          <Button
            loading={isLoading}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              openModal({
                type: 'agentAddEdit',
                agentId: undefined,
                refetchOnCompleted: totalRefetch,
              });
            }}
            data-id={isLoading ? undefined : 'add-agent-btn'}
          >
            {i18n.t('companySettingsPage.agentsTab.createAgent')}
          </Button>
        </SettingsButtonsFlexDiv>
      </SettingsTopFlexDiv>
      <StyledTable
        tableLayout="auto"
        loading={isLoading}
        columns={columns}
        dataSource={data}
        sortDirections={['ascend', 'descend', 'ascend']}
        pagination={{
          defaultPageSize: 1000,
          showSizeChanger: false,
          pageSizeOptions: undefined,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} agents`,
          hideOnSinglePage: true,
        }}
      />
    </WrapperDiv>
  );
});

export default TabAgents;
