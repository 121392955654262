'use client';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { Table, Tooltip, Button, type TableColumnsType, Tag, Switch, Alert } from 'antd';
import { memo, useMemo, type Key, type ReactNode } from 'react';
import styled from 'styled-components';

import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import { SelectCompanySubsidiaryDiv, SettingsTopFlexDiv } from '~/components/settings/shared';
import TimeAgo from '~/components/TimeAgo';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompanyEmergencyContacts from '~/hooks/useCompanyEmergencyContacts';
import i18n from '~/locales/i18n';
import { formatDateTime } from '~/utils/dateTime';
import notification from '~/utils/notification';
import formatPhoneNumber from '~/utils/phoneNumber/formatPhoneNumber';

import OnlyCompanyAdminsAlert from '../OnlyCompanyAdminsAlert';

const AlertsFlexDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 8px;
`;

const StyledTable = styled(Table)`
  overflow-x: auto;
  border: none;
  margin-bottom: 16px;

  td {
    background: #ffffff;
  }
`;

const ActionsDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

interface TableDataType {
  key: Key;
  isActive: boolean;
  name: string;
  phone: string;
  createdBy: string;
  createdAt: string;
  actions: ReactNode;
}

const TabEmergencyContacts = memo(() => {
  const { isLoading: isLoadingCurrentUser, isSuperAdmin, isCompanyAdmin } = useCurrentUserContext();
  const { openModal } = useModalsContext();
  const {
    onCallPhoneNumbers,
    setOnCallPhoneNumbers,
    emergencyContactsList,
    setEmergencyContactsList,
    isLoading: isLoadingEmergencyContacts,
  } = useCompanyEmergencyContacts();

  const isLoading = isLoadingCurrentUser || isLoadingEmergencyContacts;

  const isDisabled = !isSuperAdmin && !isCompanyAdmin;

  const columns: TableColumnsType<TableDataType> = useMemo(
    () => [
      {
        dataIndex: 'isActive',
        title: i18n.t('companySettingsPage.emergencyContactsTab.onCall'),
        width: 125,
        sorter: (a, b) => Number(a.isActive) - Number(b.isActive),
        render: (isActive, record) => {
          const switchTooltip = isActive ? i18n.t('common.deactivate') : i18n.t('common.activate');

          return (
            <Tooltip title={isDisabled ? undefined : switchTooltip}>
              <Switch
                disabled={isDisabled}
                checked={isActive}
                onChange={async (checked) => {
                  if (checked) {
                    if (onCallPhoneNumbers.length >= 5) {
                      notification.warning({
                        message: i18n.t(
                          'companySettingsPage.emergencyContactsTab.maxActiveContacts',
                        ),
                      });
                      return;
                    }

                    await setOnCallPhoneNumbers([...onCallPhoneNumbers, record.phone]);
                  } else {
                    await setOnCallPhoneNumbers(
                      onCallPhoneNumbers.filter((phone) => phone !== record.phone),
                    );
                  }
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        dataIndex: 'name',
        title: i18n.t('common.name'),
        sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        render: (text) => text,
      },
      {
        dataIndex: 'phone',
        title: i18n.t('common.phoneNumber'),
        sorter: (a, b) => a.phone.toLowerCase().localeCompare(b.phone.toLowerCase()),
        render: (text) => {
          const isActive = onCallPhoneNumbers.some((phoneNumber) => phoneNumber === text);

          return (
            <>
              {formatPhoneNumber(text)}{' '}
              {isActive && (
                <Tag style={{ marginLeft: '0.5rem' }} color="green">
                  {i18n.t('companySettingsPage.emergencyContactsTab.onCall').toLowerCase()}
                </Tag>
              )}
            </>
          );
        },
      },
      {
        dataIndex: 'createdBy',
        title: i18n.t('common.createdBy'),
        sorter: (a, b) => a.createdBy.toLowerCase().localeCompare(b.createdBy.toLowerCase()),
        render: (text) => text,
      },
      {
        dataIndex: 'createdAt',
        title: i18n.t('common.created'),
        sorter: (a, b) => a.createdAt.toLowerCase().localeCompare(b.createdAt.toLowerCase()),
        defaultSortOrder: 'descend',
        render: (text) => (
          <Tooltip title={formatDateTime(text)}>
            <span style={{ cursor: 'help' }}>
              <TimeAgo date={text} />
            </span>
          </Tooltip>
        ),
      },
      {
        dataIndex: 'actions',
        title: i18n.t('common.actions'),
        fixed: 'right',
      },
    ],
    [isDisabled, onCallPhoneNumbers, setOnCallPhoneNumbers],
  );

  const data: TableDataType[] = useMemo(
    () =>
      emergencyContactsList.map((contact) => {
        const isActive = onCallPhoneNumbers.some((phoneNumber) => phoneNumber === contact.phone);
        const deleteTooltip = isActive
          ? i18n.t('companySettingsPage.emergencyContactsTab.cannotDeleteOnCallContact')
          : i18n.t('companySettingsPage.emergencyContactsTab.deleteContact');

        return {
          key: JSON.stringify(contact),
          isActive,
          name: contact.name,
          phone: contact.phone,
          createdBy: contact.createdBy,
          createdAt: contact.createdAt,
          actions: (
            <ActionsDiv>
              <Tooltip title={isDisabled ? undefined : deleteTooltip}>
                <Button
                  disabled={isActive || isDisabled}
                  danger
                  onClick={async () => {
                    openModal({
                      type: 'confirmation',
                      title: i18n.t('companySettingsPage.emergencyContactsTab.deleteContact'),
                      description: (
                        <div
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: i18n.t<string>(
                              'companySettingsPage.emergencyContactsTab.areYouSureYouWantToDeleteContactHtml',
                              {
                                phoneNumber: `${contact.name} (${formatPhoneNumber(contact.phone)})`,
                              },
                            ),
                          }}
                        />
                      ),
                      actionType: 'danger',
                      action: async () => {
                        try {
                          await setEmergencyContactsList(
                            emergencyContactsList.filter((c) => c !== contact),
                          );
                          notification.success({
                            message: i18n.t('common.success'),
                            description: i18n.t(
                              'companySettingsPage.emergencyContactsTab.contactDeletedSuccess',
                            ),
                          });
                        } catch (error) {
                          notification.error({
                            message: (error as any)?.message || i18n.t('common.error'),
                          });
                        }
                      },
                    });
                  }}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </ActionsDiv>
          ),
        };
      }),
    [emergencyContactsList, isDisabled, onCallPhoneNumbers, openModal, setEmergencyContactsList],
  );

  return (
    <>
      <AlertsFlexDiv>
        <SelectCompanySubsidiaryDiv />
        <OnlyCompanyAdminsAlert />
        {!isDisabled && onCallPhoneNumbers.length === 0 && (
          <Alert
            message={i18n.t(
              'companySettingsPage.emergencyContactsTab.missingActiveOnCallContactsWarningDescription',
            )}
            type="warning"
            showIcon
          />
        )}
      </AlertsFlexDiv>
      <SettingsTopFlexDiv>
        <TabSectionHeader
          title={
            isLoading
              ? i18n.t('common.loading')
              : `${i18n.t('companySettingsPage.emergencyContactsTab.listOfContacts')} (${emergencyContactsList.length})`
          }
        />
        <Button
          disabled={isDisabled}
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            openModal({
              type: 'emergencyContactAdd',
            });
          }}
        >
          {i18n.t('companySettingsPage.emergencyContactsTab.addContact')}
        </Button>
      </SettingsTopFlexDiv>
      <StyledTable
        tableLayout="auto"
        loading={isLoading}
        columns={columns}
        dataSource={data}
        sortDirections={['ascend', 'descend', 'ascend']}
        pagination={{
          defaultPageSize: 1000,
          showSizeChanger: false,
          pageSizeOptions: undefined,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} subsidiaries`,
          hideOnSinglePage: true,
        }}
      />
    </>
  );
});

TabEmergencyContacts.displayName = 'TabEmergencyContacts';

export default TabEmergencyContacts;
