'use client';

import { memo, useEffect, useState } from 'react';

import useAuthenticationContext from '~/context/useAuthenticationContext';

import DeveloperConsoleApp from './components/DeveloperConsoleApp';

const DeveloperConsole = memo(() => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isAuthenticated } = useAuthenticationContext();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && ['o', 'O'].includes(event.key)) {
        setIsOpen(true);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (!isOpen || !isAuthenticated) {
    return null;
  }

  return (
    <DeveloperConsoleApp
      onClose={() => {
        setIsOpen(false);
      }}
    />
  );
});

DeveloperConsole.displayName = 'DeveloperConsole';

export default DeveloperConsole;
