'use client';

import { useReactiveVar } from '@apollo/client';
import { createContext, useCallback, useContext, useMemo, type ReactNode } from 'react';

import useQueryDeviceConfigurationOverrideForCompany from '~/apollo/hooks/configurationDevice/useQueryDeviceConfigurationOverrideForCompany';
import useQueryDeviceConfigurationOverrideForSubsidiary from '~/apollo/hooks/configurationDevice/useQueryDeviceConfigurationOverrideForSubsidiary';
import useQueryDeviceConfigurationOverridesForAgents from '~/apollo/hooks/configurationDevice/useQueryDeviceConfigurationOverridesForAgents';
import useQueryDeviceConfigurations from '~/apollo/hooks/configurationDevice/useQueryDeviceConfigurations';
import { currentCompanyIdentifierVar } from '~/apollo/reactiveVariables/currentCompanyIdentifierVar';
import { currentSubsidiaryIdentifierVar } from '~/apollo/reactiveVariables/currentSubsidiaryIdentifierVar';
import type { DeviceConfiguration } from '~/types/configurationDevice';

import useCurrentUserContext from './useCurrentUserContext';

interface BrainConfigurationsContextType {
  brainConfigurations: DeviceConfiguration[];
  isLoading: boolean;
  refetchBrainConfigurations: () => Promise<void>;
}

const BrainConfigurationsContext = createContext<BrainConfigurationsContextType>({
  brainConfigurations: [],
  isLoading: true,
  refetchBrainConfigurations: async () => undefined,
});

BrainConfigurationsContext.displayName = 'BrainConfigurationsContext';

export function BrainConfigurationsContextProvider({ children }: { children: ReactNode }) {
  const { isLoading: isCurrentUserContextLoading, isSubsidiaryAdmin } = useCurrentUserContext();
  const currentCompanyIdentifier = useReactiveVar(currentCompanyIdentifierVar);
  const currentSubsidiaryIdentifier = useReactiveVar(currentSubsidiaryIdentifierVar);

  const skip = isCurrentUserContextLoading;

  // Global
  const {
    deviceConfigurations,
    isLoading: isQueryDeviceConfigurationsLoading,
    refetch: refetchQueryDeviceConfigurations,
  } = useQueryDeviceConfigurations({
    skip,
  });

  // Company (Override)
  const {
    deviceConfigurationOverrideForCompany,
    isLoading: isQueryDeviceConfigurationOverrideForCompanyLoading,
    refetch: refetchQueryDeviceConfigurationOverrideForCompany,
  } = useQueryDeviceConfigurationOverrideForCompany({
    companyId: currentCompanyIdentifier,
    skip: skip || isSubsidiaryAdmin,
  });

  // Subsidiary (Override)
  const {
    deviceConfigurationOverrideForSubsidiary,
    isLoading: isQueryDeviceConfigurationOverrideForSubsidiaryLoading,
    refetch: refetchQueryDeviceConfigurationOverrideForSubsidiary,
  } = useQueryDeviceConfigurationOverrideForSubsidiary({
    subsidiaryId: currentSubsidiaryIdentifier,
    skip,
  });

  // Agents (Overrides)
  const {
    deviceConfigurationOverridesForAgents,
    isLoading: isQueryDeviceConfigurationOverridesForAgentsLoading,
    refetch: refetchQueryDeviceConfigurationOverridesForAgents,
  } = useQueryDeviceConfigurationOverridesForAgents({
    subsidiaryId: currentSubsidiaryIdentifier,
    skip,
  });

  const refetchBrainConfigurations = useCallback(async () => {
    await Promise.all([
      refetchQueryDeviceConfigurations(),
      refetchQueryDeviceConfigurationOverrideForCompany(),
      refetchQueryDeviceConfigurationOverrideForSubsidiary(),
      refetchQueryDeviceConfigurationOverridesForAgents(),
    ]);
  }, [
    refetchQueryDeviceConfigurations,
    refetchQueryDeviceConfigurationOverrideForCompany,
    refetchQueryDeviceConfigurationOverrideForSubsidiary,
    refetchQueryDeviceConfigurationOverridesForAgents,
  ]);

  const brainConfigurations = useMemo(() => {
    if (!currentCompanyIdentifier || !currentSubsidiaryIdentifier) {
      return [];
    }
    return [
      ...deviceConfigurations.filter((item) => item.name === 'base'),
      deviceConfigurationOverrideForCompany,
      deviceConfigurationOverrideForSubsidiary,
      ...deviceConfigurationOverridesForAgents,
    ].filter((item) => !!item);
  }, [
    currentCompanyIdentifier,
    currentSubsidiaryIdentifier,
    deviceConfigurations,
    deviceConfigurationOverrideForCompany,
    deviceConfigurationOverrideForSubsidiary,
    deviceConfigurationOverridesForAgents,
  ]);

  const isLoading =
    isQueryDeviceConfigurationsLoading ||
    isQueryDeviceConfigurationOverrideForCompanyLoading ||
    isQueryDeviceConfigurationOverrideForSubsidiaryLoading ||
    isQueryDeviceConfigurationOverridesForAgentsLoading;

  const value: BrainConfigurationsContextType = useMemo(
    () => ({
      brainConfigurations,
      isLoading,
      refetchBrainConfigurations,
    }),
    [brainConfigurations, isLoading, refetchBrainConfigurations],
  );

  return (
    <BrainConfigurationsContext.Provider value={value}>
      {children}
    </BrainConfigurationsContext.Provider>
  );
}

export default function useBrainConfigurationsContext() {
  return useContext(BrainConfigurationsContext);
}
