import { gql } from '@apollo/client';

import {
  MERGED_ALARM_CONFIGURATION_QUERY_BODY,
  MEASUREMENT_ITEMS_QUERY_BODY,
} from '~/apollo/operations/shared';
import type { AgentMergedAlarmConfiguration, AgentMergedDeviceConfiguration } from '~/types/agent';
import { ALARM_TYPE } from '~/types/alarm';
import type { Attribute } from '~/types/attribute';
import { EQUIPMENT_STATUS } from '~/types/equipment';
import {
  SENSOR_NAME_QUERY,
  SENSOR_NAME_VARIABLE,
  SENSOR_STATUS_QUERY,
  SENSOR_STATUS_VARIABLE,
} from '~/types/sensor';
import { STREAM_REQUESTED_STATUS, STREAM_STATE } from '~/types/videoStream';
import { getBeginningOfTheDayISO } from '~/utils/dateTime';

export interface SubsidiaryCarrierListQueryVariables {
  subsidiaryID: string;
}

interface MeasurementConnection<V> {
  __typename?: 'MeasurementConnection';
  items: {
    __typename?: 'Measurement';
    timestamp: string;
    value: V;
  }[];
}

export interface CarrierItem {
  __typename: 'Carrier_Cognito' | 'Carrier_NonCognito';
  id: string;
  name: string;
  email: string | null;
  attributes: Attribute[];
  requested_video_stream_status: STREAM_REQUESTED_STATUS | null;
  video_stream: {
    ivs_stream_state: STREAM_STATE | null;
  } | null;
  device: {
    __typename?: 'Device';
    name: string;
  } & {
    [key in SENSOR_NAME_VARIABLE]?: MeasurementConnection<string>;
  } & {
    [key in SENSOR_STATUS_VARIABLE]?: MeasurementConnection<EQUIPMENT_STATUS>;
  };
  // Merge configurations (device)
  mergeConfigDevice_base: AgentMergedDeviceConfiguration;
  // Merge configurations (alarms)
  mergeConfigAlarm_emergency: AgentMergedAlarmConfiguration;
  mergeConfigAlarm_fall: AgentMergedAlarmConfiguration;
  mergeConfigAlarm_attack: AgentMergedAlarmConfiguration;
  mergeConfigAlarm_traak_front: AgentMergedAlarmConfiguration;
  mergeConfigAlarm_traak_back: AgentMergedAlarmConfiguration;
  mergeConfigAlarm_stress: AgentMergedAlarmConfiguration;
  mergeConfigAlarm_abnormal_stops: AgentMergedAlarmConfiguration;
  mergeConfigAlarm_long_stops: AgentMergedAlarmConfiguration;
  mergeConfigAlarm_gas_danger: AgentMergedAlarmConfiguration;
  mergeConfigAlarm_gas_high: AgentMergedAlarmConfiguration;
}

export interface SubsidiaryCarrierListQueryData {
  subsidiary: {
    __typename?: 'Subsidiary';
    id: string;
    carriers: {
      __typename?: 'CarrierConnection';
      nextToken?: string | null;
      items: CarrierItem[];
    };
  };
}

// prettier-ignore
export default gql`
  query QuerySubsidiaryCarrierList($subsidiaryID: ID!, $nextToken: String) {
    subsidiary(id: $subsidiaryID) {
      id
      carriers(limit: 1000, nextToken: $nextToken) {
        nextToken
        items {
          id
          name
          ... on Carrier_Cognito {
            email
          }
          attributes {
            name
            value
          }
          requested_video_stream_status
          video_stream {
            ivs_stream_state
          }
          device {
            name
            ${SENSOR_NAME_VARIABLE.connected}: measurements(type: "${SENSOR_NAME_QUERY.connected}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.gps}: measurements(type: "${SENSOR_NAME_QUERY.gps}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.heartRate}: measurements(type: "${SENSOR_NAME_QUERY.heart_rate}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.heartRateStatus}: measurements(type: "${SENSOR_STATUS_QUERY.heart_rate_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.bodyMultiSensorV1}: measurements(type: "${SENSOR_NAME_QUERY.body_multi_sensor_v1}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.bodyMultiSensorV1Status}: measurements(type: "${SENSOR_STATUS_QUERY.body_multi_sensor_v1_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.bodyTemperature}: measurements(type: "${SENSOR_NAME_QUERY.body_temperature}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.bodyTemperatureStatus}: measurements(type: "${SENSOR_STATUS_QUERY.body_temperature_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.gas}: measurements(type: "${SENSOR_NAME_QUERY.gas}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.gasStatus}: measurements(type: "${SENSOR_STATUS_QUERY.gas_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.radiation}: measurements(type: "${SENSOR_NAME_QUERY.radiation}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.radiationStatus}: measurements(type: "${SENSOR_STATUS_QUERY.radiation_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.brainStop}: measurements(type: "${SENSOR_NAME_QUERY.brain_stop}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.connected_history}: measurements(type: "${SENSOR_NAME_QUERY.connected}", limit: 1000, createdAfter: "${getBeginningOfTheDayISO()}") ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.traakFrontStatus}: measurements(type: "${SENSOR_STATUS_QUERY.traak_front_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.traakBackStatus}: measurements(type: "${SENSOR_STATUS_QUERY.traak_back_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.emergencyStatus}: measurements(type: "${SENSOR_STATUS_QUERY.emergency_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.battery}: measurements(type: "${SENSOR_NAME_QUERY.battery}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.batteryStatus}: measurements(type: "${SENSOR_STATUS_QUERY.battery_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.lteSignalStrength}: measurements(type: "${SENSOR_NAME_QUERY.lte_signal_strength}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.lteSignalStrengthStatus}: measurements(type: "${SENSOR_STATUS_QUERY.lte_signal_strength_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_NAME_VARIABLE.oxygenSupply}: measurements(type: "${SENSOR_NAME_QUERY.oxygen_supply}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
            ${SENSOR_STATUS_VARIABLE.oxygenSupplyStatus}: measurements(type: "${SENSOR_STATUS_QUERY.oxygen_supply_status}", limit: 1) ${
              MEASUREMENT_ITEMS_QUERY_BODY
            }
          }
          mergeConfigDevice_base: merged_configuration(type: "device", name: "base") {
            ... on DeviceConfiguration {
              __typename
              wbr_orientation
              embedded_sound
            }
          }
          mergeConfigAlarm_emergency: merged_configuration(type: "alarm", name: "${ALARM_TYPE.emergency}") ${MERGED_ALARM_CONFIGURATION_QUERY_BODY}
          mergeConfigAlarm_fall: merged_configuration(type: "alarm", name: "${ALARM_TYPE.fall}") ${MERGED_ALARM_CONFIGURATION_QUERY_BODY}
          mergeConfigAlarm_attack: merged_configuration(type: "alarm", name: "${ALARM_TYPE.attack}") ${MERGED_ALARM_CONFIGURATION_QUERY_BODY}
          mergeConfigAlarm_traak_front: merged_configuration(type: "alarm", name: "${ALARM_TYPE.traak_front}") ${MERGED_ALARM_CONFIGURATION_QUERY_BODY}
          mergeConfigAlarm_traak_back: merged_configuration(type: "alarm", name: "${ALARM_TYPE.traak_back}") ${MERGED_ALARM_CONFIGURATION_QUERY_BODY}
          mergeConfigAlarm_stress: merged_configuration(type: "alarm", name: "${ALARM_TYPE.stress}") ${MERGED_ALARM_CONFIGURATION_QUERY_BODY}
          mergeConfigAlarm_abnormal_stops: merged_configuration(type: "alarm", name: "${ALARM_TYPE.abnormal_stops}") ${MERGED_ALARM_CONFIGURATION_QUERY_BODY}
          mergeConfigAlarm_long_stops: merged_configuration(type: "alarm", name: "${ALARM_TYPE.long_stops}") ${MERGED_ALARM_CONFIGURATION_QUERY_BODY}
          mergeConfigAlarm_gas_danger: merged_configuration(type: "alarm", name: "${ALARM_TYPE.gas_danger}") ${MERGED_ALARM_CONFIGURATION_QUERY_BODY}
          mergeConfigAlarm_gas_high: merged_configuration(type: "alarm", name: "${ALARM_TYPE.gas_high}") ${MERGED_ALARM_CONFIGURATION_QUERY_BODY}
        }
      }
    }
  }
`;
