'use client';

import { memo } from 'react';

import SectionList, {
  type SectionListItem,
} from '~/components/DetailsPopup/components/SectionList';
import Warning from '~/components/Warning';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import formatNumberWithUnit from '~/utils/parse/formatNumberWithUnit';

function calculateDataQuality(value: number | undefined): 'Unknown' | 'Bad' | 'Low' | 'Good' {
  if (typeof value === 'undefined') {
    return 'Unknown';
  }
  if (value < 1) {
    return 'Bad';
  }
  if (value === 1) {
    return 'Low';
  }
  return 'Good';
}

interface Props {
  agent: Agent | undefined;
  isHealthy: boolean;
}

const ContentPhysiologicalTemperature = memo(({ agent, isHealthy }: Props) => {
  const { companyFeatures } = useCompanyFeatures();

  if (!agent) {
    return null;
  }

  const bodyTemperature =
    agent?.equipmentStatus.physiologicalTemperature.status ||
    agent?.equipmentStatus.bodyTemperature.status
      ? agent?.sensors?.physiologicalTemperature?.value?.body_temp ??
        agent?.sensors?.bodyTemperature?.value
      : undefined;

  const skinTemperature = agent?.equipmentStatus.physiologicalTemperature.status
    ? agent?.sensors?.physiologicalTemperature?.value?.skin_temp
    : undefined;

  const dataQualityValue = agent?.equipmentStatus.physiologicalTemperature.status
    ? agent?.sensors?.physiologicalTemperature?.value?.data_qual
    : undefined;

  const dataQuality = calculateDataQuality(dataQualityValue);

  const getCommonParams = (value: number | null | undefined): Partial<SectionListItem> => ({
    style: {
      opacity:
        isHealthy &&
        (dataQuality === 'Good' || (dataQuality === 'Unknown' && Number.isFinite(value)))
          ? 1
          : 0.4,
    },
    tooltip: ['Bad', 'Low'].includes(dataQuality)
      ? i18n.t(`carrierDetailsPopup.physiologicalSensors.dataQualityTooltip${dataQuality}`)
      : undefined,
    valueText:
      dataQuality === 'Low' ? (
        <Warning>
          {formatNumberWithUnit({
            number: isHealthy ? value : undefined,
            unit: '\u00B0C',
          })}
        </Warning>
      ) : (
        formatNumberWithUnit({
          number: isHealthy && dataQuality !== 'Bad' ? value : undefined,
          unit: '\u00B0C',
        })
      ),
  });

  const list: SectionListItem[] = [
    ...(companyFeatures.physiologicalTemperatureSensor || companyFeatures.bodyTemperatureSensor
      ? [
          {
            ...getCommonParams(bodyTemperature),
            labelText: i18n.t('carrierDetailsPopup.physiologicalSensors.bodyTemperature'),
            'data-id': 'bodyTemperaturePhysiologicalSensor',
          },
        ]
      : []),
    ...(companyFeatures.physiologicalTemperatureSensor
      ? [
          {
            ...getCommonParams(skinTemperature),
            labelText: i18n.t('carrierDetailsPopup.physiologicalSensors.skinTemperature'),
            'data-id': 'skinTemperaturePhysiologicalSensor',
          },
        ]
      : []),
  ];

  return <SectionList data-id="SectionPhysiologicalSensors" elements={list} />;
});

ContentPhysiologicalTemperature.displayName = 'ContentPhysiologicalTemperature';

export default ContentPhysiologicalTemperature;
