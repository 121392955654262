'use client';

import { gql, useMutation } from '@apollo/client';
import { useMemo } from 'react';

import type { DeviceConfiguration } from '~/types/configurationDevice';

export interface SetConfigurationDeviceOverrideVariables {
  configuration: Partial<DeviceConfiguration> | undefined;
  companyId: string | undefined;
  subsidiaryId: string | undefined;
  carrierId: string | undefined;
}

export interface SetConfigurationDeviceOverrideData {
  setConfigurationDeviceOverride: {
    name: string;
  };
}

export default function useMutationSetConfigurationDeviceOverride(): {
  setConfigurationDeviceOverride: (
    variables: SetConfigurationDeviceOverrideVariables,
  ) => Promise<void>;
  result: SetConfigurationDeviceOverrideData['setConfigurationDeviceOverride'] | undefined;
  isLoading: boolean;
  error: Error | undefined;
} {
  const [setConfigurationDeviceOverride, { data, loading, error }] =
    useMutation<SetConfigurationDeviceOverrideData>(
      gql`
        mutation MutationSetConfigurationDeviceOverride(
          $companyId: ID
          $subsidiaryId: ID
          $carrierId: ID
          $configuration: setConfigurationDeviceOverrideInput
        ) {
          setConfigurationDeviceOverride(
            name: "base"
            company_id: $companyId
            subsidiary_id: $subsidiaryId
            carrier_id: $carrierId
            configuration: $configuration
          ) {
            name
          }
        }
      `,
      {
        fetchPolicy: 'no-cache',
      },
    );

  const setConfigurationDeviceOverrideCurated = useMemo(
    () => async (variables: SetConfigurationDeviceOverrideVariables) => {
      // Logic:
      // Value -> set
      // Null -> remove
      // Undefined -> ignore
      await setConfigurationDeviceOverride({
        variables: {
          companyId: variables.companyId,
          subsidiaryId: variables.subsidiaryId,
          carrierId: variables.carrierId,
          configuration: variables.configuration
            ? {
                ...variables.configuration,
                activated: { value: variables.configuration?.activated },
                wbr_orientation: { value: variables.configuration?.wbr_orientation },
                embedded_sound: { value: variables.configuration?.embedded_sound },
                delay_bad_conn_sec: { value: variables.configuration?.delay_bad_conn_sec },
                delay_disconn_sec: { value: variables.configuration?.delay_disconn_sec },
                physio_pr: { value: variables.configuration?.physio_pr },
                env_pr: { value: variables.configuration?.env_pr },
                gps_pr: { value: variables.configuration?.gps_pr },
                lte_debug_mode: { value: variables.configuration?.lte_debug_mode },
                sl: variables.configuration?.sl || [],
              }
            : null,
        },
      });
    },
    [setConfigurationDeviceOverride],
  );

  return useMemo(
    () => ({
      setConfigurationDeviceOverride: setConfigurationDeviceOverrideCurated,
      result: data?.setConfigurationDeviceOverride,
      isLoading: loading,
      error,
    }),
    [setConfigurationDeviceOverrideCurated, data?.setConfigurationDeviceOverride, error, loading],
  );
}
