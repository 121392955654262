'use client';

import { Divider } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import theme from '~/theme';

const StyledDivider = styled(Divider)`
  height: 65%;
  border-color: ${theme.colors.white};
  margin: 0;
  opacity: 0.5;
`;

const NavbarDesktopDivider = memo(() => <StyledDivider type="vertical" />);

NavbarDesktopDivider.displayName = 'NavbarDesktopDivider';

export default NavbarDesktopDivider;
