'use client';

import { Alert, Button } from 'antd';
import { memo, useState, useEffect } from 'react';
import styled from 'styled-components';

import Link from '~/components/Link';
import routes from '~/config/routes';
import { CompanyFeatures } from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';

const Div = styled.div`
  padding: 16px;
`;

interface Props {
  companyFeatureKey: keyof CompanyFeatures;
  showFeature: boolean;
  isLoading: boolean;
}

const MissingCompanyFeature = memo(({ companyFeatureKey, showFeature, isLoading }: Props) => {
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHasLoaded(true);
    }, 1_500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  if (showFeature || isLoading || !hasLoaded) {
    return null;
  }

  return (
    <Div>
      <Alert
        type="warning"
        message={
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: i18n.t<string>('general.missingCompanyFeature.descriptionHtml', {
                featureName: i18n.t(`companyFeatures.${companyFeatureKey}`),
              }),
            }}
          />
        }
        showIcon
      />
      <Link to={routes.map()}>
        <Button style={{ marginTop: '16px' }}>
          {i18n.t('general.missingCompanyFeature.goToDashboard')}
        </Button>
      </Link>
    </Div>
  );
});

MissingCompanyFeature.displayName = 'MissingCompanyFeature';

export default MissingCompanyFeature;
