'use client';

import QrcodeOutlined from '@ant-design/icons/QrcodeOutlined';
import { Input, Form } from 'antd';
import { memo, useState, useCallback, type ClipboardEvent, type ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthFormButton from '~/components/forms/components/AuthFormButton';
import AuthFormHeader from '~/components/forms/components/AuthFormHeader';
import Image from '~/components/Image';
import routes from '~/config/routes';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import useQueryParams from '~/hooks/useQueryParams';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { AWS_AUTHENTICATION_RESPONSE_TYPE } from '~/types/awsService';
import type { CustomURLSearchParams } from '~/types/route';
import browserStorage, { BROWSER_STORAGE_KEY } from '~/utils/browserStorage';
import notification from '~/utils/notification';

const MFA_LENGTH = 6;
const MFA_REGEX = /^[0-9]{6}$/;
const MFA_SHORTER_REGEX = /^[0-9]{1,6}$/;

interface FormFields {
  mfaCode: string;
}

function showVerifyError(error: Error) {
  notification.error({
    message: i18n.t('mfa.errorTitle'),
    // {error.message} <a href={routes.login}>{i18n.t('mfa.goBackToLogin')}</a>
    description: error.message,
    duration: null,
  });
}

const MFAPage = memo(() => {
  const { verifyMFA, verifyMFASetup } = useAuthenticationContext();
  const { mfaType, base64Image }: CustomURLSearchParams = useQueryParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const error = '';

  const verifyMFACallback = useCallback(
    (code?: string): void => {
      notification.destroy();
      if (!code || !MFA_REGEX.test(code)) {
        notification.error({
          message: i18n.t('mfa.errorTitle'),
          description: i18n.t('mfa.patternFailed'),
        });
        return;
      }
      setLoading(true);
      if (base64Image) {
        verifyMFASetup({ code })
          .then(() => {
            notification.success({
              message: i18n.t('mfa.successTitle'),
              description: i18n.t('mfa.validCode'),
            });
            browserStorage.local.set(BROWSER_STORAGE_KEY.LOGIN_TIME, Date.now());
            navigate(routes.map());
          })
          .catch((err: Error) => {
            showVerifyError(err);
            setLoading(false);
          });
        return;
      }
      verifyMFA({
        mfaType: mfaType as AWS_AUTHENTICATION_RESPONSE_TYPE,
        confirmationCode: code,
      })
        .then(() => {
          notification.success({
            message: i18n.t('mfa.successTitle'),
            description: i18n.t('mfa.validCode'),
          });
          browserStorage.local.set(BROWSER_STORAGE_KEY.LOGIN_TIME, Date.now());
          navigate(routes.map());
        })
        .catch((err: Error) => {
          showVerifyError(err);
          setLoading(false);
        });
    },
    [base64Image, mfaType, navigate, verifyMFA, verifyMFASetup],
  );

  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      event.preventDefault();
      verifyMFACallback(event.clipboardData.getData('Text').trim());
    },
    [verifyMFACallback],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const code = event.currentTarget.value;
      if (!MFA_SHORTER_REGEX.test(code)) {
        return null;
      }
      if (code.length === MFA_LENGTH) {
        verifyMFACallback(code);
      }
      return code;
    },
    [verifyMFACallback],
  );

  const handleFinish = useCallback(
    ({ mfaCode }: FormFields) => verifyMFACallback(mfaCode),
    [verifyMFACallback],
  );

  return (
    <Form onFinish={handleFinish}>
      <AuthFormHeader
        title={i18n.t('mfa.title')}
        description={base64Image ? i18n.t('mfa.messageQrCode') : i18n.t('mfa.message')}
      />
      <Form.Item
        name="mfaCode"
        colon={false}
        required={false}
        validateStatus={error && 'error'}
        help={error}
      >
        <Input
          type="number"
          required
          addonBefore={<QrcodeOutlined style={{ color: theme.colors.black }} />}
          size="large"
          placeholder={i18n.t<string>('mfa.inputPlaceholder')}
          onPaste={handlePaste}
          onChange={handleChange}
          autoFocus
        />
      </Form.Item>
      {base64Image && (
        <Form.Item>
          <Image src={base64Image} />
        </Form.Item>
      )}
      <AuthFormButton loading={loading}>{i18n.t('mfa.buttonText')}</AuthFormButton>
    </Form>
  );
});

MFAPage.displayName = 'MFAPage';

export default MFAPage;
