export default function formatNumberWithUnit({
  number,
  unit,
  decimals,
}: {
  number: number | null | undefined;
  unit?: string;
  decimals?: number;
}): string {
  const isValidNumber = typeof number === 'number' && number >= 0;

  return isValidNumber
    ? `${decimals ? number.toFixed(decimals) : number.toString()}${unit ? ` ${unit}` : ''}`
    : 'n/a';
}
