'use client';

import type { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  type ReactNode,
} from 'react';

import LoadingSpinner from '~/components/LoadingSpinner';
import type { AWSLoginResponse } from '~/types/awsService';
import logger from '~/utils/logger';

import useAWSCognitoService, { type AWSCognitoService } from './useAWSCognitoService';

interface IdToken {
  aud: string;
  auth_time: number;
  email: string;
  email_verified: boolean;
  event_id: string;
  exp: number;
  family_name?: string;
  given_name?: string;
  iat: number;
  iss: string;
  jti: string;
  origin_jti: string;
  sub: string;
  token_use: string;
  'cognito:groups': string[];
  'cognito:username': string;
}

interface AuthenticationContextType {
  isAuthenticated: boolean;
  idToken: IdToken | undefined;
  login: (Username: string, Password: string) => Promise<AWSLoginResponse | undefined>;
  logout: () => Promise<void>;
  isLoggingOut: boolean;
  getUser: () => CognitoUser | null;
  getUserSession: () => Promise<CognitoUserSession | null>;
  setUserAttributes: AWSCognitoService['setUserAttributes'];
  verifyMFASetup: AWSCognitoService['verifyMFASetup'];
  verifyMFA: AWSCognitoService['verifyMFA'];
  verifyConfirmationCode: AWSCognitoService['verifyConfirmationCode'];
  changePassword: AWSCognitoService['changePassword'];
  completeNewPasswordChallenge: AWSCognitoService['completeNewPasswordChallenge'];
  forgotPassword: AWSCognitoService['forgotPassword'];
  passwordReset: AWSCognitoService['passwordReset'];
  getIdToken: AWSCognitoService['getIdToken'];
  refreshSession: AWSCognitoService['refreshSession'];
}

const initialValues: AuthenticationContextType = {
  isAuthenticated: false,
  idToken: undefined,
  login: async () => undefined,
  logout: async () => undefined,
  isLoggingOut: false,
  getUser: () => null,
  getUserSession: async () => null,
  setUserAttributes: async () => undefined,
  verifyMFASetup: async () => false,
  verifyMFA: async () => undefined,
  verifyConfirmationCode: async () => undefined,
  changePassword: async () => undefined,
  completeNewPasswordChallenge: async () => undefined,
  forgotPassword: async () => undefined,
  passwordReset: async () => undefined,
  getIdToken: async () => undefined,
  refreshSession: async () => null,
};

const AuthenticationContext = createContext<AuthenticationContextType>(initialValues);

AuthenticationContext.displayName = 'AuthenticationContext';

export function AuthenticationContextProvider({ children }: { children: ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  // when logging out awsCognitoService clears user and therefore resets its
  // exports (methods references change) before isAuthenticated is updated which
  // leads to rerender with invalid state of isAuthenticated equals true in
  // components that import from awsCognitoService.
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
  const awsCognitoService = useAWSCognitoService();

  const getUser = useCallback(
    (): CognitoUser | null => awsCognitoService?.getUser() || null,
    [awsCognitoService],
  );

  const getUserSession = useCallback(
    (): Promise<CognitoUserSession | null> =>
      new Promise((resolve) => {
        if (awsCognitoService) {
          (async () => {
            const user = awsCognitoService?.getUser();
            if (user) {
              user.getSession((error: Error, userSession: CognitoUserSession | null) => {
                if (error) {
                  resolve(null);
                } else {
                  resolve(userSession);
                }
              });
            } else {
              resolve(null);
            }
          })();
        } else {
          resolve(null);
        }
      }),
    [awsCognitoService],
  );

  const login = useCallback(
    (Username: string, Password: string): Promise<AWSLoginResponse | undefined> =>
      new Promise((resolve, reject) => {
        if (awsCognitoService?.login) {
          const willingToWaitTimeout = setTimeout(() => {
            const timeoutError: Error = new Error();
            timeoutError.name = 'LOGIN_TIMEOUT';
            // timeoutError.code = 'TIMEOUT';
            timeoutError.message = 'Timeout while performing login';
            reject(timeoutError);
          }, 10_000);
          awsCognitoService
            .login({ Username, Password })
            .then((success) => {
              resolve(success);
            })
            .catch((error: Error) => {
              const exceptionError = new Error();
              exceptionError.name = error.name;
              // exceptionError.code = error.code;
              exceptionError.message = error.message;
              reject(exceptionError);
            })
            .finally(() => {
              clearTimeout(willingToWaitTimeout);
            });
        } else {
          reject(new Error('Auth Service has not being initialized'));
        }
      }),
    [awsCognitoService],
  );

  const logout = useCallback(
    (): Promise<void> =>
      new Promise((resolve, reject) => {
        if (awsCognitoService?.logout) {
          setIsLoggingOut(true);
          awsCognitoService
            .logout()
            .then((success) => {
              if (!success) {
                throw new Error();
              }
              setIsAuthenticated(false);
              setIsLoggingOut(false);
              resolve();
            })
            .catch(() => {
              logger.error('AuthenticationContext: logout error');
              setIsLoggingOut(false);
              reject(Error('Failed logout'));
            });
        } else {
          reject(Error('Injected Auth Service is invalid'));
        }
      }),
    [awsCognitoService],
  );

  useEffect(() => {
    (async () => {
      const userSession = await getUserSession();
      const nextIsAuthenticated = userSession ? userSession.isValid() : false;

      if (nextIsAuthenticated !== isAuthenticated || isAuthenticated === undefined) {
        setIsAuthenticated(nextIsAuthenticated);
      }
    })();
  }, [getUserSession, isAuthenticated]);

  const value = useMemo(() => {
    const service = awsCognitoService || initialValues;
    const idToken = getUser()?.getSignInUserSession()?.getIdToken()?.payload as IdToken | undefined;

    return {
      isAuthenticated: isAuthenticated || false,
      idToken,
      login,
      logout,
      isLoggingOut,
      getUser,
      getUserSession,
      setUserAttributes: service.setUserAttributes,
      verifyMFASetup: service.verifyMFASetup,
      verifyMFA: service.verifyMFA,
      verifyConfirmationCode: service.verifyConfirmationCode,
      changePassword: service.changePassword,
      completeNewPasswordChallenge: service.completeNewPasswordChallenge,
      forgotPassword: service.forgotPassword,
      passwordReset: service.passwordReset,
      getIdToken: service.getIdToken,
      refreshSession: service.refreshSession,
    };
  }, [isAuthenticated, login, logout, isLoggingOut, getUser, getUserSession, awsCognitoService]);

  if (isAuthenticated === undefined || !awsCognitoService) {
    return <LoadingSpinner fullscreen />;
  }

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
}

export default function useAuthenticationContext() {
  return useContext(AuthenticationContext);
}
